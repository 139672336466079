import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './FoundItemsTable.module.css';
import { TabLayout } from '../../components/TabLayout/TabLayout';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import Api from '../../../../servieces/Api/Api';
import {
  ArchivedFoundItem,
  FoundItem,
  MatchRecord
} from '../../../../types/supabase/collections';
import useFilters from '../../hooks/useFilters';
import { SearchBar } from '../../../../components/SearchBar/SearchBar';
import Tabs from '../../../../components/Tabs/Tabs';
import { MatchesBatch } from './components/MatchesBadge/MatchesBatch';
import {
  filterItemsWithPossibleMatches,
  PossibleMatchesTable
} from './components/PossibleMatchesTable/PossibleMatchesTable';
import { LoadingSpinner } from '../../../../sharedPacakge/components/LoadingSpinner/LoadingSpinner';
import {
  ExpiredOrLeftBehindTable,
  filterExpiredOrLeftBehindItems
} from './components/ExpiredOrLeftBehindTable/ExpiredOrLeftBehindTable';
import { Text } from '../../../../components/Text/Text';
import {
  AwaitingPickupTable,
  filterItemsAwaitingPickup
} from './components/AwaitingPickupTable/AwaitingPickupTable';
import { AllFoundItemsTable } from './components/AllFoundItems/FoundItemsTab';
import { ArchivedFoundItemsTable } from './components/ArchivedFoundItems/ArchivedFoundItemsTable';
import { useSnackbar } from '../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import Page from '../../../Page';
import { useFoundItemsPoster } from '../../../../contexts/FoundItemPosterContext/PostFoundItemJobsContext';

const getTabs = (amountOfPossibleMatches: number) => [
  {
    label: 'Alle',
    description: 'Alle aktive genstande',
    searchParamValue: null
  },
  {
    label: 'Mulige matches',
    description: 'Alle genstande der har mulige matchende efterlysning',
    searchParamValue: 'matches',
    suffix: <MatchesBatch amount={amountOfPossibleMatches} />,
    filter: filterItemsWithPossibleMatches
  },
  {
    label: 'Afhentning',
    description: 'Alle genstande der er aftalt at blive afhentet af ejeren',
    searchParamValue: 'pickup',
    filter: filterItemsAwaitingPickup
  },
  {
    label: 'Udløbet',
    description: 'Alle genstande der har lagt på lager for længe',
    searchParamValue: 'expired',
    filter: filterExpiredOrLeftBehindItems
  },
  {
    label: 'Arkiveret',
    description: 'Genstande der er arkiverede',
    searchParamValue: 'archived'
  }
];

const FoundItemsTab: FC = () => {
  const { location, itemCategories } = useLocationData();
  const { onItemsPosted, itemsBeingPosted } = useFoundItemsPoster();
  const [filters, udpateFilter] = useFilters();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { showSnackbar } = useSnackbar();
  const [foundItems, setFoundItems] = useState<
    FoundItem[] | ArchivedFoundItem[]
  >([]);
  const [matchesData, setMatchesData] = useState<MatchRecord[]>([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    getTabs(0).findIndex((tab) => {
      if (filters.filterItems === tab.searchParamValue) return true;
      if (!filters.filterItems && tab.searchParamValue === null) return true;
    }) as any
  );
  const isArchivedItems = useMemo(
    () => filters.filterItems === 'archived',
    [filters]
  );

  const removeFoundItemLocally = (id: number) => {
    setFoundItems((prevState) => prevState.filter((item) => item.id !== id));
  };

  const fetchFoundItems = async () => {
    // todo fetch archived if isArchived is true

    if (!location) return;
    setIsLoading(true);
    const foundItemsResponse = await Api.get.foundItems({
      location_id: location.id,
      archived: isArchivedItems
    });

    foundItemsResponse && setFoundItems(foundItemsResponse);

    const matchesDataResponse = await Api.get.matches({
      customQuery: (query) =>
        query
          .eq('location_id', location.id)
          .eq('match_rejected', false)
          .eq('match_confirmed', false)
    });

    setMatchesData(matchesDataResponse);
    setIsLoading(false);
  };

  // useEventListener('foundItemUploaded', fetchFoundItems);

  useEffect(() => {
    fetchFoundItems();
  }, [location, isArchivedItems]);

  onItemsPosted((newFoundItems: FoundItem[]) => {
    if (isArchivedItems || !location) return;

    console.log('RAN onItemsPosted newFoundItems', newFoundItems);

    if (!newFoundItems) return;
    console.log('newFoundItems', newFoundItems);
    setFoundItems((prevItems) => [...newFoundItems, ...prevItems]);
    showSnackbar(
      newFoundItems.length === 1 ? (
        <>
          Genstand tilføjet.{' '}
          <a
            style={{ textDecoration: 'underline' }}
            href={Page.l.viewFoundItem(location.id, newFoundItems[0].id)}
          >
            Se genstand.
          </a>
        </>
      ) : (
        `${newFoundItems.length} genstande tilføjet.`
      )
    );
    newFoundItems.map((item) => {
      if (item.used_image_detection) {
        // if using image detection with a payment card or id
        if (
          item.category.id === 200 ||
          item.category.id === 201 ||
          item.category.id === 202 ||
          item.category.id === 203
        ) {
          showSnackbar(
            'Vi anbefaler at du bruger "Nyt kort", ved oprettelse af kort, for ikke at uploade følsomme data',
            'warning'
          );
        }
      }
    });
    Api.get
      .matches({
        customQuery: (query) =>
          query
            .in(
              'found_item_id',
              newFoundItems.map((item) => item.id)
            )
            .eq('match_rejected', false)
            .eq('match_confirmed', false)
      })
      .then((matchesDataResponse) =>
        setMatchesData((prev) => [...prev, ...matchesDataResponse])
      );
  });

  const updateFoundItem = (
    id: number,
    newValue: Partial<FoundItem | ArchivedFoundItem>
  ) => {
    console.log('RAN updateFoundItem', id, newValue);
    setFoundItems((prevState) =>
      prevState.map((item) =>
        item.id === id ? { ...item, ...newValue } : item
      )
    );
  };

  const queryFilter = (item: FoundItem) =>
    filters.query
      ? item.category.name_danish
          .toLowerCase()
          .includes(filters.query.toLowerCase()) ||
        item.description.toLowerCase().includes(filters.query.toLowerCase())
      : true;

  if (!location) return null;

  const handleTabClick = (index: number) => {
    setSelectedTabIndex(index);
    udpateFilter({
      filterItems: (getTabs(0).at(index) as any).searchParamValue
    });
  };

  const callbacks = {
    onUpdateItem: updateFoundItem,
    onArchive: removeFoundItemLocally,
    onUnArchive: removeFoundItemLocally
  };

  const filteredItemsBasedOnTab: FoundItem[] = getTabs(0)[selectedTabIndex]
    .filter
    ? (getTabs(0)[selectedTabIndex].filter as any)(foundItems, matchesData)
    : foundItems;

  const fullyFilteredItems = filters.query
    ? filteredItemsBasedOnTab.filter((item) => queryFilter(item))
    : filteredItemsBasedOnTab;

  const uniqueQueryFilterOptions = Array.from(
    new Set([
      ...fullyFilteredItems.map((item) => item.description),
      ...fullyFilteredItems.map((item) => item.category.name_danish)
    ])
  );

  return (
    <TabLayout>
      <Tabs
        tabs={getTabs(
          filterItemsWithPossibleMatches(foundItems, matchesData).length
        )}
        onTabClick={handleTabClick}
        selectedTabIndex={selectedTabIndex}
      />
      <div className={styles.tabDescription__container}>
        <Text type="caption">{getTabs(0)[selectedTabIndex].description}</Text>
      </div>
      <div className={styles.searchBar__container}>
        <SearchBar
          placeholder="Kategori eller beskrivelse"
          options={uniqueQueryFilterOptions}
        />
      </div>
      {isLoading ? (
        <LoadingSpinner centered />
      ) : (
        <>
          {!filters.filterItems && (
            <AllFoundItemsTable
              foundItems={[...itemsBeingPosted, ...fullyFilteredItems]}
              callbacks={callbacks}
            />
          )}
          {filters.filterItems === 'matches' && (
            <PossibleMatchesTable
              itemsWithPossibleMatch={fullyFilteredItems}
              matchesData={matchesData}
              callbacks={callbacks}
            />
          )}
          {filters.filterItems === 'pickup' && (
            <AwaitingPickupTable
              itemsAwaitingPickup={fullyFilteredItems}
              callbacks={callbacks}
            />
          )}
          {filters.filterItems === 'expired' && (
            <ExpiredOrLeftBehindTable
              expiredOrLeftBehindItems={fullyFilteredItems}
              callbacks={callbacks}
            />
          )}
          {filters.filterItems === 'archived' && (
            <ArchivedFoundItemsTable
              foundItems={fullyFilteredItems}
              callbacks={callbacks}
            />
          )}
        </>
      )}
    </TabLayout>
  );
};

export default FoundItemsTab;
