import styles from './Switch.module.css';
import React, { ChangeEvent, FC, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
  uniqueId: string;
  state: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  color?: 'green' | 'blue';
  label?: string;
}
export const Switch: FC<Props> = ({
  uniqueId,
  state,
  onChange,
  color = 'blue',
  label
}) => {
  const [animateClass, setAnimate] = useState<string | null>(null);
  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAnimate((prevState) =>
      animateClass === styles.animate1 ? styles.animate2 : styles.animate1
    );
    onChange(e);
  };

  return (
    <div
      style={{ width: 'fit-content', height: 'fit-content' }}
      className={styles.container}
    >
      <input
        type="checkbox"
        id={uniqueId}
        className={styles.checkbox}
        checked={state}
        onChange={handleCheckboxChange}
      />
      {label && label}
      <label
        htmlFor={uniqueId}
        className={`${styles.toggle} ${color === 'blue' ? styles.blue : styles.green}`}
      >
        <div className={`${styles.icons} ${animateClass}`}>
          <CheckIcon className={styles.icon} />
          <ClearIcon className={styles.icon} />
        </div>
      </label>
    </div>
  );
};
