import { supabase } from '../../../supabase/supabaseClient';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export const getAllLocationsInOrganisation = async (
  organisationId: number
): Promise<{ id: number; name: string }[]> => {
  const { data: locations, error: locationsError } = await supabase
    .from('locations')
    .select('id, name')
    .eq('organisation_id', organisationId);

  assertNoSupabaseError(
    locations,
    locationsError,
    'getAllLocationsWithinOrganisation locationsError:',
    { organisationId },
    true
  );

  return locations;
};
