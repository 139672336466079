import React, { FC, ReactNode, useEffect } from 'react';
import { IconButton } from '@mui/material';
import styles from './snackbar.module.css';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export type SnackbarType = 'regular' | 'success' | 'error' | 'warning';

interface Props {
  message: string | ReactNode;
  open: boolean;
  close: () => void;
  type: SnackbarType;
  autoHideDuration: number;
}

export const Snackbar: FC<Props> = ({
  message,
  open,
  close,
  type,
  autoHideDuration
}) => {
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        close();
      }, autoHideDuration);

      return () => clearTimeout(timer); // Clear timer if unmounted early
    }
  }, []);

  const handleClose = (e: any) => {
    e.preventDefault();
    close();
  };

  return (
    <div className={styles.individualSnackbar__container}>
      <div
        className={`${styles.snackbar} 
        ${type === 'regular' ? styles.regularColor : ''}
        ${type === 'success' ? styles.successColor : ''}
        ${type === 'error' ? styles.errorColor : ''}
        ${type === 'warning' ? styles.warningColor : ''}`}
      >
        <span>{message}</span>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseRoundedIcon />
        </IconButton>
        {/* Add unique progress indicator based on autoHideDuration */}
        <div
          className={styles.progress__indicator}
          style={{ animationDuration: `${autoHideDuration}ms` }} // Duration is set dynamically
        />
      </div>
    </div>
  );
};
