import { supabase } from '../../../supabase/supabaseClient';
import { InquiryRecord } from '../../../../types/supabase/collections';
import logger from '../../../../sharedPacakge/logger';

export const updateInquiry = async (
  inquiryId: number,
  newData: Partial<InquiryRecord>
): Promise<void> => {
  const { error } = await supabase
    .from('inquiries')
    .update(newData)
    .eq('id', inquiryId);

  if (error) {
    logger.supabaseError('updateInquiry', error);
    throw error;
  }
};
