import { Role } from '../../types/supabase/collections';

export interface RoleAccess {
  manageUsers?: Role[];
  // canAccessLocations: { id: number; name: string }[];
}

export const getRoleAccess = (
  role: Role
  // canAccessLocations: { id: number; name: string }[]
): RoleAccess => {
  switch (role) {
    case Role.OrganisationAdmin:
      return {
        manageUsers: [
          Role.OrganisationAdmin,
          Role.LocationAdmin,
          Role.GeneralUser
        ]
      };
    case Role.LocationAdmin:
      return {
        manageUsers: [Role.LocationAdmin, Role.GeneralUser]
      };
    default:
      return {};
  }
};
