import { DependencyList, useMemo, useRef } from "react";
import { objectsAreEqual } from "../arraysAreEqual";

/**
 * Memoization even variables which change refference on disk, but not value. Like objects or arrays.
 */
export function useDeepMemo<T>(factory: () => T, deps: DependencyList): T {
  const ref = useRef<DependencyList | undefined>(undefined);

  const hasChanged = !objectsAreEqual(ref.current, deps);
  if (hasChanged) {
    ref.current = deps;
  }

  return useMemo(factory, [ref.current]);
}
