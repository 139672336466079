import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FoundItem,
  Inquiry
} from '../../../../../../types/supabase/collections';
import { useModalNavigation } from '../../../../../../sharedPacakge/components/Modal/Modal';
import { Button } from '../../../../../../sharedPacakge/components/Button/Button';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { Text } from '../../../../../../components/Text/Text';
import Api from '../../../../../../servieces/Api/Api';
import { FoundItemArchiveReason } from '../../../../../../sharedPacakge/types';
import { useSnackbar } from '../../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { SetState } from '../../../../../../sharedPacakge/types/utilityTypes';
import { ViewItemModalCallbacks } from '../../ViewItemModalCallbacks';

interface Props<T> {
  item: T;
  onClose: () => void;
  callbacks: ViewItemModalCallbacks<T>;
}

function EditContent<T extends FoundItem | Inquiry>({
  item,
  callbacks,
  onClose
}: Props<T>) {
  const { navigation, setNavigation } = useModalNavigation();
  const [value, setValue] = useState<FoundItemArchiveReason>(
    '(manually archived) Physical inquiry & immediate pickup'
  );
  const { showSnackbar } = useSnackbar();
  const isFoundItem = 'found_at_date' in item;

  const handleSubmit = useCallback(() => {
    Api.put
      .archiveItems({
        ids: [item.id],
        type: isFoundItem ? 'foundItems' : 'inquiries',
        archiveReason: value
      })
      .then(() => {
        callbacks?.onArchive && callbacks.onArchive(item.id);
        showSnackbar('Afviklet');
        onClose();
        setNavigation(null);
      })
      .catch(() => showSnackbar('Der skete en fejl', 'error'));
  }, [value, item, setNavigation]);

  useEffect(() => {
    if (navigation) {
      // Only update navigation if needed
      setNavigation((prev) => {
        if (prev && prev.onSubmit !== handleSubmit) {
          return {
            ...prev,
            onSubmit: handleSubmit
          };
        }
        return prev; // Ensure we return prev in both branches
      });
    }
  }, [navigation, handleSubmit]); // handleSubmit is still a dependency, but we mitigate the issue

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="(manually archived) Physical inquiry & immediate pickup"
        name="radio-buttons-group"
        value={value}
        onChange={(e) => setValue(e.target.value as FoundItemArchiveReason)}
      >
        <FormControlLabel
          value="(manually archived) Physical inquiry & immediate pickup"
          control={<Radio />}
          label="Ejer henvendte sig fysisk"
        />
        <FormControlLabel
          value="(manually archived) It was a test"
          control={<Radio />}
          label="Det var en test"
        />
        <FormControlLabel
          value="Other"
          control={<Radio />}
          label="Anden årsag"
        />
      </RadioGroup>
    </FormControl>
  );
}

export function ArchiveItemButton<T extends FoundItem | Inquiry>(
  props: Props<T>
) {
  const { setNavigation } = useModalNavigation();

  const onClick = (e: any) => {
    e.preventDefault();
    setNavigation({
      title: 'Arkivér med Årsag',
      // this has to be another component, in order for it to keep its state once it slides out.
      content: <EditContent {...props} />,
      onBack: () => setNavigation(null),
      onSubmit: () => {}
    });
  };

  return (
    <Button onClick={onClick} buttonType="white">
      <Text type="body-strong">Arkivér manuelt</Text>
      <ArchiveOutlinedIcon />
    </Button>
  );
}
