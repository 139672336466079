import React, { FC } from 'react';
import styles from './organisationLogo.module.css';
import { Skeleton } from '@mui/material';

interface Props {
  image?: string | undefined;
  altText: string | undefined;
  className?: string;
  onClick?: (e: any) => void;
  size?: 'small' | 'normal' | 'large';
}

const sizeMap = {
  small: { width: 20, height: 20, fontSize: 12 },
  normal: { width: 40, height: 40, fontSize: 16 },
  large: { width: 120, height: 120, fontSize: 48 }
};

const CircleImage: FC<Props> = ({
  altText,
  className,
  onClick,
  size = 'normal'
}) => {
  const { width, height, fontSize } = sizeMap[size];

  const props: any = {
    className: `${styles.circleImage} ${className ?? ''}`,
    style: { width, height, fontSize }
  };

  if (onClick) {
    props.onClick = onClick;
    props.className += ` ${styles.hoverEffect}`;
  }

  return altText ? (
    <div {...props}>{altText.charAt(0).toUpperCase()}</div>
  ) : (
    <Skeleton variant="circular" width={width} height={height} />
  );
};

export default CircleImage;
