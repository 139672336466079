import { supabase } from '../../../supabase/supabaseClient';
import { Role } from '../../../../types/supabase/collections';
import logger from '../../../../sharedPacakge/logger';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export async function createOrganisation(args: {
  userId: string;
  organisationName: string;
  locationName: string;
}): Promise<{ locationId: number }> {
  const { userId, organisationName, locationName } = args;

  const { data: orgData, error: orgError } = await supabase
    .from('organisations')
    .insert({ name: organisationName })
    .select('id')
    .single();

  assertNoSupabaseError(
    orgData,
    orgError,
    'createOrganisation orgData:',
    {},
    true
  );

  const organisationId = orgData.id;

  // Assign the user as an organization admin -> so that the policy access is granted to create location
  const { error: userRoleError } = await supabase.from('user_roles').insert([
    {
      user_id: userId,
      role: Role.OrganisationAdmin,
      organisation_id: organisationId
    }
  ]);

  if (userRoleError) {
    logger.supabaseError('createOrganisation userRoleError', userRoleError);
    throw userRoleError;
  }

  // Create location
  const { data: locationData, error: locationError } = await supabase
    .from('locations')
    .insert({
      name: locationName,
      organisation_id: organisationId
    })
    .select('id')
    .single();

  assertNoSupabaseError(
    locationData,
    locationError,
    'createOrganisation locationError:',
    {
      name: locationName,
      organisation_id: organisationId
    },
    true
  );

  const locationId = locationData.id;

  return {
    locationId
  };
}
