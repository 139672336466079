import { supabase } from '../../../supabase/supabaseClient';
import {
  ArchivedInquiry,
  Inquiry,
  ItemCategory
} from '../../../../types/supabase/collections';
import { inquiryFields } from './getInquiries';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export async function getInquiry<T extends boolean>(
  id: number,
  archived: T = false as T
): Promise<T extends true ? ArchivedInquiry : Inquiry> {
  const { data, error } = archived
    ? await supabase
        .from('archived_inquiries')
        .select(inquiryFields)
        .eq('id', id)
        .single()
    : await supabase
        .from('inquiries')
        .select(inquiryFields)
        .eq('id', id)
        .single();

  assertNoSupabaseError(data, error, 'getInquiry error:', {}, true);

  // Casting to `unknown` first, then to `T extends true ? ArchivedInquiry : Inquiry`
  return {
    ...data,
    category: data?.categories as ItemCategory
  } as unknown as T extends true ? ArchivedInquiry : Inquiry;
}
