export function arraysAreEqual(array1: Array<any>, array2: Array<any>) {
  if (array1.length !== array2.length) {
    return false;
  }
  return array1.every((value, index) => array2.includes(value));
}

export function objectsAreEqual(obj1: any, obj2: any): boolean {
  // Convert objects to JSON strings and compare them
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}
