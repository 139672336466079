import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Api from '../../servieces/Api/Api';
import Page from '../../pages/Page';
import {
  ItemCategory,
  Location,
  Organisation,
  Role
} from '../../types/supabase/collections';
import { useAuth } from '../AuthContext/AuthContext';
import { decodeIntId } from '../../sharedPacakge/utils/IntIdEncryption';
import { SetState } from '../../sharedPacakge/types/utilityTypes';
import { RoleAccess } from '../../servieces/Role/roleBasedAccessControl';

type LocationDataDataContext =
  | {
      isLoading: true;
      organisation: null;
      location: null;
      userRole: null;
      roleAccess: null;
      itemCategories: [];
    }
  | {
      isLoading: false;
      organisation: Organisation;
      location: Location;
      userRole: Role;
      roleAccess: RoleAccess;
      itemCategories: ItemCategory[];
    };

type LocationDataContextType = {
  data: LocationDataDataContext;
  setData: SetState<LocationDataDataContext>;
};

const defaultValue: LocationDataDataContext = {
  isLoading: true,
  organisation: null,
  location: null,
  userRole: null,
  roleAccess: null,
  itemCategories: []
};

const LocationDataContext = createContext<LocationDataContextType | undefined>(
  undefined
);

export const LocationDataProvider: FC<{ children: ReactNode }> = ({
  children
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { encodedLocationId } = useParams();
  const [data, setData] = useState<LocationDataDataContext>(defaultValue);

  useEffect(() => {
    if (!encodedLocationId) {
      throw Error('LocationDataProvider missing encodedLocationId');
    }
    if (!user) {
      console.error('not fetching locationData, because there is no user');
      return;
    }

    const locationId = decodeIntId(encodedLocationId);
    Api.get
      .locationData({ userId: user.id, locationId })
      .then((response) => {
        setData({
          isLoading: false,
          ...response
        });
      })
      .catch((e) => {
        return navigate(Page.login);
      });
  }, [navigate, user, encodedLocationId]);

  return (
    <LocationDataContext.Provider value={{ data, setData }}>
      {children}
    </LocationDataContext.Provider>
  );
};

export const useLocationData = (): LocationDataDataContext => {
  const context = useContext(LocationDataContext);
  if (!context) {
    throw new Error(
      'useLocationData must be used within an LocationDataProvider'
    );
  }
  return context.data;
};

export const useSetLocationData = (): SetState<LocationDataDataContext> => {
  const context = useContext(LocationDataContext);
  if (!context) {
    throw new Error(
      'useUpdateLocationData must be used within an LocationDataProvider'
    );
  }
  return context.setData;
};
