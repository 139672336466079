import React, { useCallback, useEffect, useRef, useState } from 'react';
import Api from '../../../../../../servieces/Api/Api';
import {
  ArchivedFoundItem,
  FoundItem
} from '../../../../../../types/supabase/collections';
import EditIcon from '@mui/icons-material/Edit';
import { TextField } from '../../../../../../components/TextField/TextField';
import { useModalNavigation } from '../../../../../../sharedPacakge/components/Modal/Modal';
import styles from './editableFoundItemTextField.module.css';
import { TextArea } from '../../../../../../components/TextArea/TextArea';
import { ViewItemModalCallbacks } from '../../ViewItemModalCallbacks';

interface Props<T> {
  foundItem: T;
  callbacks: ViewItemModalCallbacks<T>;
  field: 'notes' | 'description' | 'storage_location' | 'found_at_location';
}

const translateField = (
  field: 'notes' | 'description' | 'storage_location' | 'found_at_location'
) => {
  switch (field) {
    case 'notes':
      return 'Noter';
    case 'description':
      return 'Beskrivelse';
    case 'storage_location':
      return 'Opbevaringssted';
    case 'found_at_location':
      return 'Fundet ved';
  }
};

function EditContent<T extends FoundItem | ArchivedFoundItem>({
  field,
  callbacks,
  foundItem
}: Props<T>) {
  const isArchivedItem = 'archive_reason' in foundItem;
  const { navigation, setNavigation } = useModalNavigation();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useState<string>(foundItem[field]);

  const handleSubmit = useCallback(() => {
    // Your API call and state update logic
    Api.put.foundItem(
      foundItem.id,
      {
        [field]: value // this value will be the latest due to closure in the callback
      },
      isArchivedItem
    );

    callbacks.onUpdateItem(foundItem.id, {
      [field]: value
    } as Partial<T>);

    // Reset navigation
    setNavigation(null);
  }, [value, foundItem, field, callbacks, setNavigation]);

  useEffect(() => {
    if (navigation) {
      // Only update navigation if needed
      setNavigation((prev) => {
        if (prev && prev.onSubmit !== handleSubmit) {
          return {
            ...prev,
            onSubmit: handleSubmit
          };
        }
        return prev; // Ensure we return prev in both branches
      });
    }

    // Focus the input when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
      const length = inputRef.current.value.length; // Get the current text length
      inputRef.current.setSelectionRange(length, length); // Set cursor to the end
    }
  }, [navigation, handleSubmit]); // handleSubmit is still a dependency, but we mitigate the issue

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <TextArea
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={handleKeyDown}
      inputRef={inputRef}
    />
  );
}

export function EditableFoundItemTextField<
  T extends FoundItem | ArchivedFoundItem
>(props: Props<T>) {
  const { setNavigation } = useModalNavigation();

  const onEditClick = (e: any) => {
    e.preventDefault();
    setNavigation({
      title: translateField(props.field),
      // this has to be another component, in order for it to keep its state once it slides out.
      content: <EditContent {...props} />,
      onBack: () => setNavigation(null),
      onSubmit: () => {}
    });
  };

  return (
    <div onClick={onEditClick}>
      <TextField
        classes={{
          input: styles.pointerCursor
        }}
        label={translateField(props.field)}
        value={props.foundItem[props.field]}
        onChange={() => {}} // nothing should happen here
        adornment={{
          end: <EditIcon />
        }}
      />
    </div>
  );
}
