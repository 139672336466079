export const inquiryEmailVariables = [
  '{{ recipient name }}',
  '{{ location name }}',
  '{{ inquiry category }}',
  '{{ inquiry description }}'
];

export const formatEmailContentWithVariables = (args: {
  content: string;
  recipientName: string;
  locationName: string;
  inquiryCategoryName: string;
  inquiryDescription: string;
}) => {
  const {
    content,
    recipientName,
    locationName,
    inquiryCategoryName,
    inquiryDescription
  } = args;
  // Replace the placeholder with the actual recipient name
  let formattedContent = content.replace(
    /\{\{\s*recipient name\s*\}\}/g,
    recipientName
  );

  // Replace the placeholder with the actual location name
  formattedContent = formattedContent.replace(
    /\{\{\s*location name\s*\}\}/g,
    locationName
  );

  formattedContent = formattedContent.replace(
    /\{\{\s*inquiry category\s*\}\}/g,
    inquiryCategoryName
  );

  formattedContent = formattedContent.replace(
    /\{\{\s*inquiry description\s*\}\}/g,
    inquiryDescription
  );

  return formattedContent;
};
