import React, { FC, useEffect, useState } from 'react';
import { Divider, ListItemIcon, MenuItem } from '@mui/material';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import Api from '../../../../../servieces/Api/Api';
import Page from '../../../../Page';
import styles from './organisationSelectModal.module.css';
import CircleImage from '../../../../../components/OrganisationLogo/OrganisationLogo';
import { useAuth } from '../../../../../contexts/AuthContext/AuthContext';
import { useNavigate } from 'react-router-dom';
import SignOutButton from '../../Sidebar/SignOutButton/SignOutButton';
import { useLocationData } from '../../../../../contexts/LocationDataContext/LocationDataContext';

interface Option {
  label: string;
  isSelected: boolean;
  icon?: React.ReactNode; // Allow any React node, including MUI icons
  onClick: () => void;
}

interface Props {
  handleClose: () => void;
}

export const OrganisationSelectModal: FC<Props> = ({ handleClose }) => {
  const navigate = useNavigate();

  const { user, username } = useAuth();
  const { organisation } = useLocationData();
  const [options, setOptions] = useState<Option[]>([
    ...(organisation
      ? [
          {
            label: organisation.name,
            isSelected: true,
            icon: (
              <CircleImage
                altText={organisation?.name}
                size="small"
                className={styles.organisationsLogo}
              />
            ),
            onClick: () => {
              if (!user) return;
              Api.get
                .anyLocationIdFromOrganisation({
                  organisationId: organisation.id,
                  userId: user.id
                })
                .then((response) => {
                  navigate(Page.l.foundItems(response.locationId));
                });
            }
          }
        ]
      : [])
  ]);

  useEffect(() => {
    if (!user) return;

    Api.get.allOrganisationWhereUserIsAdmin(user.id).then((results) => {
      if (results.length === 0) return;

      const organisations = results.map((result) => ({
        label: result.name,
        isSelected: organisation?.id === result.id,
        icon: (
          <CircleImage
            altText={result?.name}
            size="small"
            className={styles.organisationsLogo}
          />
        ),
        onClick: () => {
          if (!user) return;
          Api.get
            .anyLocationIdFromOrganisation({
              organisationId: result.id,
              userId: user.id
            })
            .then((response) => {
              navigate(Page.l.foundItems(response.locationId));
            });
        }
      }));

      setOptions([...organisations]);
    });
  }, []);

  const handleMenuItemClick = (e: any, onClick: () => void) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
    handleClose();
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.userInfo__container}>
          <CircleImage altText={username} size="normal" />
          <div>
            <div className={styles.username}>{username}</div>
            <div className={styles.email}>{user?.email}</div>
          </div>
        </div>
        <Divider />
        <div className={styles.organisations__title}>Dine organisationer</div>
        {options.map((option, index) => (
          <MenuItem
            key={index}
            onClick={(e) => handleMenuItemClick(e, option.onClick)}
            className={`${styles.menuItem} ${option.isSelected ? styles.selectedItem : ''}`}
          >
            {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
            {option.label}
          </MenuItem>
        ))}
        <Divider />
        <SignOutButton />
      </div>
    </>
  );
};
