import React, {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useDropzone } from 'react-dropzone';

interface ImageUploaderProps {
  onFileUpload: (file: File) => void;
  previewImage?: File;
  resetStateOnUpload?: boolean;
  imageScaling?: {
    maxWidth: number;
    maxHeight: number;
  };
  renderText?: (image: string) => React.ReactNode;
  styles?: React.CSSProperties;
  className?: string;
}

const ImageUploader: FC<ImageUploaderProps> = ({
  onFileUpload,
  previewImage,
  resetStateOnUpload = false,
  renderText,
  styles = {},
  className = ''
}) => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const previewImageToDataUrl = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (previewImage && !resetStateOnUpload) {
      previewImageToDataUrl(previewImage);
    } else {
      setImagePreview(null);
    }
  }, [previewImage, resetStateOnUpload]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (file) {
        onFileUpload(file);
        previewImageToDataUrl(file);
      }
    },
    [onFileUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png']
    },
    multiple: false
  });

  return (
    <div
      {...getRootProps()}
      style={{ ...dropzoneStyles, ...styles }}
      className={className}
    >
      <input {...getInputProps()} />
      {renderText ? (
        renderText(imagePreview as string)
      ) : imagePreview ? (
        <p>Træk og slip en billedfil her, eller klik for at vælge et</p>
      ) : (
        <div style={{ marginTop: '20px' }}>
          <img
            src={imagePreview as string}
            alt="Preview"
            style={{ maxWidth: '100%', maxHeight: '200px' }}
          />
        </div>
      )}
    </div>
  );
};

const dropzoneStyles: CSSProperties = {
  border: '2px dashed #ccc',
  borderRadius: '4px',
  textAlign: 'center',
  cursor: 'pointer'
};

export default ImageUploader;
