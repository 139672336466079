import { deleteRecords, getRecords } from '../../../supabase/getRecord';
import { ItemStatus } from '../../../../sharedPacakge/types/ItemStatus';
import { supabase } from '../../../supabase/supabaseClient';
import { runMatchmaking } from '../../../matchmaking/runMatchmaking';
import logger from '../../../../sharedPacakge/logger';

export async function unArchiveItems(args: {
  ids: number[];
  type: 'inquiries' | 'foundItems';
  newExpiryDate: Date;
}): Promise<void> {
  const { ids, type, newExpiryDate } = args;

  // Step 1: Retrieve archived records
  const archivedItems =
    type === 'inquiries'
      ? await getRecords('archived_inquiries', ids)
      : await getRecords('archived_found_items', ids);

  if (!archivedItems || archivedItems.length === 0) {
    logger.error('unArchiveItems: failed to get items');
    throw new Error('unArchiveFoundItems: failed to get items');
  }

  // Step 2: Unarchive the items by re-inserting them into the original table with updated expiry date
  const unarchivedItems = archivedItems.map((item) => {
    const { archive_reason, archived_date, ...rest } = item; // Exclude archive-specific fields
    return {
      ...rest,
      expiry_date: newExpiryDate.toISOString(),
      updated_at: new Date().toISOString(),
      // ensuring the status wont be an expired status
      status:
        item.status === ItemStatus.LeftBehind
          ? ItemStatus.ReadyForPickup
          : item.status === ItemStatus.Expired
            ? ItemStatus.AwaitingMatch
            : item.status
    };
  });

  const { error: unArchiveError } = await supabase
    .from(type === 'inquiries' ? 'inquiries' : 'found_items')
    .insert(unarchivedItems);

  if (unArchiveError) {
    logger.supabaseError('unArchiveItems: unarchiveError', unArchiveError);
    throw unArchiveError;
  }

  // Step 4: Check if these items had any matching records in archived_matches
  const { data: matchRecords, error: arhivedMatchesError } = await supabase
    .from('archived_matches')
    .select('*')
    .in(type === 'inquiries' ? 'inquiry_id' : 'found_item_id', ids)
    .eq('match_confirmed', true);

  if (arhivedMatchesError)
    console.error('arhivedMatchesError', arhivedMatchesError);

  if (!matchRecords || matchRecords.length === 0) {
    await deleteRecords(
      type === 'inquiries' ? 'archived_inquiries' : 'archived_found_items',
      ids
    );
    const runMatchmakingPromises = unarchivedItems.map((unarchivedItem) =>
      runMatchmaking(unarchivedItem)
    );
    await Promise.all(runMatchmakingPromises);
    return; // this is a success
  }

  // Step 5: Unarchive the matching items as well
  const matchType = type === 'inquiries' ? 'foundItems' : 'inquiries';
  const matchingItemIds = matchRecords.map((match) =>
    matchType === 'inquiries' ? match.inquiry_id : match.found_item_id
  );

  const matchingItems =
    matchType === 'inquiries'
      ? await getRecords('archived_inquiries', matchingItemIds)
      : await getRecords('archived_found_items', matchingItemIds);

  if (!matchingItems || matchingItems.length === 0) {
    logger.error(`unArchiveItems failed to get records for ${matchType}`);
    throw new Error(`unArchiveItems failed to get records for ${matchType}`);
  }

  const unarchivedMatchingItems = matchingItems.map((item) => {
    const { archive_reason, archived_date, ...rest } = item; // Exclude archive-specific fields

    return {
      ...rest,
      expiry_date: newExpiryDate.toISOString(),
      updated_at: new Date().toISOString(),
      // ensuring the status wont be an expired status
      status:
        item.status === ItemStatus.LeftBehind
          ? ItemStatus.ReadyForPickup
          : item.status === ItemStatus.Expired
            ? ItemStatus.AwaitingMatch
            : item.status
    };
  });

  const { error: unarchiveMatchingError } = await supabase
    .from(matchType === 'inquiries' ? 'inquiries' : 'found_items')
    .insert(unarchivedMatchingItems);

  if (unarchiveMatchingError) {
    logger.supabaseError(
      'unArchiveItems unarchiveMatchingError',
      unarchiveMatchingError
    );
    throw unarchiveMatchingError;
  }

  const { error: insertMatchError } = await supabase
    .from('matches')
    .insert(matchRecords);

  if (insertMatchError) {
    logger.supabaseError('unArchiveItems insertMatchError', insertMatchError);
    throw insertMatchError;
  }

  // Step 7: Remove the corresponding archived match records
  await deleteRecords(
    type === 'inquiries' ? 'archived_inquiries' : 'archived_found_items',
    ids
  );
  await deleteRecords(
    matchType === 'inquiries' ? 'archived_inquiries' : 'archived_found_items',
    matchingItemIds
  );
}
