import { supabase } from '../../../supabase/supabaseClient';
import { MatchRecord } from '../../../../types/supabase/collections';
import type { PostgrestFilterBuilder } from '@supabase/postgrest-js';
import { Database } from '../../../../types/supabase/supabaseDB';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

type CustomQuery = PostgrestFilterBuilder<Database['public'], MatchRecord, any>;

export async function getMatches(
  args: {
    customQuery?: (query: CustomQuery) => CustomQuery;
  },
  archived: boolean = false
): Promise<MatchRecord[]> {
  const { customQuery } = args;

  let query = archived
    ? supabase.from('archived_matches').select('*')
    : (supabase.from('matches').select('*') as CustomQuery);

  if (customQuery) {
    query = customQuery(query);
  }

  const { data, error } = await query;

  assertNoSupabaseError(data, error, 'getMatches Error fetching matches');

  return data;
}
