import { supabase } from '../../../supabase/supabaseClient';
import { Role } from '../../../../types/supabase/collections';
import logger from '../../../../sharedPacakge/logger';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export async function getAllLocationsWhereUserIsAdminWithingOrganisation(args: {
  userId: string;
  organisationId: number;
}): Promise<{ id: number; name: string }[]> {
  const { userId, organisationId } = args;
  const { data: userRoles, error: userRolesError } = await supabase
    .from('user_roles')
    .select()
    .eq('user_id', userId)
    .eq('organisation_id', organisationId);

  if (userRolesError) {
    logger.supabaseError(
      'getAllLocationsWhereUserIsAdminWithingOrganisation userRolesError',
      userRolesError
    );
    throw userRolesError;
  }

  if (!userRoles || userRoles.length === 0) return [];

  if (userRoles.find((userRole) => userRole.role === Role.OrganisationAdmin)) {
    // is org admin
    const { data: locations, error: locationsError } = await supabase
      .from('locations')
      .select('id, name')
      .eq('organisation_id', organisationId);

    assertNoSupabaseError(
      locations,
      locationsError,
      'getAllLocationsWhereUserIsAdminWithingOrganisation locationsError:',
      { userId, organisationId },
      true
    );

    return locations;
  }

  // is location admin
  const locationIdsUserHasAccessTo = userRoles
    .filter((userRole) => userRole.location_id)
    .map((userRole) => userRole.location_id);

  if (locationIdsUserHasAccessTo.length === 0) {
    return [];
  }

  const { data: locations, error: locationsError } = await supabase
    .from('locations')
    .select('id, name')
    .in('id', locationIdsUserHasAccessTo);

  assertNoSupabaseError(
    locations,
    locationsError,
    'getAllLocationsWhereUserIsAdminWithingOrganisation locationsError:',
    { userId, locationIdsUserHasAccessTo },
    true
  );

  return locations;
}
