import styles from './header.module.css';
import React, { FC, ReactNode } from 'react';
import InfoButton from './InfoModal/InfoButton';
import { LocationSelectButton } from './LocationSelect/LocationSelectButton';
import { OrganisationSelectButton } from './OrganisationSelectModal/OrganisationSelectButton';
import { useIsMobile } from '../../../../utils/hooks/useIsMobile';
import { Text } from '../../../../components/Text/Text';
import { useActiveTab } from '../../hooks/useActiveTab';
import NewFoundItemButton from '../Sidebar/NewFoundItemButton/NewFoundItemButton';
import { ClickableFindyLogo } from '../FindyLogo/ClickableFindyLogo';
import { useHeaderProgressBar } from '../../../../contexts/HeaderProgressBarContext/ProgressBarContext';
import { HeaderProgressBar } from '../../../../contexts/HeaderProgressBarContext/HeaderProgressBar';
interface Props {
  navDrawerButton?: () => ReactNode;
}

export const Header: FC<Props> = ({ navDrawerButton }) => {
  const { progressBars } = useHeaderProgressBar();
  const isMobile = useIsMobile();
  const { activeTab } = useActiveTab();

  console.log('progressBars', progressBars);
  return (
    <div>
      <div className={styles.header}>
        <div className={styles.leftContainer}>
          {isMobile ? (
            <div className={styles.pageTitle__container}>
              {navDrawerButton && navDrawerButton()}
              <Text type={'body-strong'}>{activeTab?.name}</Text>
            </div>
          ) : (
            <ClickableFindyLogo />
          )}
        </div>

        <div className={styles.buttonsContainer}>
          {isMobile && <NewFoundItemButton />}{' '}
        </div>

        <div className={styles.contentRight}>
          {!isMobile && <InfoButton />}
          <LocationSelectButton />
          {!isMobile && <OrganisationSelectButton />}
        </div>
      </div>

      <div className={styles.progressBars}>
        {progressBars.map((bar) => (
          <HeaderProgressBar key={bar.id} progress={bar.progress} />
        ))}
      </div>
    </div>
  );
};
