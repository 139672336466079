import { supabase } from '../../../supabase/supabaseClient';
import { CustomInquiryFormStyling } from '../../../../pages/LocationPage/tabs/EditInquiryForm/EditInquiryForm';
import logger from '../../../../sharedPacakge/logger';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export const upsertInquiryForm = async (
  organisationId: number,
  formData: Partial<CustomInquiryFormStyling>
): Promise<void> => {
  const inquiryFormData = {
    ...formData,
    organisation_id: organisationId
  } as CustomInquiryFormStyling & { organisation_id: number };

  const { error } = await supabase
    .from('inquiry_forms')
    .upsert(inquiryFormData, { onConflict: 'organisation_id' });

  assertNoSupabaseError({}, error, 'upsertInquiryForm error ');
};
