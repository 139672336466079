import { supabase } from '../../../supabase/supabaseClient';
import { Role } from '../../../../types/supabase/collections';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export async function AddAdmin(args: {
  userIdToAdd: string;
  locationId: number;
  organisationId: number;
  roleType: Role;
}): Promise<number> {
  const { userIdToAdd, locationId, organisationId, roleType } = args;
  const { data, error } = await supabase
    .from('user_roles')
    .insert({
      user_id: userIdToAdd,
      role: roleType,
      organisation_id: organisationId,
      ...(roleType === Role.LocationAdmin ? { location_id: locationId } : {})
    })
    .select('id')
    .single();

  assertNoSupabaseError(data, error, 'AddAdmin error:', args, true);

  return data.id;
}
