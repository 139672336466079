import { supabase } from '../../../supabase/supabaseClient';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export async function createLocation(args: {
  organisationId: number;
  locationName: string;
}): Promise<{ locationId: number }> {
  const { organisationId, locationName } = args;

  // Policy will ensure the user is organisation admin
  // Create location
  const { data: locationData, error: locationError } = await supabase
    .from('locations')
    .insert({
      name: locationName,
      organisation_id: organisationId
    })
    .select('id')
    .single();

  assertNoSupabaseError(
    locationData,
    locationError,
    'createLocation locationError:',
    {
      name: locationName,
      organisation_id: organisationId
    },
    true
  );

  const locationId = locationData.id;

  // Return the IDs of the created organization and location
  return {
    locationId
  };
}
