export function daysBetweenDates(date1: Date, date2: Date): number {
  // Get the time value of each date in milliseconds
  const time1 = date1.getTime();
  const time2 = date2.getTime();

  // Calculate the difference in milliseconds
  const differenceInMillis = Math.abs(time2 - time1);

  // Convert milliseconds to days (1 day = 1000 ms * 60 s * 60 min * 24 h)
  const millisecondsInADay = 1000 * 60 * 60 * 24;
  const differenceInDays = Math.floor(differenceInMillis / millisecondsInADay);

  return differenceInDays;
}
