import Api from '../Api/Api';
import { ItemStatus } from '../../sharedPacakge/types/ItemStatus';
import logger from '../../sharedPacakge/logger';
import {
  FoundItem,
  Inquiry,
  Location,
  MatchRecord,
  Organisation
} from '../../types/supabase/collections';
import { incrementDateByDays } from '../../utils/incrementDateByDays';
import { sendInquiryEmail } from '../sendInquiryEmail';

const getNewExpiryDateForMatch = (
  foundItem: FoundItem,
  inquiry: Inquiry,
  location: Location
) => {
  const locationExpiryDate = incrementDateByDays(
    new Date(),
    location.left_behind_item_expiry_days
  );
  const dates = [
    new Date(foundItem.expiry_date).getTime(),
    new Date(inquiry.expiry_date).getTime(),
    locationExpiryDate.getTime()
  ];
  const latestDate = new Date(Math.max(...dates));
  return latestDate;
};

export const approveMatch = async (args: {
  selectedMatch: {
    matchData: MatchRecord;
    inquiry: Inquiry;
  };
  foundItem: FoundItem;
  location: Location;
  organisation: Organisation;
}): Promise<void> => {
  const { selectedMatch, foundItem, organisation, location } = args;

  try {
    await Api.put.match(selectedMatch.matchData.id, {
      match_confirmed: true,
      confirmed_or_rejected_at: new Date().toISOString()
    });

    await Api.delete.matches((query) =>
      query.eq('found_item_id', foundItem.id).eq('match_confirmed', false)
    );

    await Api.delete.matches((query) =>
      query
        .eq('inquiry_id', selectedMatch.inquiry.id)
        .eq('match_confirmed', false)
    );

    const newExpiryDate = getNewExpiryDateForMatch(
      foundItem,
      selectedMatch.inquiry,
      location
    );

    await Api.put.inquiry(selectedMatch.matchData.inquiry_id, {
      status: ItemStatus.ReadyForPickup,
      expiry_date: newExpiryDate.toISOString()
    });

    await Api.put.foundItem(selectedMatch.matchData.found_item_id, {
      status: ItemStatus.ReadyForPickup,
      expiry_date: newExpiryDate.toISOString()
    });

    await Promise.all([
      sendInquiryEmail({
        emailType: 'ITEM_FOUND_READY_FOR_PICKUP',
        organisationId: selectedMatch.inquiry.organisation_id,
        inquiryId: selectedMatch.inquiry.id
      }),
      sendInquiryEmail({
        emailType: 'X_DAYS_LEFT_TO_PICKUP',
        organisationId: selectedMatch.inquiry.organisation_id,
        inquiryId: selectedMatch.inquiry.id
      }),
      Api.delete.scheduledEmail(
        selectedMatch.inquiry.id,
        'WE_ARE_STILL_SEARCHING'
      )
    ]);
  } catch (error) {
    logger.error(`approveMatch error ${JSON.stringify(error)}`);
    throw error;
  }
};
