import React from 'react';
import Page from '../../../Page';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { FindyLogo } from './FindyLogo';

export const ClickableFindyLogo = () => {
  const { location } = useLocationData();
  const navigate = useNavigate();
  const currentLocation = useLocation();

  const onLogoClick = (e: any) => {
    if (!location) return;
    e.preventDefault();

    const foundItemsPath = Page.l.foundItems(location.id);

    if (currentLocation.pathname === foundItemsPath) {
      // Reload the page if already on foundItems
      window.location.reload();
    } else {
      // Navigate to foundItems if not already on it
      navigate(foundItemsPath);
    }
  };

  return (
    <div onClick={onLogoClick} style={{ cursor: 'pointer' }}>
      <FindyLogo />
    </div>
  );
};
