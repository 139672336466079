import { useEffect, useState } from 'react';

const MAX_MOBILE_WIDTH = 500;

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MAX_MOBILE_WIDTH
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MAX_MOBILE_WIDTH);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};
