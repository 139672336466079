import React, { FC } from 'react';
import styles from './emailTypeOptions.module.css';
import {
  emailTypes,
  InquiryEmailType
} from '../../../../../../types/supabase/collections';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export const getInquiryEmailTypeInfo = (
  type: InquiryEmailType
): {
  name: string;
  description: string;
  scheduleDescription?: string;
} => {
  switch (type) {
    case 'INQUIRY_CREATED':
      return {
        name: 'Efterlysning oprettet',
        description: 'Sendes til person som opretter efterlysning'
      };
    case 'WE_ARE_STILL_SEARCHING':
      return {
        name: 'Vi leder stadig',
        description:
          'efterlyst genstand blev er endnu ikke fundet, men vi leder stadig',
        scheduleDescription: 'efter efterlysningen blev oprettet'
      };
    case 'ITEM_FOUND_READY_FOR_PICKUP':
      return {
        name: 'Genstand fundet og klar til afhentning',
        description: 'Sendes til personer hvis genstand er blevet matched'
      };

    case 'WE_DIDNT_FIND_YOUR_ITEM':
      return {
        name: 'Efterlyst genstand blev ikke fundet',
        description: 'Sendes til personer hvis genstand ikke er blev fundet'
      };
    case 'X_DAYS_LEFT_TO_PICKUP':
      return {
        name: 'Påmindelse om at afhnete genstand',
        description:
          'Sendes til person som endnu ikke har afhentet deres genstand',
        scheduleDescription: 'efter efterlysningen blev matched'
      };
    case 'ITEM_NEVER_PICKED_UP':
      return {
        name: 'Efterlyst genstand blev ikke afhentet',
        description:
          'Sendes til personer hvis genstand er fundet, men aldrig blev afhentet'
      };
  }
};
interface Props {
  selectedEmailType: InquiryEmailType;
  onChange: (template: InquiryEmailType) => void;
}

const EmailTypeOptions: FC<Props> = ({ selectedEmailType, onChange }) => {
  return (
    <div className={styles.matchOptions__container}>
      {emailTypes.map((type, i) => {
        const info = getInquiryEmailTypeInfo(type);
        return (
          <div
            key={i}
            className={`${styles.container} ${selectedEmailType === type ? styles.container__selected : ''}`}
            onClick={(e: any) => {
              e.preventDefault();
              onChange(type);
            }}
          >
            <MailOutlineIcon />
            <div className={styles.container__right}>
              <div className={styles.description}>{info.name}</div>

              {/*<div className={styles.category}>{info.description}</div>*/}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EmailTypeOptions;
