import { InquiryFormRecord } from '../../../../types/supabase/collections';
import { supabase } from '../../../supabase/supabaseClient';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export async function getInquiryForm(
  organisation_id: number
): Promise<InquiryFormRecord | null> {
  const { data, error } = await supabase
    .from('inquiry_forms')
    .select('*')
    .eq('organisation_id', organisation_id)
    .single();

  assertNoSupabaseError(data, error, 'getInquiryForm error:', {
    organisation_id
  });

  return data;
}
