import { TextField, Chip, Box, FormControl, FormLabel } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useSnackbar } from '../../sharedPacakge/components/Snackbar/SnackbarContext';
import styles from './multiChipInput.module.css';

interface Props {
  value: string[];
  onChange: (newValue: string[]) => void;
  label: string; // Optional label prop
}

export const MultiChipInput: FC<Props> = ({ value, onChange, label }) => {
  const { showSnackbar } = useSnackbar();
  const [inputText, setInputText] = useState<string>('');

  useEffect(() => {
    if (inputText.includes(',')) {
      setInputText((prevState) => prevState.replace(',', ''));
    }
  }, [inputText]);

  const removeOption = (indexToRemove: number) => {
    onChange(value.filter((_, index) => index !== indexToRemove));
  };

  const handleKeyDown = (e: any) => {
    if (e.key === ',') {
      setInputText((prevState) => prevState.replace(',', ''));
      const valueAlreadyExists = value.some((options) => options === inputText);

      if (inputText === '' || inputText === ' ') return;
      if (valueAlreadyExists) {
        showSnackbar('Dette felt eksisterer allerede', 'error');
        return;
      }

      onChange([...value, inputText]);
      setInputText('');
    }
  };

  const handleInputChange = (e: any) => {
    setInputText(e.target.value);
  };

  return (
    <FormControl fullWidth>
      {label && <FormLabel>{label}</FormLabel>}
      <Box display="flex" flexDirection="column" gap={2}>
        <Box
          display="flex"
          flexWrap="wrap"
          gap={1}
          alignItems="center"
          border={1}
          borderColor="grey.400"
          borderRadius={1}
          p={1}
        >
          {value.map((option, index) => (
            <Chip
              key={index}
              label={option}
              onDelete={() => removeOption(index)}
              color="primary"
              className={styles.chip}
            />
          ))}
          <TextField
            type="text"
            value={inputText}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Skriv her, og tryk komma for at tilføje"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            sx={{ flex: 1, minWidth: '150px' }}
          />
        </Box>
      </Box>
    </FormControl>
  );
};
