import React from 'react';
import styles from './headerProgressBar.module.css';

export const HeaderProgressBar: React.FC<{ progress: number }> = ({
  progress
}) => {
  return (
    <div className={styles.progressBar__container}>
      <div
        className={styles.progressBar}
        style={{
          width: `${progress}%`,
          transition: 'width 0.5s ease-in-out'
        }}
      />
    </div>
  );
};
