import FindyApi from "../sharedPacakge/findyApi/fetchFindyApi";

export const sendEmail = async ({
  emails,
  subject,
  htmlContent,
}: {
  emails: string[];
  subject: string;
  htmlContent: string;
}): Promise<{ success: true }> =>
  await FindyApi.post("email", {
    emails,
    subject,
    htmlContent,
  });
