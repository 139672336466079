import {
  Location,
  Organisation,
  Role
} from '../../../../types/supabase/collections';
import { sendEmail } from '../../../../servieces/sendEmail';
import { baseUrl } from '../../../../utils/constants';
import Page from '../../../Page';

export const sendAdminInviteEmail = async (
  email: string,
  location: Location,
  organisation: Organisation,
  userExistsAlready: boolean
) => {
  const path = userExistsAlready ? Page.l.foundItems(location.id) : Page.signup;
  const link = `${baseUrl}${path}`;

  await sendEmail({
    emails: [email],
    subject: `Du er blevet tilføj organisationen ${organisation.name}`,
    htmlContent: `<div>Du er blevet tilføjet til organisationen: ${organisation.name}
                  <a href='${link}'>Gå til organisationen</a>

                </div>`
  });
};
