import { supabase } from '../../../supabase/supabaseClient';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';
import { InquiryEmailType } from '../../../../types/supabase/collections';

export async function deleteScheduledEmail(
  inquiryId: number,
  emailType: InquiryEmailType
): Promise<void> {
  const { data, error } = await supabase
    .from('scheduled_inquiry_emails')
    .select('*')
    .eq('inquiry_id', inquiryId)
    .eq('inquiry_email_type', emailType)
    .single();

  assertNoSupabaseError(data, error, 'deleteScheduledEmail error', {
    inquiryId,
    emailType
  });

  if (!data) return; // email has already been sent or deleted

  const { error: DeleteError } = await supabase
    .from('scheduled_inquiry_emails')
    .delete()
    .eq('id', data.id);

  assertNoSupabaseError({}, DeleteError, 'deleteScheduledEmail error', {
    inquiryId,
    emailType
  });
}
