import { supabase } from '../../../supabase/supabaseClient';
import { userProfileRecord } from '../../../../types/supabase/collections';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export async function getUserProfile(
  userId: string
): Promise<userProfileRecord> {
  const { data: user, error: userError } = await supabase
    .from('user_profiles')
    .select('*')
    .eq('id', userId)
    .single();

  assertNoSupabaseError(
    user,
    userError,
    'getUserProfile userError:',
    { userId },
    true
  );

  return user;
}
