import { supabase } from '../../../supabase/supabaseClient';
import { MatchRecord } from '../../../../types/supabase/collections';
import type { PostgrestFilterBuilder } from '@supabase/postgrest-js';
import { Database } from '../../../../types/supabase/supabaseDB';
import logger from '../../../../sharedPacakge/logger';

type CustomQuery = PostgrestFilterBuilder<Database['public'], MatchRecord, any>;

export const deleteMatches = async (
  customQuery?: (query: CustomQuery) => CustomQuery
): Promise<void> => {
  let query: CustomQuery = supabase.from('matches').delete() as CustomQuery;

  if (customQuery) {
    query = customQuery(query);
  }

  const { error } = await query;

  if (error) {
    logger.supabaseError(`deleteMatches`, error);
    throw error;
  }
};
