import React, {
  createContext,
  FC,
  MutableRefObject,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useHeaderProgressBar } from '../HeaderProgressBarContext/ProgressBarContext';
import { scaleAndMinifyImage } from '../../sharedPacakge/utils/imageScaleMinify';
import { encodeImageToBase64ClientSide } from '../../utils/encodeImageToBase64';
import { useGetFoundItemFromImage } from '../../pages/LocationPage/components/Sidebar/NewFoundItemButton/useGetFoundItemFromImage';
import FindyApi from '../../sharedPacakge/findyApi/fetchFindyApi';
import { FoundItem, FoundItemRecord } from '../../types/supabase/collections';
import { runMatchmaking } from '../../servieces/matchmaking/runMatchmaking';
import { useLocationData } from '../LocationDataContext/LocationDataContext';

export type PostFoundItemItem = Partial<
  Pick<FoundItem, 'expiry_date' | 'created_at' | 'id' | 'updated_at'>
> &
  Omit<FoundItem, 'expiry_date' | 'created_at' | 'id' | 'updated_at'>;

type PostFoundItemJobsContext = {
  itemsBeingPosted: Array<PostFoundItemItem>;
  postFoundItems: (data: {
    image: File;
    foundItem: PostFoundItemItem;
    imageDetection: boolean;
    isMultipleItems: boolean;
  }) => any;
  onItemsPosted: (callback: (newFoundItems: FoundItem[]) => void) => void;
};

const defaultValue: PostFoundItemJobsContext = {
  itemsBeingPosted: [],
  postFoundItems: () => {},
  onItemsPosted: () => {}
};

const PostFoundItemContext =
  createContext<PostFoundItemJobsContext>(defaultValue);

export const FoundItemPosterProvider: FC<{ children: ReactNode }> = ({
  children
}) => {
  const [isDone, setIsDone] = useState(false);
  const [initialPostData, setInitialinitialPostData] = useState<{
    image: File;
    foundItem: PostFoundItemItem;
    useImageDetection: boolean;
  } | null>(null);
  const [displayPostingItems, setDisplayPostingItems] = useState<
    Array<PostFoundItemItem>
  >([]);
  const { itemCategories } = useLocationData();
  const {
    getFoundItemsFromImage,
    imageDectionItems,
    isLoadingImageDection,
    reset
  } = useGetFoundItemFromImage();
  const { addOrUpdateProgressbar } = useHeaderProgressBar();

  const onItemsPostedCallbacksRef = useRef<
    ((newFoundItems: FoundItem[]) => void) | null
  >(null) as MutableRefObject<((newFoundItems: FoundItem[]) => void) | null>;

  const onItemsPosted = (callback: (newFoundItems: FoundItem[]) => void) => {
    onItemsPostedCallbacksRef.current = callback;
  };

  const insertFoundItem = async ({
    image,
    foundItem
  }: {
    image: File;
    foundItem: PostFoundItemItem;
  }): Promise<FoundItemRecord> => {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('data', JSON.stringify(foundItem));
    return FindyApi.post('found-item/insert', formData);
  };

  const postFoundItems = async ({
    image,
    foundItem,
    imageDetection,
    isMultipleItems
  }: {
    image: File;
    foundItem: PostFoundItemItem;
    imageDetection: boolean;
    isMultipleItems: boolean;
  }) => {
    setIsDone(false);
    setInitialinitialPostData({
      image,
      foundItem,
      useImageDetection: imageDetection
    });
    setDisplayPostingItems([foundItem]);
    addOrUpdateProgressbar(0, 0); // first item being shown
    const minifiedImage = await scaleAndMinifyImage(image, 520, 512);
    const uptimizedBase64Image =
      await encodeImageToBase64ClientSide(minifiedImage);
    addOrUpdateProgressbar(0, 10);

    if (imageDetection) {
      await getFoundItemsFromImage({
        isMultipleItems,
        uptimizedBase64Image
      });
    }

    addOrUpdateProgressbar(0, 80);
    setIsDone(true);
  };

  const callbackAndMatchmaking = (postedItems: FoundItemRecord[]) => {
    addOrUpdateProgressbar(0, 100);
    if (onItemsPostedCallbacksRef.current)
      onItemsPostedCallbacksRef.current(
        postedItems.map((postedItem, index) => ({
          ...postedItem,
          category: itemCategories.find(
            ({ id }) => id === postedItem.category_id
          )!
        }))
      );
    Promise.all(postedItems.map((item) => runMatchmaking(item))).then(() => {
      reset();
      setInitialinitialPostData(null);
      setDisplayPostingItems([]);
    });
  };

  useEffect(() => {
    if (initialPostData) {
      if (imageDectionItems && imageDectionItems.length > 0) {
        setDisplayPostingItems(
          imageDectionItems.map((imgDetectionItem) => ({
            ...initialPostData.foundItem,
            ...imgDetectionItem
          }))
        );
      }
      if (isDone) {
        setIsDone(false);

        Promise.all(
          displayPostingItems.map(async (displayItem, index) => {
            return await insertFoundItem({
              image: initialPostData.image,
              foundItem: {
                ...initialPostData.foundItem,
                ...displayItem,
                ...(initialPostData.useImageDetection
                  ? imageDectionItems[index]
                  : {}) // get latest image detection, because its on more up to date than displayPostingItem
              }
            });
          })
        ).then(callbackAndMatchmaking);
      }
    }
  }, [isLoadingImageDection, imageDectionItems, isDone]);

  return (
    <PostFoundItemContext.Provider
      value={{
        postFoundItems,
        itemsBeingPosted: displayPostingItems,
        onItemsPosted
      }}
    >
      {children}
    </PostFoundItemContext.Provider>
  );
};

export const useFoundItemsPoster = (): PostFoundItemJobsContext => {
  const context = useContext(PostFoundItemContext);
  if (!context) {
    throw new Error(
      'useFoundItemsPoster must be used within an useFoundItemsPosterProvider'
    );
  }
  return context;
};
