import { supabase } from '../../../supabase/supabaseClient';
import {
  ItemCategory,
  Location,
  Organisation,
  Role
} from '../../../../types/supabase/collections';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';
import logger from '../../../../sharedPacakge/logger';
import {
  getRoleAccess,
  RoleAccess
} from '../../../Role/roleBasedAccessControl';

export async function getLocationData(args: {
  locationId: number;
  userId: string;
}): Promise<{
  location: Location;
  organisation: Organisation;
  userRole: Role;
  roleAccess: RoleAccess;
  itemCategories: ItemCategory[];
}> {
  const { userId, locationId } = args;

  // Fetch the location by ID
  const { data: locationData, error: locationError } = await supabase
    .from('locations')
    .select('*')
    .eq('id', locationId)
    .single();

  assertNoSupabaseError(
    locationData,
    locationError,
    'getLocationData locationError:',
    { userId, locationId }
  );

  if (!locationData) {
    logger.warn(
      `getLocationData warning | user is trying to go to a location which does not exist. Location ID: ${locationId}`
    );
    throw Error('user is trying to go to a location which does not exist');
  }

  const organisationId = locationData.organisation_id;

  // userRole
  const { data: userRoles, error: userRolesError } = await supabase
    .from('user_roles')
    .select()
    .eq('user_id', userId)
    .eq('organisation_id', organisationId);

  assertNoSupabaseError(
    userRoles,
    userRolesError,
    'getLocationData userRolesError:',
    { userId, organisationId },
    true
  );

  let userRole;
  const locationIdsUserHasAccessTo: number[] = userRoles
    .filter((role) => {
      userRole = role.role as Role;

      return role.location_id !== null;
    })
    .map((role) => {
      return role.location_id;
    }) as number[]; // ensure no null location_ids are passed

  if (!userRole) throw new Error('User has no role in this organisation');
  if (
    userRole !== Role.OrganisationAdmin &&
    !locationIdsUserHasAccessTo.some((lId) => lId === locationId)
  ) {
    throw new Error('User dont have access to this location');
  }
  // if (userRole === Role.OrganisationAdmin) {
  //   const {
  //     data: locationsUserCanAccessData,
  //     error: locationsUserCanAccessError
  //   } = await supabase
  //     .from('locations')
  //     .select('id, name')
  //     .eq('organisation_id', locationData.organisation_id);
  //
  //   assertNoSupabaseError(
  //     locationsUserCanAccessData,
  //     locationsUserCanAccessError,
  //     'getLocationData locationsUserCanAccessError:',
  //     { userId, locationData },
  //     true
  //   );
  //
  //   roleAccess = getRoleAccess(userRole, locationsUserCanAccessData);
  // } else {
  //   if (locationIdsUserHasAccessTo.length > 1) {
  //     const {
  //       data: locationsUserCanAccessData,
  //       error: locationsUserCanAccessError
  //     } = await supabase
  //       .from('locations')
  //       .select('id, name')
  //       .in('id', locationIdsUserHasAccessTo);
  //
  //     assertNoSupabaseError(
  //       locationsUserCanAccessData,
  //       locationsUserCanAccessError,
  //       'getLocationData locationsUserCanAccessError:',
  //       { userId, locationData },
  //       true
  //     );
  //
  //     roleAccess = getRoleAccess(userRole, locationsUserCanAccessData);
  //   } else {
  //     roleAccess = getRoleAccess(userRole, [
  //       {
  //         id: locationData.id,
  //         name: locationData.name
  //       }
  //     ]);
  //   }
  // }

  // Fetch the organisation using the organisation_id from the location
  const { data: organisationData, error: organisationError } = await supabase
    .from('organisations')
    .select('*')
    .eq('id', organisationId)
    .single();

  assertNoSupabaseError(
    organisationData,
    organisationError,
    'getLocationData organisationError:',
    { userId, organisationId },
    true
  );

  const { data: itemCategories, error: categoriesError } = await supabase
    .from('categories')
    .select('*');

  assertNoSupabaseError(
    itemCategories,
    categoriesError,
    'getLocationData categoriesError:',
    {},
    true
  );

  return {
    location: locationData,
    organisation: organisationData,
    userRole,
    roleAccess: getRoleAccess(userRole),
    itemCategories
  };
}
