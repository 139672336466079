import React, { FC, useRef, useState } from 'react';
import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';
import { IconButton, Tooltip } from '@mui/material';
import { inquiryEmailVariables } from '../../../../../../sharedPacakge/emails/formatEmailContentWithVariables';
import { useSnackbar } from '../../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import LoadingAnimation from '../../../../components/LoadingAnimation/LoadingAnimation';
import './removeUnlayerBranding.css';
import { Button } from '../../../../../../sharedPacakge/components/Button/Button';
import { Json } from '../../../../../../types/supabase/supabaseDB';

const ShowEmailVariable: FC<{ variable: string }> = ({ variable }) => {
  const { showSnackbar } = useSnackbar();
  const onCopyClick = (e: any) => {
    e.preventDefault();
    navigator.clipboard.writeText(variable);
    showSnackbar(`kopieret`);
  };

  return (
    <div onClick={onCopyClick}>
      <Tooltip title="Klik for at kopier">
        <IconButton>
          <div style={{ fontSize: 15 }}>{variable}</div>
        </IconButton>
      </Tooltip>
    </div>
  );
};

interface Props {
  value: { html: string; json: Json };
  onComplete: (newValue: { html: string; json: Json }) => void;
}

export const EmailContentEditor: FC<Props> = ({ value, onComplete }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const emailEditorRef = useRef<EditorRef>(null);
  // const [reloadCount, setReloadCount] = useState<number>(0);
  // const [renderEditor, setRenderEditor] = useState<boolean>(true);

  const onCompleteClick = (e: any) => {
    e.preventDefault();
    const unlayer = emailEditorRef.current?.editor;
    unlayer?.exportHtml((data) => {
      const { design, html } = data;
      console.log('design', design);
      onComplete({ html, json: design });
    });
  };

  const onReady: EmailEditorProps['onReady'] = (unlayer) => {
    setIsLoading(false);
    console.log('value.json', value.json);
    unlayer.loadDesign(value.json as any);

    const iframe = document.querySelector('iframe');
    if (iframe) {
      const brandingElements: NodeListOf<HTMLElement> | undefined =
        iframe.contentDocument?.querySelectorAll('.blockbuilder-branding');
      brandingElements?.forEach((container) => {
        container.style.background = `red`;
        container.style.display = `none`;
      });
    }
  };

  // useEffect(() => {
  //   // Reset the email editor(fetching of iframe) by using the ref to re-render it - we are doing this because sometiems it infinite loads
  //
  //   const timeoutId = setTimeout(() => {
  //     if (isLoading) {
  //       setReloadCount((prev) => prev + 1);
  //       setIsLoading(true); // Reset loading state to trigger refresh
  //     }
  //   }, 8000); // 8 seconds
  //
  //   return () => clearTimeout(timeoutId);
  // }, [isLoading, reloadCount]);
  // useEffect(() => {
  //   if (reloadCount > 0) {
  //     setIsLoading(true);
  //     setRenderEditor(false);
  //     setTimeout(() => {
  //       setIsLoading(true); // Trigger loading animation
  //       setRenderEditor(true);
  //       console.log('rerendered');
  //     }, 500); // Allow some time for destroy to complete
  //   }
  // }, [reloadCount]);
  // setTimeout(() => {
  //   const iframe = document.querySelector('iframe');
  //   if (iframe) {
  //     const brandingElements = iframe.contentDocument?.getElementsByClassName(
  //       'blockbuilder-branding'
  //     );
  //     if (brandingElements) {
  //       Array.from(brandingElements).forEach((el) => {
  //         el.style.display = 'none';
  //       });
  //     }
  //   }
  // }, 1000); // Adjust the timeout if necessary
  return (
    <div>
      <div
        style={{
          marginBottom: '10px',
          display: 'block',
          width: 'fit-content',
          margin: 'auto',
          textAlign: 'center',
          padding: '10px 20px',
          borderRadius: 4,
          background: '#F3F7FB'
        }}
      >
        <div>Variabler</div>
        <small style={{ fontStyle: 'italic' }}>
          Disse variabler kan indsættes hvor som helst i emailen
        </small>
        <div
          style={{
            display: 'flex',
            gap: 5,
            width: 'fit-content'
          }}
        >
          {inquiryEmailVariables.map((emailVariable, i) => (
            <ShowEmailVariable key={i} variable={emailVariable} />
          ))}
        </div>
      </div>

      {isLoading && (
        <div>
          {/*<div style={{ textAlign: 'center', color: 'grey', marginTop: 10 }}>*/}
          {/*  Vent venligst. Dette kan godt tage lidt tid...*/}
          {/*</div>*/}
          <LoadingAnimation />
        </div>
      )}
      <EmailEditor ref={emailEditorRef} onReady={onReady} />
      {!isLoading && (
        <div style={{ width: 300, margin: 'auto' }}>
          <Button fullWidth onClick={onCompleteClick}>
            Færdig
          </Button>
        </div>
      )}
    </div>
  );
};
