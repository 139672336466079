import { FC, ReactNode } from 'react';
import styles from './text.module.css';

type TextType = 'body' | 'body-strong' | 'caption' | 'detail';

interface TextProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  type: TextType;
}

export const Text: FC<TextProps> = ({ children, type, ...props }) => (
  <div className={`${styles[type]} ${props.className || ''}`} {...props}>
    {children}
  </div>
);
