import { PostgrestError } from '@supabase/supabase-js';
import logger from '../../sharedPacakge/logger';

export const isNoRowsError = (error: PostgrestError) =>
  error.code === 'PGRST116';

type NotPostgrestError<T> = T extends PostgrestError ? never : T;

/**
 * Asserts that there is no Supabase error. Throws an error if one exists or if no rows are returned and `treatNoRowsAsError` is `true`.
 *
 * @param data - The data returned from Supabase.
 * @param error - The error object from Supabase.
 * @param context - A string describing the context in which the error occurred.
 * @param contextData - Additional context data for logging (optional).
 * @param treatNoRowsAsError - Whether to treat a 'no rows' response as an error (optional).
 * @throws Throws an error if `error` is not null or if no rows are returned and `treatNoRowsAsError` is `true`.
 */
export function assertNoSupabaseError<
  T extends any,
  TreatNoRowsAsError extends boolean
>(
  data: NotPostgrestError<T> | null, // ensures that the error is not passed in data field
  error: PostgrestError | null,
  context: string,
  contextData?: object,
  treatNoRowsAsError?: TreatNoRowsAsError
): asserts data is TreatNoRowsAsError extends true
  ? NotPostgrestError<T>
  : NotPostgrestError<T> | null {
  if (error) {
    // don't make checks to the actual data, because null can be passed if for example its a post or delete with no selects on it
    // supabase treats no no rows returned as an error per default
    if (treatNoRowsAsError || !isNoRowsError(error)) {
      logger.supabaseError(context, error, contextData);
      throw error;
    }
  }
}
