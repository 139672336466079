import { supabase } from '../../../supabase/supabaseClient';
import { userProfileRecord} from '../../../../types/supabase/collections';
import logger from '../../../../sharedPacakge/logger';

export const updateUserProfile = async (
    id: string,
    newData: Partial<userProfileRecord>
): Promise<void> => {
    const { error } = await supabase
    .from('user_profiles')
    .update(newData)
    .eq('id', id);

    if (error) {
        logger.supabaseError('updateUserProfile', error);
        throw error;
    }
};
