import styles from '../../newFoundItemButton.module.css';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MuiLocalizationAndThemeProvider } from '../../../../../../../sharedPacakge/components/MuiLocalizationAndThenmeProvider/MuiLocalizationAndThemeProvider';
import { DatePicker, DesktopTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { isToday } from '../../../../../../../utils/isToday';
import { AutocompleteInput } from '../../../../../../../components/AutoCompleteInput/AutoCompleteInput';
import { TextField } from '@mui/material';
import React, { FC, useState } from 'react';
import { SetState } from '../../../../../../../sharedPacakge/types/utilityTypes';
import { useLocationData } from '../../../../../../../contexts/LocationDataContext/LocationDataContext';

export interface OptionalNewFoundItemData {
  foundAtDateTime: Date;
  foundAtLocation: string;
  storageLocation: string;
  notes: string;
}
interface Props {
  optionalFields: OptionalNewFoundItemData;
  setOptionalFields: SetState<OptionalNewFoundItemData>;
}

export const OptionalNewFoundItemFields: FC<Props> = ({
  optionalFields,
  setOptionalFields
}) => {
  const { location } = useLocationData();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  if (!location) return null;

  const onDatetimeChange = (value: any) => {
    setOptionalFields((prevState) => ({
      ...prevState,
      foundAtDateTime: value.$d
    }));
  };
  const onShowOptionalFieldsClick = (e: any) => {
    e.preventDefault();
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <div
        className={styles.moreFields__button}
        onClick={onShowOptionalFieldsClick}
      >
        Vis flere felter {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}{' '}
      </div>
      {isOpen && (
        <div>
          <div className={`${styles.container} ${styles.split__container}`}>
            <MuiLocalizationAndThemeProvider>
              <DatePicker
                onChange={onDatetimeChange}
                value={dayjs(optionalFields.foundAtDateTime)}
                maxDate={dayjs(new Date())}
              />
              <DesktopTimePicker
                onAccept={onDatetimeChange}
                value={dayjs(optionalFields.foundAtDateTime)}
                maxTime={
                  isToday(optionalFields.foundAtDateTime)
                    ? dayjs(new Date())
                    : undefined
                }
              />
            </MuiLocalizationAndThemeProvider>
          </div>
          <div className={`${styles.container} ${styles.split__container}`}>
            <AutocompleteInput
              label="Fundet ved (Valgfri)"
              options={location.found_at_location_options}
              value={optionalFields.foundAtLocation}
              onChange={(newState) =>
                setOptionalFields((prevState) => ({
                  ...prevState,
                  foundAtLocation: newState
                }))
              }
            />
            <AutocompleteInput
              label="Opbevaringssted (Valgfri)"
              options={location.storage_location_options}
              value={optionalFields.storageLocation}
              onChange={(newState) =>
                setOptionalFields((prevState) => ({
                  ...prevState,
                  storageLocation: newState
                }))
              }
            />
          </div>
          <div className={styles.container}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Noter (Valgfri)"
              variant="outlined"
              value={optionalFields.notes}
              onChange={(e: any) =>
                setOptionalFields((prevState) => ({
                  ...prevState,
                  notes: e.target.value
                }))
              }
              multiline
            />
          </div>
        </div>
      )}
    </>
  );
};
