import { supabase } from '../../../supabase/supabaseClient';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';
import { superUserEmail } from '../../../../utils/constants';
import { Role, UserRoleRecord } from '../../../../types/supabase/collections';

export interface UserTabUser {
  isUserInvite: false;
  id: string;
  email: string;
  fullName: string;
  role: Role;
  lastActiveAt: Date;
  locationIds: number[];
}
export interface UserTabInvite {
  isUserInvite: true;
  id: number;
  email: string;
  fullName: string;
  role: Role;
  expiryDate: Date;
  locationIds: number[];
}

export type UserOrInvite = UserTabUser | UserTabInvite;

interface FilteredUserRole {
  role: string;
  locationIds: number[];
  userId: string;
}

function filterUniqueUserRoles(
  userRoles: UserRoleRecord[]
): FilteredUserRole[] {
  const result: FilteredUserRole[] = [];

  const map: Map<string, FilteredUserRole> = new Map();

  userRoles.forEach((item) => {
    const { role, location_id, user_id } = item;

    if (!map.has(user_id)) {
      map.set(user_id, { role, locationIds: [], userId: user_id });
    }

    if (location_id !== null) {
      map.get(user_id)?.locationIds.push(location_id);
    }
  });

  map.forEach((value) => result.push(value));

  return result;
}

export async function getUsersAndInvites(
  organisationId: number,
  allLocationIdsInOrg: number[]
): Promise<Array<UserOrInvite>> {
  const { data: userRoles, error: userRolesError } = await supabase
    .from('user_roles')
    .select()
    .eq('organisation_id', organisationId);

  assertNoSupabaseError(
    userRoles,
    userRolesError,
    'getOrganisationUsers userRolesError:',
    { organisationId }
  );

  // todo combine all userRoles such i get "location" & "role"

  let allAdmins: UserOrInvite[] = [];
  if (userRoles) {
    const uniqueUserRoles = filterUniqueUserRoles(userRoles);
    const userIdList = uniqueUserRoles.map((user) => user.userId);

    const { data: userProfiles, error: userProfilesError } = await supabase
      .from('user_profiles')
      .select()
      .in('id', userIdList);

    assertNoSupabaseError(
      userProfiles,
      userProfilesError,
      'getUsersAndInvites userProfilesError:',
      { userRoles },
      true
    );

    allAdmins = uniqueUserRoles.map((userRole) => {
      const userProfile = userProfiles.find(
        (profile) => profile.id === userRole.userId
      )!;

      return {
        id: userRole.userId,
        email: userProfile.email,
        fullName: userProfile.full_name,
        role: userRole.role as Role,
        locationIds: userRole.locationIds,
        isUserInvite: false,
        lastActiveAt: new Date(userProfile.last_active_at)
      };
    });
  }

  const { data: userInvites, error: userInvitesError } = await supabase
    .from('user_invites')
    .select()
    .eq('organisation_id', organisationId)
    .gte('expiry_date', new Date().toISOString());

  assertNoSupabaseError(
    userInvites,
    userInvitesError,
    'getAllAdmins userInvitesError:'
  );

  let allUserInvites: UserOrInvite[] = [];
  if (userInvites) {
    allUserInvites = userInvites.map((invite) => ({
      id: invite.id,
      email: invite.email,
      fullName: invite.email.split('@')[0],
      isUserInvite: true,
      locationId: invite.location_id,
      expiryDate: new Date(invite.expiry_date),
      role: invite.role as Role, // Ensure role is Role.LocationAdmin
      locationIds: invite.location_id
        ? [invite.location_id]
        : allLocationIdsInOrg // Add locationId to locationIds array
    }));
  }

  const adminListExceptSuperUser = [...allAdmins, ...allUserInvites].filter(
    (admin) => admin.email !== superUserEmail
  );

  return adminListExceptSuperUser;
}
