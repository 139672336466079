import FindyApi from '../sharedPacakge/findyApi/fetchFindyApi';
import { InquiryEmailType } from '../types/supabase/collections';

export const sendInquiryEmail = async ({
  organisationId,
  inquiryId,
  emailType
}: {
  organisationId: number;
  inquiryId: number;
  emailType: InquiryEmailType;
}): Promise<{ success: true }> =>
  await FindyApi.post('inquiry-email', {
    organisationId,
    inquiryId,
    emailType
  });
