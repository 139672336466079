import React, { useState } from 'react';
import { singupWithEmail } from './signupWithemail';
import { TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import styles from '../loginAndSignupStyles.module.css';
import logo from '../../../assets/logo/darkFindyLogo.png';
import Page from '../../Page';
import { SetPageTitle } from '../../../utils/SetPageTitle';
import { Button } from '../../../sharedPacakge/components/Button/Button';
import { FindyLogo } from '../../LocationPage/components/FindyLogo/FindyLogo';

const isEmailValid = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};
const isPasswordValid = (password: string) => {
  return password.length >= 8;
};

const Signup = () => {
  SetPageTitle('Opret Bruger');
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setconfirmPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasSignedUp, setHasSignedUp] = useState(false);

  const formInputIsValid = (): boolean => {
    if (fullName === '' || fullName === ' ') {
      setErrorMessage('Navn er ikke udfyldt.');
      return false;
    }

    if (!isEmailValid(email)) {
      setErrorMessage('Indtast venligst en gyldig email-adresse.');
      return false;
    }

    if (!isPasswordValid(password)) {
      setErrorMessage('Adgangskoden skal være mindst 8 tegn lang.');
      return false;
    }

    if (confirmPassword !== password) {
      setErrorMessage('Adgangskoderne matcher ikke.');
      return false;
    }

    setErrorMessage('');
    return true;
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!formInputIsValid()) return;

    if (!isRegistering && !hasSignedUp) {
      setIsRegistering(true);
      const { success } = await singupWithEmail({
        email: email.toLowerCase(),
        password,
        fullName
      });

      if (success) setHasSignedUp(true);
      else setIsRegistering(false);
    }
  };

  return (
    <main className={styles.container}>
      <div className={styles.logo}>
        <FindyLogo height={50} />
      </div>

      <form className={styles.form} onSubmit={onSubmit}>
        {hasSignedUp ? (
          <>
            <h1 className={styles.title}>Bekræft din email</h1>
            <p style={{ textAlign: 'center' }}>
              Vi har sendt dig en mail. Bekræft venligst din email, før du
              foretsætter til login.
            </p>
            <Button onClick={() => navigate(Page.login)}>Gå til login</Button>
          </>
        ) : (
          <>
            <h1 className={styles.title}>Opret en ny bruger</h1>
            <TextField
              label="Fulde Navn"
              type="text"
              value={fullName}
              onChange={(e: any) => setFullName(e.target.value)}
              fullWidth
              variant="outlined"
              disabled={isRegistering}
              autoComplete="name"
            />
            <TextField
              label="Email"
              type="text"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              fullWidth
              variant="outlined"
              disabled={isRegistering}
              autoComplete="email"
            />
            <TextField
              label="Adgangskode"
              type="password"
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
              fullWidth
              variant="outlined"
              disabled={isRegistering}
            />
            <TextField
              label="Gentag adgangskode"
              type="password"
              value={confirmPassword}
              onChange={(e: any) => setconfirmPassword(e.target.value)}
              fullWidth
              variant="outlined"
              disabled={isRegistering}
            />
            {errorMessage && (
              <p className={styles.errorMessage}>{errorMessage}</p>
            )}
            <Button disabled={isRegistering} type="submit" fullWidth>
              {isRegistering ? 'Opretter...' : 'Opret'}
            </Button>
            <p className={styles.noUser__text}>
              Har du allerede en bruger? {'   '}
              <Link className={styles.noUser__link} to={'/login'}>
                Log ind her
              </Link>
            </p>
          </>
        )}
      </form>
    </main>
  );
};

export default Signup;
