import { supabase } from '../../../supabase/supabaseClient';
import { MatchRecord } from '../../../../types/supabase/collections';
import logger from '../../../../sharedPacakge/logger';

export const updateMatch = async (
  matchId: number,
  newData: Partial<MatchRecord>
): Promise<void> => {
  const { error } = await supabase
    .from('matches')
    .update(newData)
    .eq('id', matchId);

  if (error) {
    logger.supabaseError('updateMatch', error);
    throw error;
  }
};
