import React, { FC, useEffect } from 'react';
import styles from './infoModal.module.css';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import Diversity2OutlinedIcon from '@mui/icons-material/Diversity2Outlined';
import { useAuth } from '../../../../../contexts/AuthContext/AuthContext';
import GuidingCard from './components/GuidingCard/GuidingCard';
import Page from '../../../../Page';
import { useLocationData } from '../../../../../contexts/LocationDataContext/LocationDataContext';
import { Modal } from '../../../../../sharedPacakge/components/Modal/Modal';
import { SetState } from '../../../../../sharedPacakge/types/utilityTypes';
import { useSnackbar } from '../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';

const InfoModal: FC<{ setIsOpen: SetState<boolean> }> = ({ setIsOpen }) => {
  const { showSnackbar } = useSnackbar();
  const { user, username } = useAuth();
  const { organisation } = useLocationData();

  useEffect(() => {
    document.title = organisation?.name || 'Startside';
  }, [organisation]);

  if (!user || !organisation) return null;

  const onCopyClick = (e: any, type: 'link' | 'iframe') => {
    e.preventDefault();

    if (type === 'iframe') {
      const iframeString = `<iframe src="${Page.external.lostSomethingIframe(organisation.id, organisation.name)}" width="600" height="900" style="border:none;"></iframe>`;
      navigator.clipboard.writeText(iframeString);
    } else {
      const linkUrl = Page.external.lostSomething(
        organisation.id,
        organisation.name
      );
      navigator.clipboard.writeText(linkUrl);
    }

    showSnackbar(`${type} kopieret`);
  };

  return (
    <Modal
      title="Hjælp og support"
      setIsOpen={setIsOpen}
      className={styles.modal}
    >
      <div className={styles.container}>
        <div className={styles.text__containers}>
          <p>Hej {username}</p>
          <h2 className={styles.titles}>Sådan kommer du i gang!</h2>
        </div>
        <div className={styles.guidingCards__container}>
          <GuidingCard
            tag="Step 1"
            icon={<FmdGoodOutlinedIcon className={styles.card__icon} />}
            title="Tilføj flere lokationer"
            description={() => (
              <p className={styles.lostItemPageCard__description}>
                Tilføj en eller flere lokationer til din organisation under
                "Tilpasning" siden.
              </p>
            )}
          />
          <GuidingCard
            tag="Step 2"
            icon={<GroupAddOutlinedIcon className={styles.card__icon} />}
            title="Inviter andre brugere"
            description={() => (
              <p className={styles.lostItemPageCard__description}>
                Inviter andre brugere til din organisation eller enkelte
                lokationer under "Brugere" siden.
              </p>
            )}
          />
          <GuidingCard
            tag="Step 3"
            icon={<Diversity2OutlinedIcon className={styles.card__icon} />}
            title="Indsæt efterlysnings-formular"
            description={() => (
              <div className={styles.lostItemPageCard__description}>
                <p>Sådan kan dine kunder efterlyse deres genstande via:</p>
                <p>
                  1. Et link til Findy{' '}
                  <button
                    className={styles.copy__button}
                    onClick={(e) => onCopyClick(e, 'link')}
                  >
                    kopier
                  </button>
                </p>
                <p>
                  2. En iframe kode på jeres hjemmeside{' '}
                  <button
                    className={styles.copy__button}
                    onClick={(e) => onCopyClick(e, 'iframe')}
                  >
                    kopier
                  </button>
                </p>
              </div>
            )}
          />
        </div>
        <div className={styles.text__containers}>
          <h2 className={styles.titles}>Brug for hjælp?</h2>
          <p className={styles.needHelp__text}>info@findy.dk</p>
        </div>
      </div>
    </Modal>
  );
};

export default InfoModal;
