import Api from '../Api/Api';
import { getNewExpiryDate } from '../../utils/getExpiryDate';
import {
  FoundItem,
  Inquiry,
  LocationRecord
} from '../../types/supabase/collections';
import { ItemStatus } from '../../sharedPacakge/types/ItemStatus';
import { runMatchmaking } from './runMatchmaking';
import logger from '../../sharedPacakge/logger';

interface UnMatchArgs {
  inquiryId: number;
  foundItemId: number;
  location: LocationRecord;
}

const getItems = async (
  args: UnMatchArgs
): Promise<{
  inquiry: Inquiry;
  foundItem: FoundItem;
}> => {
  const { inquiryId, foundItemId } = args;
  const [inquiry, foundItem] = await Promise.all([
    Api.get.inquiry(inquiryId),
    Api.get.foundItem(foundItemId)
  ]);
  if (!inquiry || !foundItem) {
    logger.error(
      'unMatchItems failed, due to either missing inquiry or foundItem'
    );
    throw new Error(
      'unMatchItems failed, due to either missing inquiry or foundItem'
    );
  }
  return { inquiry, foundItem };
};

export const unMatchItems = async (args: UnMatchArgs): Promise<void> => {
  console.log('Args', args);

  try {
    const { inquiryId, foundItemId, location } = args;
    const { inquiry, foundItem } = await getItems(args);
    await Api.delete.matches((query) =>
      query.eq('inquiry_id', inquiryId).eq('found_item_id', foundItemId)
    );

    // updates inquiry
    const newInquiryExpiryDate = getNewExpiryDate(
      new Date(inquiry.lost_at_date),
      inquiry.is_high_value_item
        ? location.high_value_item_expiry
        : location.low_value_item_expiry
    );
    const inquiryHasExpired =
      newInquiryExpiryDate.getTime() < new Date().getTime();

    await Api.put.inquiry(inquiryId, {
      expiry_date: newInquiryExpiryDate.toISOString(),
      status: inquiryHasExpired ? ItemStatus.Expired : ItemStatus.AwaitingMatch
    });

    await runMatchmaking({
      ...inquiry,
      category_id: inquiry.category.id
    });

    // updates found item
    const newFoundItemExpiryDate = getNewExpiryDate(
      new Date(foundItem.found_at_date),
      foundItem.is_high_value_item
        ? location.high_value_item_expiry
        : location.low_value_item_expiry
    );
    const foundItemHasExpired =
      newFoundItemExpiryDate.getTime() < new Date().getTime();

    await Api.put.foundItem(foundItemId, {
      expiry_date: newFoundItemExpiryDate.toISOString(),
      status: foundItemHasExpired
        ? ItemStatus.Expired
        : ItemStatus.AwaitingMatch
    });

    await runMatchmaking({
      ...foundItem,
      category_id: foundItem.category.id
    });
  } catch (error) {
    logger.error(`UnmatchItems failed due to: ${JSON.stringify(error)}`);
    throw error;
  }
};
