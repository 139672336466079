import INQUIRY_CREATED_content from './defaultEmailTemplates/INQUIRY_CREATED/content';
import INQUIRY_CREATED_content_json from './defaultEmailTemplates/INQUIRY_CREATED/content_json.json';
import WE_ARE_STILL_SEARCHING_content from './defaultEmailTemplates/WE_ARE_STILL_SEARCHING/content';
import WE_ARE_STILL_SEARCHING_content_json from './defaultEmailTemplates/WE_ARE_STILL_SEARCHING/content_json.json';
import ITEM_FOUND_READY_FOR_PICKUP_content from './defaultEmailTemplates/ITEM_FOUND_READY_FOR_PICKUP/content';
import ITEM_FOUND_READY_FOR_PICKUP_content_json from './defaultEmailTemplates/ITEM_FOUND_READY_FOR_PICKUP/content_json.json';
import WE_DIDNT_FIND_YOUR_ITEM_content from './defaultEmailTemplates/WE_DIDNT_FIND_YOUR_ITEM/content';
import WE_DIDNT_FIND_YOUR_ITEM_content_json from './defaultEmailTemplates/WE_DIDNT_FIND_YOUR_ITEM/content_json.json';
import X_DAYS_LEFT_TO_PICKUP_content from './defaultEmailTemplates/X_DAYS_LEFT_TO_PICKUP/content';
import X_DAYS_LEFT_TO_PICKUP_content_json from './defaultEmailTemplates/X_DAYS_LEFT_TO_PICKUP/content_json.json';
import ITEM_NEVER_PICKED_UP_content from './defaultEmailTemplates/ITEM_NEVER_PICKED_UP/content';
import ITEM_NEVER_PICKED_UP_content_json from './defaultEmailTemplates/ITEM_NEVER_PICKED_UP/content_json.json';
import {
  InquiryEmailTemplate,
  InquiryEmailType
} from '../../types/supabase/collections';

export const getDefaultEmailTemplate = (
  inquiryEmailType: InquiryEmailType,
  organisationId: number
): Omit<InquiryEmailTemplate, 'id'> => {
  let variableValues;

  switch (inquiryEmailType) {
    case 'INQUIRY_CREATED':
      variableValues = {
        subject: 'Vi har modtaget din efterlysning!',
        content: INQUIRY_CREATED_content,
        content_json: INQUIRY_CREATED_content_json,
        scheduled_in_x_days: null
      };
      break;
    case 'WE_ARE_STILL_SEARCHING':
      variableValues = {
        subject: 'Vi leder stadig efter din genstand!',
        content: WE_ARE_STILL_SEARCHING_content,
        content_json: WE_ARE_STILL_SEARCHING_content_json,
        scheduled_in_x_days: 5
      };
      break;
    case 'ITEM_FOUND_READY_FOR_PICKUP':
      variableValues = {
        subject: 'Vi har fundet din genstand!',
        content: ITEM_FOUND_READY_FOR_PICKUP_content,
        content_json: ITEM_FOUND_READY_FOR_PICKUP_content_json,
        scheduled_in_x_days: null
      };
      break;

    case 'WE_DIDNT_FIND_YOUR_ITEM':
      variableValues = {
        subject: 'Din genstand er ikke hos os…',
        content: WE_DIDNT_FIND_YOUR_ITEM_content,
        content_json: WE_DIDNT_FIND_YOUR_ITEM_content_json,
        scheduled_in_x_days: null
      };
      break;
    case 'X_DAYS_LEFT_TO_PICKUP':
      variableValues = {
        subject: 'Du har endnu ikke afhentet din genstand!',
        content: X_DAYS_LEFT_TO_PICKUP_content,
        content_json: X_DAYS_LEFT_TO_PICKUP_content_json,
        scheduled_in_x_days: 5
      };
      break;
    case 'ITEM_NEVER_PICKED_UP':
      variableValues = {
        subject: 'Din genstand er blevet afskaffet!',
        content: ITEM_NEVER_PICKED_UP_content,
        content_json: ITEM_NEVER_PICKED_UP_content_json,
        scheduled_in_x_days: null
      };
      break;
  }

  console.log('content_json', variableValues.content_json);
  return {
    ...variableValues,
    enabled: true,
    inquiry_email_type: inquiryEmailType,
    organisation_id: organisationId
  };
};
