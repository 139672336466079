import React, { FC, useState } from 'react';
import { ChromePicker } from 'react-color';
import { ClickAwayListener, Paper } from '@mui/material';
import styles from './colorPicker.module.css';

interface Props {
  label: string;
  value: string;
  onChange: (newValue: string) => void;
  disabled?: boolean;
}

export const ColorInput: FC<Props> = ({ label, value, onChange, disabled }) => {
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);

  const handleDivClick = (e: any) => {
    e.preventDefault();
    setIsColorPickerVisible(true);
  };

  const handleClickAway = (e: any) => {
    e.preventDefault();
    setIsColorPickerVisible(false);
  };

  // Update color picker position on scroll or resize

  return (
    <div style={{ position: 'relative' }}>
      <small>{label}</small>
      <div
        onClick={handleDivClick}
        className={styles.previewColor}
        style={{
          backgroundColor: value ?? '#ffffff'
        }}
      />
      {!disabled && isColorPickerVisible && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper
            elevation={3}
            style={{
              position: 'absolute',
              top: '73px',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 10
            }}
          >
            <ChromePicker
              color={value ?? ''}
              onChange={(newColor: any) => onChange(newColor.hex)}
            />
          </Paper>
        </ClickAwayListener>
      )}
    </div>
  );
};
