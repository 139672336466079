import { supabase } from '../../../supabase/supabaseClient';
import { Location } from '../../../../types/supabase/collections';
import logger from '../../../../sharedPacakge/logger';

export const updateLocation = async (
  locationId: number,
  newData: Partial<Location>
): Promise<void> => {
  const { error } = await supabase
    .from('locations')
    .update(newData)
    .eq('id', locationId);

  if (error) {
    logger.supabaseError('updateLocation', error);
    throw error;
  }
};
