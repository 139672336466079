import React, { FC, ReactNode } from 'react';
import styles from './tabLayout.module.css';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import { useIsMobile } from '../../../../utils/hooks/useIsMobile';
import Page from '../../../Page';
import { SetPageTitle } from '../../../../utils/SetPageTitle';
import { useActiveTab } from '../../hooks/useActiveTab';
interface Props {
  children: ReactNode;
}

export const TabLayout: FC<Props> = ({ children }) => {
  const { location, organisation } = useLocationData();
  const isMobile = useIsMobile();
  const { activeTab } = useActiveTab();

  SetPageTitle(activeTab ? activeTab.name : 'Findy');
  if (!location || !activeTab) return null;

  const onCreateInquiryClick = (e: any) => {
    e.preventDefault();
    if (!organisation) return;
    document.location.href = Page.external.lostSomething(
      organisation.id,
      organisation.name
    );
  };

  return (
    <div id="scrollable-tab" className={styles.container}>
      {/*{isMobile && (*/}
      {/*  <div className={styles.mobileSubHeader}>*/}
      {/*    <NewFoundItemButton*/}
      {/*      classes={{ button: styles.mobileSubHeader__buttons }}*/}
      {/*    />*/}
      {/*    <div className={styles.mobileSubHeader__secondRow}>*/}
      {/*      <NewFoundCardButton*/}
      {/*        className={styles.mobileSubHeader__buttons}*/}
      {/*        fullWidth*/}
      {/*      />*/}
      {/*      <Button*/}
      {/*        className={styles.mobileSubHeader__buttons}*/}
      {/*        buttonType="secondary"*/}
      {/*        onClick={onCreateInquiryClick}*/}
      {/*        fullWidth*/}
      {/*      >*/}
      {/*        <ThreePOutlinedIcon />*/}
      {/*        Efterlys*/}
      {/*      </Button>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
      {/*<div className={styles.contentAndTitle}>*/}
      {/*<Gap size={8} />*/}
      {/*<Text type={'body'}>{activeTab?.description}</Text>*/}
      {/*<Gap size={8} />*/}
      <div className={styles.content__container}>{children}</div>
      {/*</div>*/}
    </div>
  );
};
