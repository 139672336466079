import { supabase } from '../../../supabase/supabaseClient';
import {
  ArchivedFoundItem,
  FoundItem,
  ItemCategory
} from '../../../../types/supabase/collections';
import { foundItemFields } from './getFoundItems';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export async function getFoundItem<T extends boolean>(
  id: number,
  archived: T = false as T
): Promise<T extends true ? ArchivedFoundItem : FoundItem> {
  const { data, error } = archived
    ? await supabase
        .from('archived_found_items')
        .select(foundItemFields)
        .eq('id', id)
        .single()
    : await supabase
        .from('found_items')
        .select(foundItemFields)
        .eq('id', id)
        .single();

  assertNoSupabaseError(data, error, 'getFoundItem error:', {}, true);

  return {
    ...data,
    category: data.categories as ItemCategory
  } as unknown as T extends true ? ArchivedFoundItem : FoundItem;
}
