import React from 'react';
import { useIsMobile } from '../../utils/hooks/useIsMobile';
import GenericTableMobile from './MobileTable';
import GenericTableDesktop from './DesktopTable';

export interface Column<T> {
  id: keyof T | string;
  label: string;
  render: (row: T) => React.ReactNode;
  renderMobile?: true | ((row: T) => React.ReactNode); // if left empty, then it won't appear on mobile
}

export interface GenericTableProps<T> {
  columns: Column<T>[];
  data: T[];
  onRowClick?: (e: any, rowData: T) => void;
  isRowLoading?: (rowData: T) => void;
  rowKey: keyof T;
  actionsColumn?: (row: T) => React.ReactNode;
  multiSelect?: {
    enabled: true;
    onSelectedRowChange: (selectedRow: T[]) => void;
  };
}

function GenericTable<T extends Record<string, any>>(
  props: GenericTableProps<T>
) {
  const isMobile = useIsMobile();

  return isMobile ? (
    <GenericTableMobile {...props} />
  ) : (
    <GenericTableDesktop {...props} />
  );
}

export default GenericTable;
