import { FC } from 'react';
import { AgCharts } from 'ag-charts-react';
import { AgCartesianChartOptions } from 'ag-charts-community';
import dayjs from 'dayjs';
import { FindyInternalInsightsResponse } from '../../InternalInsightsPage';
import { MatchRecord } from '../../../../types/supabase/collections';

interface Props {
  data: FindyInternalInsightsResponse | null; // Handle cases where data might be null
  startDate: Date;
  recordType:
    | 'foundItems'
    | 'inquiries'
    | 'matches_created'
    | 'matches_confirmed'
    | 'matches_rejected'; // Type of record to aggregate
}

export const DateGraph: FC<Props> = ({ data, startDate, recordType }) => {
  let recordName: 'foundItems' | 'inquiries' | 'matches';
  if (
    recordType === 'matches_created' ||
    recordType === 'matches_confirmed' ||
    recordType === 'matches_rejected'
  ) {
    recordName = 'matches';
  } else {
    recordName = recordType;
  }

  const getAggregatedData = () => {
    if (!data) return [];

    const orgDateCountMap: Record<string, Record<string, number>> = {}; // Map for counting items by organisation and date
    const start = dayjs(startDate).startOf('day');
    const end = dayjs(); // Current day

    // Initialize the orgDateCountMap for each organisation
    data.organisations.forEach((organisation) => {
      orgDateCountMap[organisation.id] = {};
    });

    // Aggregate counts based on the selected record type (foundItems, inquiries, or matches)
    data.organisations.forEach((organisation) => {
      const records = organisation[recordName]; // Dynamically access the record type
      if (records) {
        records.forEach((record) => {
          let createdDate;

          if (recordName === 'matches') {
            // Type guard for matches, to handle matches-specific fields
            const matchRecord = record as MatchRecord;

            if (recordType === 'matches_confirmed') {
              if (!matchRecord.match_confirmed) return; // Filter out matches that are not confirmed
            }

            if (recordType === 'matches_rejected') {
              if (!matchRecord.match_rejected) return; // Filter out matches that are not rejected
            }

            if (
              (recordType === 'matches_confirmed' ||
                recordType === 'matches_rejected') &&
              matchRecord.confirmed_or_rejected_at
            ) {
              createdDate = dayjs(matchRecord.confirmed_or_rejected_at);
            } else {
              createdDate = dayjs(matchRecord.created_at);
            }
          } else {
            // Handle foundItems and inquiries
            createdDate = dayjs(record.created_at);
          }

          // Filter dates after start date
          if (createdDate.isAfter(start)) {
            const date = createdDate.format('YYYY-MM-DD');
            orgDateCountMap[organisation.id][date] =
              (orgDateCountMap[organisation.id][date] || 0) + 1;
          }
        });
      }
    });

    // Create an array of dates from startDate to today
    const allDates: Array<Record<string, any>> = [];
    let currentDate = start.clone();

    while (currentDate.isBefore(end) || currentDate.isSame(end, 'day')) {
      const dateKey = currentDate.format('YYYY-MM-DD');
      const orgData: Record<string, any> = { date: currentDate.toDate() }; // Initialize with the current date

      // Add counts for each organisation for the current date
      data.organisations.forEach((organisation) => {
        orgData[organisation.id] =
          orgDateCountMap[organisation.id][dateKey] || 0;
      });

      allDates.push(orgData);
      currentDate = currentDate.add(1, 'day');
    }

    return allDates; // Return all dates with counts for each organisation
  };

  const chartData = getAggregatedData();

  if (!chartData.length) return null;

  const chartOptions: AgCartesianChartOptions = {
    data: chartData,
    title: {
      text: recordType
    },
    subtitle: {
      text: `Number of ${recordType} over time`
    },
    series: data!.organisations.map((organisation) => ({
      type: 'line',
      xKey: 'date',
      yKey: organisation.id.toString(),
      yName: `${organisation.name} (${organisation.id})`,
      marker: { enabled: false }
    })),
    axes: [
      {
        type: 'time',
        position: 'bottom',
        title: { text: 'Date' }
      },
      {
        type: 'number',
        position: 'left',
        title: { text: `Count of ${recordType}` }
      }
    ],
    theme: {
      overrides: {
        line: {
          series: {
            interpolation: {
              type: 'smooth'
            },
            tooltip: {
              renderer: ({ title, datum, xKey, yKey, xName, yName }) => ({
                title,
                content: `${datum[yKey]} | ${formatDate(datum[xKey])}`
              })
            }
          }
        }
      }
    },
    legend: { enabled: true }
  };

  return (
    <div style={{ width: '100%' }}>
      <AgCharts options={chartOptions} />
    </div>
  );
};

export function formatDate(isoDate: string): string {
  const date = new Date(isoDate);

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long', // Add this line to include the weekday
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  };

  // Create the formatter
  const formatter = new Intl.DateTimeFormat('da-DK', options);

  // Get the parts of the formatted date
  const parts = formatter.formatToParts(date);

  // Extract the parts
  const weekday = parts.find((part) => part.type === 'weekday')?.value; // Extract the weekday
  const day = parts.find((part) => part.type === 'day')?.value;
  const month = parts.find((part) => part.type === 'month')?.value;
  const year = parts.find((part) => part.type === 'year')?.value;

  // Construct the formatted string including the weekday
  return `${weekday} d. ${day} ${month} ${year}`;
}
