import React, { createContext, useContext, ReactNode, useState } from 'react';
import { Snackbar, SnackbarType } from './Snackbar';
import styles from './snackbar.module.css';
import ReactDOM from 'react-dom';

interface SnackbarContextProps {
  showSnackbar: (message: string | ReactNode, type?: SnackbarType) => void;
}

const SnackbarContext = createContext<SnackbarContextProps | undefined>(
  undefined
);

interface SnackbarProviderProps {
  children: ReactNode;
}

interface SnackbarData {
  id: string;
  message: string | ReactNode;
  type: SnackbarType;
  autoHideDuration: number;
}

const AUTOHIDE_DURATION = 4000;
const AUTOHIDE_DURATION_FOR_WARNINGS = 10000;

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({
  children
}) => {
  const [snackbars, setSnackbars] = useState<SnackbarData[]>([]);

  const showSnackbar = (
    message: string | ReactNode,
    type: SnackbarType = 'regular',
    autoHideDuration?: number
  ) => {
    const id = `${Date.now()}-${Math.random()}`; // Unique ID

    let duration: number = AUTOHIDE_DURATION;
    if (type === 'warning' || type === 'error')
      duration = AUTOHIDE_DURATION_FOR_WARNINGS;
    if (autoHideDuration) duration = autoHideDuration;

    setSnackbars((prevSnackbars) => [
      ...prevSnackbars,
      { id, message, type, autoHideDuration: duration }
    ]);
  };

  const handleSnackbarClose = (id: string) => {
    setSnackbars((prevSnackbars) =>
      prevSnackbars.filter((snackbar) => snackbar.id !== id)
    );
  };

  const portal = document.getElementById('snackbar-root');
  if (!portal) {
    console.warn("Snackbar can't open, there is no snackbar root in the HTML");
    return null;
  }

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      {snackbars.length > 0 &&
        ReactDOM.createPortal(
          <div className={styles.snackbars__container}>
            {snackbars.map((snackbar) => (
              <Snackbar
                key={snackbar.id}
                message={snackbar.message}
                open={true}
                close={() => handleSnackbarClose(snackbar.id)}
                type={snackbar.type}
                autoHideDuration={snackbar.autoHideDuration}
              />
            ))}
          </div>,
          portal
        )}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = (): SnackbarContextProps => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};
