import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FoundItem } from '../../../../../../types/supabase/collections';
import { useModalNavigation } from '../../../../../../sharedPacakge/components/Modal/Modal';
import { Button } from '../../../../../../sharedPacakge/components/Button/Button';
import { Text } from '../../../../../../components/Text/Text';
import { useSnackbar } from '../../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { SetState } from '../../../../../../sharedPacakge/types/utilityTypes';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import Api from '../../../../../../servieces/Api/Api';
import { ItemStatus } from '../../../../../../sharedPacakge/types/ItemStatus';
import { ViewItemModalCallbacks } from '../../ViewItemModalCallbacks';

interface Props<T> {
  foundItem: T;
  callbacks: ViewItemModalCallbacks<T>;
}

function EditContent<T extends FoundItem>({ callbacks, foundItem }: Props<T>) {
  const { navigation, setNavigation } = useModalNavigation();
  const { showSnackbar } = useSnackbar();

  const handleSubmit = useCallback(() => {
    Api.put
      .foundItem(foundItem.id, {
        status: ItemStatus.ReadyForPickup
      })
      .then(() => {
        callbacks.onUpdateItem(foundItem.id, {
          ...foundItem,
          status: ItemStatus.ReadyForPickup
        });
        showSnackbar('Status ændret');
        setNavigation(null);
      });

    setNavigation(null);
  }, [foundItem, setNavigation]);

  useEffect(() => {
    if (navigation) {
      // Only update navigation if needed
      setNavigation((prev) => {
        if (prev && prev.onSubmit !== handleSubmit) {
          return {
            ...prev,
            onSubmit: handleSubmit
          };
        }
        return prev; // Ensure we return prev in both branches
      });
    }
  }, [navigation, handleSubmit]); // handleSubmit is still a dependency, but we mitigate the issue

  return (
    <div>
      <Text type={'body-strong'}>
        Genstand uden match sættes til afhentning
      </Text>
      <Text type={'body'}>
        (Anvendes når genstandens ejer er funden, uden vedkommende har oprettet
        en efterlysning)
      </Text>
    </div>
  );
}

export function ToPickupButton<T extends FoundItem>(props: Props<T>) {
  const { setNavigation } = useModalNavigation();

  const onClick = (e: any) => {
    e.preventDefault();
    setNavigation({
      title: 'Til Afhentning',
      // this has to be another component, in order for it to keep its state once it slides out.
      content: <EditContent {...props} />,
      onBack: () => setNavigation(null),
      onSubmit: () => {}
    });
  };

  return (
    <Button onClick={onClick} buttonType="white">
      <Text type="body-strong">Til Afhentning</Text>
      <HandshakeOutlinedIcon />
    </Button>
  );
}
