import {
  ArchivedFoundItemRecord,
  FoundItemRecord
} from '../../../../types/supabase/collections';
import { supabase } from '../../../supabase/supabaseClient';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export async function updateFoundItem<T extends boolean>(
  foundItemId: number,
  newData: Partial<T extends true ? ArchivedFoundItemRecord : FoundItemRecord>,
  isArchived?: T // Default parameter set correctly
): Promise<void> {
  // Perform the query based on whether it's archived or not
  const { error } = isArchived
    ? await supabase
        .from('archived_found_items')
        .update(newData)
        .eq('id', foundItemId)
    : await supabase.from('found_items').update(newData).eq('id', foundItemId);

  // Error handling - assuming `assertNoSupabaseError` is a utility function that checks for errors
  assertNoSupabaseError(newData as any, error, 'updateFoundItem');
}
