import React, { FC, useState } from 'react';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styles from './locationSelectModal.module.css';
import { useAuth } from '../../../../../contexts/AuthContext/AuthContext';
import { useLocationData } from '../../../../../contexts/LocationDataContext/LocationDataContext';
import Api from '../../../../../servieces/Api/Api';
import Page from '../../../../Page';
import { useNavigate } from 'react-router-dom';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
interface Option {
  label: string;
  isSelected: boolean;
  icon?: React.ReactNode; // Allow any React node, including MUI icons
  onClick: () => void;
}

export const LocationSelectButton: FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { organisation, location } = useLocationData();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [locationOptions, setLocationOptions] = useState<Option[]>([]);
  const open = Boolean(anchorEl);

  if (!location) return null;

  const fetchLocationOptions = () => {
    if (!user || !location) return;

    Api.get
      .allLocationsWhereUserIsAdminWithingOrganisation({
        userId: user.id,
        organisationId: organisation.id
      })
      .then((response) => {
        setLocationOptions(
          response.map((responseLocation) => ({
            label: responseLocation.name,
            isSelected: location.id === responseLocation.id,
            icon: <FmdGoodOutlinedIcon style={{ width: 18, height: 18 }} />,
            onClick: () => {
              navigate(Page.l.foundItems(responseLocation.id));
            }
          }))
        );
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    fetchLocationOptions();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!location) return null;

  const handleMenuItemClick = (e: any, onClick: () => void) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
    handleClose();
  };

  if (locationOptions.length === 1) return null; //don't show if there is only one location

  return (
    <div>
      <div onClick={handleClick} className={styles.button}>
        <FmdGoodOutlinedIcon style={{ width: 18, height: 18 }} />
        <span>{location.name}</span> <ArrowDropDownIcon />
      </div>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {locationOptions.map((option, index) => (
          <MenuItem
            key={index}
            onClick={(e) => handleMenuItemClick(e, option.onClick)}
            className={`${styles.menuItem} ${option.isSelected ? styles.selectedItem : ''}`}
          >
            {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
