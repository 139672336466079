import { supabase } from '../../../supabase/supabaseClient';
import {
  ArchivedInquiry,
  ArchivedInquiryRecord,
  Inquiry,
  InquiryRecord
} from '../../../../types/supabase/collections';
import type { PostgrestFilterBuilder } from '@supabase/postgrest-js';
import { Database } from '../../../../types/supabase/supabaseDB';
import logger from '../../../../sharedPacakge/logger';

type CustomQuery<Archived extends boolean> = PostgrestFilterBuilder<
  Database['public'],
  Archived extends true ? ArchivedInquiryRecord : InquiryRecord,
  any
>;

export const inquiryFields = `
      id,
      created_at,
      description,
      expiry_date,
      image,
      is_high_value_item,
      location_id,
      lost_at_date,
      organisation_id,
      reporter_email,
      reporter_name,
      reporter_phone,
      status,
      updated_at,
      lost_at_location,
      categories (
        id,
        name,
        name_danish
      )
    `;
export async function getInquiries<Archived extends boolean = false>(args: {
  location_id: number;
  archived?: Archived;
  customQuery?: (query: CustomQuery<Archived>) => CustomQuery<Archived>;
}): Promise<(Archived extends true ? ArchivedInquiry[] : Inquiry[]) | null> {
  const { location_id, archived, customQuery } = args;

  let query = archived
    ? supabase
        .from('archived_inquiries')
        .select(`${inquiryFields}, archive_reason, archived_date`)
        .eq('location_id', location_id)
    : supabase
        .from('inquiries')
        .select(inquiryFields)
        .eq('location_id', location_id);

  if (customQuery) {
    query = customQuery(query); // Apply the query builder function
  }

  // Apply order by clause
  query = query.order('lost_at_date', { ascending: false });

  const { data, error } = await query;

  if (error) {
    logger.supabaseError('getInquiries error', error);
    throw error;
  }
  return data.map((item: any) => ({
    ...item,
    category: item.categories
  })) as Archived extends true ? ArchivedInquiry[] : Inquiry[];
}
