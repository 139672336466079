import { useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext/AuthContext';

declare global {
  interface Window {
    hj?: (command: string, ...args: any[]) => void;
  }
}

export const useHotjarIdentity = () => {
  const { user } = useAuth();

  useEffect(() => {
    const interval = setInterval(() => {
      if (user && window.hj) {
        window.hj('identify', user.email, {
          email: user.email
        });
        clearInterval(interval); // stop polling once we identify the user
      }
    }, 100); // Check every 100ms until window.hj is available

    return () => clearInterval(interval); // Cleanup when component unmounts
  }, [user]);
};
