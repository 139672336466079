import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import React, { FC } from 'react';
import {
  FoundItem,
  MatchRecord
} from '../../../../../../types/supabase/collections';
import { useLocationData } from '../../../../../../contexts/LocationDataContext/LocationDataContext';
import Api from '../../../../../../servieces/Api/Api';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { ItemStatus } from '../../../../../../sharedPacakge/types/ItemStatus';
import { useSnackbar } from '../../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import FoundItemsTable from '../../../../components/FoundItemsTable/FoundItemTable';
import { Button } from '../../../../../../sharedPacakge/components/Button/Button';
import { ViewItemModalCallbacks } from '../../../../components/ViewItemModal/ViewItemModalCallbacks';
import styles from '../foundItemsTable.module.css';
import { useIsMobile } from '../../../../../../utils/hooks/useIsMobile';

export const filterItemsAwaitingPickup = (
  foundItems: FoundItem[],
  matchesData: MatchRecord[]
) => foundItems.filter((item) => item.status === ItemStatus.ReadyForPickup);

export const AwaitingPickupTable: FC<{
  itemsAwaitingPickup: FoundItem[];
  callbacks: ViewItemModalCallbacks<FoundItem>;
}> = ({ itemsAwaitingPickup, callbacks }) => {
  const isMobile = useIsMobile();
  const { location } = useLocationData();
  const { showSnackbar } = useSnackbar();

  if (!location) return null;

  const onPickedUpClick = async (e: any, foundItem: FoundItem) => {
    e.preventDefault();

    await Api.put.archiveItems({
      ids: [foundItem.id],
      type: 'foundItems',
      archiveReason: 'Picked up by owner'
    });
    callbacks.onArchive && callbacks.onArchive(foundItem.id);

    showSnackbar('genstand er noteret som afhentet');
  };

  return (
    <FoundItemsTable
      foundItems={itemsAwaitingPickup}
      callbacks={callbacks}
      options={{
        columns: ['image', 'category', 'description'],
        renderNoFoundItems: () => (
          <span className={styles.noFoundItems__text}>
            <InventoryRoundedIcon />
            <p>Du er helt ajour.</p>
          </span>
        ),
        renderActionsColumn: (row) => (
          <div className={styles.tableActions__container}>
            <Button
              onClick={(e) => onPickedUpClick(e, row)}
              size={isMobile ? 'small' : 'normal'}
              style={isMobile ? { gap: 6 } : {}}
            >
              <DoneAllIcon />
              <div>Afhentet</div>
            </Button>
          </div>
        )
      }}
    />
  );
};
