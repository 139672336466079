import React from 'react';
import styles from './customizationTab.module.css';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import { TabLayout } from '../../components/TabLayout/TabLayout';
import { LocationsOverview } from './components/LocationsOverview/LocationsOverview';
import { ExpiryDateOptions } from './components/ExpiryDateOptions/ExpiryDateOptions';
import { StorageAndFoundLocationOptions } from './components/StorageAndFoundLocationOptions/StorageAndFoundLocationOptions';

export const CustomizationTab = () => {
  const { organisation, location } = useLocationData();

  return (
    <TabLayout>
      <div className={styles.pageContainer}>
        <div className={styles.container}>
          <h3 className={styles.locations__title}>
            Lokationer i {organisation?.name}
          </h3>
          <LocationsOverview />
        </div>

        <div className={styles.container}>
          <h3 className={styles.locations__title}>
            Udløbs indstillinger for {location?.name}
          </h3>
          <ExpiryDateOptions />
        </div>

        <div className={styles.container}>
          <h3 className={styles.locations__title}>
            Konfigurer felter for "opret ny genstand" hos {location?.name}
          </h3>
          <StorageAndFoundLocationOptions />
        </div>
      </div>
    </TabLayout>
  );
};
