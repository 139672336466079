import {
  ArchivedFoundItem,
  ArchivedInquiry,
  FoundItem,
  Inquiry
} from '../../../../types/supabase/collections';
import React, { FC } from 'react';
import { ItemStatus } from '../../../../sharedPacakge/types/ItemStatus';

export const StatusCircle: FC<{
  item: FoundItem | ArchivedFoundItem | Inquiry | ArchivedInquiry;
}> = ({ item }) => {
  let fillColor;
  const isArchivedItem = 'archive_reason' in item;
  if (isArchivedItem) fillColor = 'var(--color-gray-600)';
  else {
    switch (item.status) {
      case ItemStatus.AwaitingMatch:
        fillColor = 'var(--color-primary)';
        break;
      case ItemStatus.ReadyForPickup:
        fillColor = 'var(--color-positive)';
        break;
      case ItemStatus.LeftBehind:
        fillColor = 'var(--color-warning)';
        break;
      case ItemStatus.Expired:
        fillColor = 'var(--color-warning)';
        break;
    }
  }

  return (
    <svg width="18" height="18" viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="10" fill={fillColor} />
    </svg>
  );
};
