import {
  FoundItemArchiveReason,
  InquiryArchiveReason
} from '../../sharedPacakge/types';

export const translateArchiveReason = (
  archiveReason: FoundItemArchiveReason | InquiryArchiveReason
) => {
  switch (archiveReason) {
    case 'Picked up by owner':
      return 'Afhentet af ejer';
    case 'Donated':
      return 'Doneret';
    case 'Kept':
      return 'Beholdt';
    case 'Handed to police':
      return 'Overgivet til politiet';
    case '(manually archived) Physical inquiry & immediate pickup':
      return '(manuelt arkiveret) Fysisk forespørgsel og øjeblikkelig afhentning';
    case '(manually archived) Donated':
      return '(manuelt arkiveret) Doneret';
    case '(manually archived) Kept':
      return '(manuelt arkiveret) Beholdt';
    case '(manually archived) Handed to police':
      return '(manuelt arkiveret) Overgivet til politiet';
    case 'never matched':
      return 'Aldrig matchet';
    default:
      return 'Ukendt årsag'; // Fallback for unknown reasons
  }
};
