import {
  FoundItem,
  FoundItemRecord,
  ItemCategory
} from '../../../../types/supabase/collections';
import { supabase } from '../../../supabase/supabaseClient';

export function subscribeToNewFoundItems(
  locationId: number,
  callback: (item: FoundItemRecord) => void
) {
  const channel = supabase
    .channel('new_found_items')
    .on(
      'postgres_changes',
      {
        event: 'INSERT',
        schema: 'public',
        table: 'found_items',
        filter: `location_id=eq.${locationId}`
      },
      (payload) => {
        console.log('payload', payload);
        const newItem = payload.new as FoundItemRecord;
        callback(newItem);
      }
    )
    .subscribe();

  // Return an unsubscribe function to clean up the listener when no longer needed
  return () => {
    supabase.removeChannel(channel);
  };
}

const formatFoundItem = (
  item: FoundItemRecord,
  categories: ItemCategory[]
): FoundItem => {
  const { category_id, ...rest } = item;
  return {
    ...rest,
    category: categories.find((category) => category.id === category_id)!
  };
};

export function subscribeToNewFoundItemsBuffered(
  locationId: number,
  categories: ItemCategory[],
  callback: (items: FoundItem[]) => void,
  bufferTime = 300 // Optional buffer time in milliseconds
) {
  const buffer: FoundItem[] = [];
  let timeoutId: NodeJS.Timeout | null = null;

  // Use the original subscribeToNewFoundItems function to receive individual items
  const unsubscribe = subscribeToNewFoundItems(locationId, (item) => {
    // Add each new item to the buffer
    console.log('item', item);
    buffer.push(formatFoundItem(item, categories));

    // Set a timeout to flush the buffer if not already set
    if (!timeoutId) {
      timeoutId = setTimeout(() => {
        callback([...buffer]); // Pass a copy of the buffer to the callback
        buffer.length = 0; // Clear the buffer
        timeoutId = null; // Reset the timeout ID
      }, bufferTime);
    }
  });

  // Return a function to unsubscribe and clear the timeout if needed
  return () => {
    unsubscribe(); // Unsubscribe from real-time updates
    if (timeoutId) clearTimeout(timeoutId); // Clear any remaining timeout
  };
}
