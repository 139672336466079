import React, { useCallback, useEffect } from 'react';
import {
  ArchivedFoundItem,
  ArchivedInquiry
} from '../../../../../../types/supabase/collections';
import { useModalNavigation } from '../../../../../../sharedPacakge/components/Modal/Modal';
import { Button } from '../../../../../../sharedPacakge/components/Button/Button';
import { Text } from '../../../../../../components/Text/Text';
import Api from '../../../../../../servieces/Api/Api';
import { getDateDaysFromNow } from '../../../../../../utils/dateInXDays';
import { useSnackbar } from '../../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { ViewItemModalCallbacks } from '../../ViewItemModalCallbacks';
import RefreshIcon from '@mui/icons-material/Refresh';

interface Props<T> {
  item: T;
  onClose: () => void;
  callbacks: ViewItemModalCallbacks<T>;
}

function EditContent<T extends ArchivedFoundItem | ArchivedInquiry>({
  item,
  callbacks,
  onClose
}: Props<T>) {
  const { navigation, setNavigation } = useModalNavigation();
  const { showSnackbar } = useSnackbar();
  const isFoundItem = 'found_at_date' in item;
  const isArchivedItem = 'archive_reason' in item;

  const handleSubmit = useCallback(() => {
    if (!isArchivedItem) return;

    Api.put
      .unArchiveItems({
        ids: [item.id],
        type: isFoundItem ? 'foundItems' : 'inquiries',
        newExpiryDate: getDateDaysFromNow(10)
      })
      .then(() => {
        callbacks?.onUnArchive && callbacks.onUnArchive(item.id);
        showSnackbar('genåbnet');
        onClose();
        setNavigation(null);
      })
      .catch(() => showSnackbar('Der skete en fejl', 'error'));
  }, [item, setNavigation]);

  useEffect(() => {
    if (navigation) {
      // Only update navigation if needed
      setNavigation((prev) => {
        if (prev && prev.onSubmit !== handleSubmit) {
          return {
            ...prev,
            onSubmit: handleSubmit
          };
        }
        return prev; // Ensure we return prev in both branches
      });
    }
  }, [navigation, handleSubmit]); // handleSubmit is still a dependency, but we mitigate the issue

  return (
    <Text type={'body-strong'}>
      {isFoundItem ? 'Genstanden' : 'Efterlysningen'} vil blive genaktiveret i
      10 dage.
    </Text>
  );
}

export function UnArchiveItemButton<
  T extends ArchivedFoundItem | ArchivedInquiry
>(props: Props<T>) {
  const { setNavigation } = useModalNavigation();

  const onClick = (e: any) => {
    e.preventDefault();
    setNavigation({
      title: 'Genaktiver',
      // this has to be another component, in order for it to keep its state once it slides out.
      content: <EditContent {...props} />,
      onBack: () => setNavigation(null),
      onSubmit: () => {}
    });
  };

  return (
    <Button onClick={onClick} buttonType="white">
      <Text type="body-strong">Genaktivér</Text>
      <RefreshIcon />
    </Button>
  );
}
