import { CircularProgress } from '@mui/material';
import React, { FC } from 'react';
import styles from './loadingSpinner.module.css';

export const LoadingSpinner: FC<{ centered?: boolean }> = ({ centered }) =>
  centered ? (
    <div
      style={{
        height: '100dvh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <LoadingSpinner />
    </div>
  ) : (
    <CircularProgress className={styles.loadingSpinner} />
  );
