import React, { useEffect, useState } from 'react';

import styles from './viewFoundItemModal.module.css';
import { formatDate2 } from '../../../../../utils/formatting/formatDate';
import { useLocationData } from '../../../../../contexts/LocationDataContext/LocationDataContext';
import Api from '../../../../../servieces/Api/Api';
import {
  ArchivedFoundItem,
  FoundItem,
  Inquiry,
  userProfileRecord
} from '../../../../../types/supabase/collections';
import { daysUntilDateText } from '../../../../../utils/daysUntilDate';
import { Modal } from '../../../../../sharedPacakge/components/Modal/Modal';
import { TranslateViewItemModalStatus } from '../../../../../utils/translate/translateItemStatus';
import { ItemStatus } from '../../../../../sharedPacakge/types/ItemStatus';
import { LoadingSpinner } from '../../../../../sharedPacakge/components/LoadingSpinner/LoadingSpinner';
import { Text } from '../../../../../components/Text/Text';
import { ResponsiveGridContainer } from '../../../../../components/ResponsiveGridContainer/ResponsiveGridContainer';
import { EditableFoundItemTextField } from '../Fields/EditableFoundItemTextField/EditableFoundItemTextField';
import { TextField } from '../../../../../components/TextField/TextField';
import { StatusCircle } from '../../StatusCircle/StatusCircle';
import { ArchiveItemButton } from '../Fields/ArchiveItemButton/ArchiveItemButton';
import { ToPickupButton } from '../Fields/ToPickupButton/ToPickupButton';
import { UnArchiveItemButton } from '../Fields/UnArchiveItemButton/UnArchiveItemButton';
import { ViewItemModalCallbacks } from '../ViewItemModalCallbacks';
import useFilters from '../../../hooks/useFilters';
import { MatchSection } from '../Sections/MatchSection/MatchSection';
import { ManuelMatchButton } from '../Fields/ManuelMatchButton/ManuelMatchButton';
import { EditableCategory } from '../Fields/EditableCategory/EditableCategory';
import GalleryImage from '../GalleryImage/GalleryImage';

interface Props<T> {
  foundItem: T;
  callbacks: ViewItemModalCallbacks<T>;
}

function ViewFoundItem<T extends FoundItem | ArchivedFoundItem>({
  foundItem,
  callbacks
}: Props<T>) {
  const isArchivedItem = 'archive_reason' in foundItem;
  const { location } = useLocationData();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [match, setMatch] = useState<Inquiry | null>(null);
  const [postedByUser, setPostedByUser] = useState<userProfileRecord | null>(
    null
  );
  const [_, udpateFilter] = useFilters();
  const closeModal = () => udpateFilter({ viewItem: null });

  useEffect(() => {
    if (foundItem.posted_by_user_id) {
      Api.get
        .userProfile(foundItem.posted_by_user_id)
        .then(
          (userProfileResponse) =>
            userProfileResponse && setPostedByUser(userProfileResponse)
        );
    }

    if (
      foundItem.status === ItemStatus.ReadyForPickup ||
      foundItem.status === ItemStatus.LeftBehind
    ) {
      Api.get
        .matches(
          {
            customQuery: (query) =>
              query
                .eq('found_item_id', foundItem.id)
                .eq('match_confirmed', true)
          },
          isArchivedItem
        )
        .then((matches) => {
          if (matches.length < 1) {
            // dont log error, because when manually being sat to pickup it will not have a match
            return;
          }
          Api.get
            .inquiry(matches[0].inquiry_id, isArchivedItem)
            .then((inquiry) => {
              if (!inquiry) return;

              setMatch(inquiry);
              setIsLoading(false);
            });
        });
    }
  }, []);

  if (!location) return null;

  return (
    <Modal
      className={styles.modal}
      title={`${isArchivedItem ? 'Arkiveret' : 'Fundet'} genstand`}
      onClose={closeModal}
    >
      <div className={styles.container}>
        <div className={styles.sectionContainer}>
          <GalleryImage image={foundItem?.image} />
        </div>
        <TextField
          label="Status"
          value={TranslateViewItemModalStatus(foundItem)}
          adornment={{
            beforeValue: <StatusCircle item={foundItem} />
          }}
          disabled
        />

        <div className={styles.sectionContainer}>
          <Text type="body-strong">Genstand</Text>
          <ResponsiveGridContainer>
            <EditableCategory
              foundItem={foundItem}
              matchingItem={match}
              callbacks={callbacks}
            />
            <EditableFoundItemTextField
              foundItem={foundItem}
              callbacks={callbacks}
              field="description"
            />
          </ResponsiveGridContainer>
        </div>

        <div className={styles.sectionContainer}>
          <Text type="body-strong">Håndtering</Text>
          <ResponsiveGridContainer>
            <TextField
              label="Fundet Omkring"
              value={formatDate2(foundItem.found_at_date)}
              disabled
            />
            <TextField
              label="Oprettet af"
              value={
                postedByUser
                  ? postedByUser.full_name
                  : 'bruger eksisterer ikke længere'
              }
              disabled
            />
            {!isArchivedItem && (
              <TextField
                label="Udløber om"
                value={daysUntilDateText(foundItem.expiry_date, 'long')}
                disabled
              />
            )}
            <EditableFoundItemTextField
              foundItem={foundItem}
              callbacks={callbacks}
              field="notes"
            />
            <EditableFoundItemTextField
              foundItem={foundItem}
              callbacks={callbacks}
              field="storage_location"
            />
            <EditableFoundItemTextField
              foundItem={foundItem}
              callbacks={callbacks}
              field="found_at_location"
            />
          </ResponsiveGridContainer>
        </div>

        <MatchSection
          item={foundItem}
          matchingItem={match}
          callbacks={callbacks}
          closeModal={closeModal}
          isArchivedItem={isArchivedItem}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />

        <div className={styles.sectionContainer}>
          <Text type="body-strong">Manuel Håndtering</Text>
          <ResponsiveGridContainer>
            <TextField label="ID" value={foundItem.id} disabled />
            {!isArchivedItem &&
              foundItem.status === ItemStatus.AwaitingMatch && (
                <ToPickupButton foundItem={foundItem} callbacks={callbacks} />
              )}

            {!isArchivedItem && (
              <ArchiveItemButton
                item={foundItem}
                callbacks={callbacks}
                onClose={closeModal}
              />
            )}
            {isArchivedItem && (
              <UnArchiveItemButton
                item={foundItem}
                callbacks={
                  callbacks as ViewItemModalCallbacks<ArchivedFoundItem>
                }
                onClose={closeModal}
              />
            )}
            <ManuelMatchButton item={foundItem} matchingItem={match} />
          </ResponsiveGridContainer>
        </div>

        {isLoading && <LoadingSpinner />}
      </div>
    </Modal>
  );
}

export default ViewFoundItem;
