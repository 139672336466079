import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './inquiriesTable.module.css';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import Api from '../../../../servieces/Api/Api';
import { TabLayout } from '../../components/TabLayout/TabLayout';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import {
  ArchivedInquiry,
  Inquiry
} from '../../../../types/supabase/collections';
import GenericTable, {
  Column
} from '../../../../components/GenericTable/GenericTable';
import { formatDate } from '../../../../utils/formatting/formatDate';
import useFilters from '../../hooks/useFilters';
import { LoadingSpinner } from '../../../../sharedPacakge/components/LoadingSpinner/LoadingSpinner';
import { translateItemStatus } from '../../../../utils/translate/translateItemStatus';
import { translateArchiveReason } from '../../../../utils/translate/translateArchiveReason';
import { FoundItemArchiveReason } from '../../../../sharedPacakge/types';
import { SearchBar } from '../../../../components/SearchBar/SearchBar';
import ViewInquiryModal from '../../components/ViewItemModal/ViewInquiryModal/ViewInquiryModal';
import Tabs from '../../../../components/Tabs/Tabs';
import { Text } from '../../../../components/Text/Text';

const getTabs = [
  {
    label: 'Alle',
    description: 'Alle aktive efterlysninger fra gæster',
    searchParamValue: null
  },
  {
    label: 'Arkiveret',
    description: 'Alle arkiverede efterlysninger fra gæster',
    searchParamValue: 'archived'
  }
];

const InquiriesTable: FC = () => {
  const { location } = useLocationData();
  const [filters, udpateFilter] = useFilters();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [inquiries, setInquiries] = useState<Inquiry[] | ArchivedInquiry[]>([]);
  const [viewingItem, setViewingItem] = useState<
    Inquiry | ArchivedInquiry | null
  >(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    getTabs.findIndex((tab) => {
      if (filters.filterItems === tab.searchParamValue) return true;
      if (!filters.filterItems && tab.searchParamValue === null) return true;
    }) as any
  );
  const isArchivedItems = useMemo(
    () => filters.filterItems === 'archived',
    [filters]
  );
  useMemo(() => {
    if (filters.viewItem) {
      const item = inquiries.find((item) => item.id === filters.viewItem);
      item && setViewingItem(item);
    } else {
      setViewingItem(null);
    }
  }, [inquiries, filters]);

  // useEffect(() => {
  //   // When we update the found item locally, we need to update the value of the viewFoundItem
  //   setViewingItem((prevViewingItem) =>
  //     prevViewingItem
  //       ? (inquiries.find((item) => item.id === prevViewingItem.id) ?? null)
  //       : null
  //   );
  // }, [inquiries]);
  const removeInquiryLocally = (id: number) => {
    setInquiries((prevState) => prevState.filter((item) => item.id !== id));
  };

  useEffect(() => {
    if (!location) return;

    Api.get
      .inquiries({
        location_id: location.id,
        archived: isArchivedItems
      })
      .then(
        (foundItemsResponse) =>
          foundItemsResponse && setInquiries(foundItemsResponse)
      );
    setIsLoading(false);
  }, [location, isArchivedItems]);

  if (isLoading)
    return (
      <TabLayout>
        <div className={styles.loadingSpinner__container}>
          <LoadingSpinner />
        </div>
      </TabLayout>
    );

  const columns: Column<Inquiry | ArchivedInquiry>[] = [
    {
      id: 'image',
      label: 'Billede',
      render: ({ image }) =>
        image ? (
          <img
            className={styles.itemImage}
            src={image}
            alt="genstand billede"
            width={90}
            height={90}
          />
        ) : (
          '(intet billede)'
        ),
      renderMobile: true
    },
    {
      id: 'category',
      label: 'Kategori',
      render: ({ category }) => category.name_danish,
      renderMobile: true
    },
    {
      id: 'description',
      label: 'Beskrivelse',
      render: ({ description }) => (
        <div className={styles.description}>{description}</div>
      ),
      renderMobile: ({ description }) => (
        <div
          className={styles.description}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block'
          }}
        >
          {description}
        </div>
      )
    },
    {
      id: 'lostAtDateTime',
      label: 'Tabt tidspunkt',
      render: ({ lost_at_date }) => formatDate(lost_at_date)
    },
    {
      id: 'name',
      label: 'Navn',
      render: ({ reporter_name }) => reporter_name,
      renderMobile: ({ reporter_name }) => <small>{reporter_name}</small>
    },

    {
      id: 'id',
      label: 'ID',
      render: ({ id }) => id
    }
  ];

  const isArchivedItem = (item: Inquiry | ArchivedInquiry) =>
    'archive_reason' in item;

  if (inquiries.length > 0 && !isArchivedItem(inquiries[0])) {
    columns.push({
      id: 'status',
      label: 'Status',
      render: ({ status }) => translateItemStatus(status)
    });
  }
  if (inquiries.length > 0 && isArchivedItem(inquiries[0])) {
    columns.push({
      id: 'archive_reason',
      label: 'Arkiveringsårsag',
      render: (item) =>
        'archive_reason' in item
          ? translateArchiveReason(
              item.archive_reason as FoundItemArchiveReason
            )
          : ''
    });
  }
  const onRowClick = (e: any, item: Inquiry) => {
    e.preventDefault();
    udpateFilter({ viewItem: item.id });
  };

  const queryFilter = (item: Inquiry) =>
    filters.query
      ? item.category.name_danish
          .toLowerCase()
          .includes(filters.query.toLowerCase()) ||
        item.description.toLowerCase().includes(filters.query.toLowerCase())
      : true;

  const filteredInquiries = filters.query
    ? inquiries.filter((item) => queryFilter(item))
    : inquiries;

  const uniqueQueryFilterOptions = Array.from(
    new Set([
      ...filteredInquiries.map((item) => item.description),
      ...filteredInquiries.map((item) => item.category.name_danish)
    ])
  );

  const handleTabClick = (index: number) => {
    setSelectedTabIndex(index);
    udpateFilter({
      filterItems: (getTabs.at(index) as any).searchParamValue
    });
  };

  const callbacks = {
    onArchive: removeInquiryLocally,
    onUnArchive: removeInquiryLocally,
    onUpdateItem: () => {} // this is just for ts not to be mad
  };

  return (
    <TabLayout>
      <Tabs
        tabs={getTabs}
        onTabClick={handleTabClick}
        selectedTabIndex={selectedTabIndex}
      />
      <div className={styles.tabDescription__container}>
        <Text type="caption">{getTabs[selectedTabIndex].description}</Text>
      </div>
      <div className={styles.searchBar__container}>
        <SearchBar
          placeholder="Kategori eller beskrivelse"
          options={uniqueQueryFilterOptions}
        />
      </div>
      {isLoading ? (
        <LoadingSpinner centered />
      ) : inquiries.length === 0 ? (
        <>
          <h2 className={styles.noItems__text}>
            {isArchivedItems
              ? 'Ingen afviklet efterlysninger endnu'
              : 'Ingen efterlysninger endnu'}
          </h2>
          <p
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '5px'
            }}
          >
            Lær hvordan du kan modtage efterlysninger, ved at klikke på
            <HelpOutlineOutlinedIcon />
          </p>
        </>
      ) : (
        <>
          <GenericTable<Inquiry | ArchivedInquiry>
            columns={columns}
            data={filteredInquiries}
            rowKey="id"
            onRowClick={onRowClick}
          />
          {viewingItem && (
            <ViewInquiryModal callbacks={callbacks} inquiry={viewingItem} />
          )}
        </>
      )}
    </TabLayout>
  );
};

export default InquiriesTable;
