import React, { FC, RefObject } from 'react';
import styles from './textField.module.css';

type CustomProps = {
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  id?: string;
  label?: string; // New prop for label
  adornment?: {
    beforeValue?: React.ReactNode; // Decoration to be rendered at the start
    end?: React.ReactNode; // Decoration to be rendered at the end
  };
  classes?: {
    input?: string;
  };
  inputRef?: RefObject<HTMLInputElement>;
  type?: 'text' | 'number';
};

export const TextField: FC<CustomProps> = ({
  value = '',
  onChange,
  onKeyDown,
  placeholder,
  type = 'text',
  disabled = false,
  id,
  label,
  adornment,
  classes,
  inputRef
}) => {
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (disabled) {
      e.target.blur(); // Remove focus if the field is disabled
    }
  };

  return (
    <div className={`${styles.textFieldContainer}`}>
      {adornment?.beforeValue && (
        <div className={styles.beforeValueAdornment}>
          {adornment.beforeValue}
        </div>
      )}
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
      )}
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        id={id}
        multiple={true}
        onKeyDown={onKeyDown}
        className={`
        ${styles.textField} 
        ${disabled ? styles.textField__disabled : ''} 
        ${adornment?.beforeValue ? styles.inputWithStart : ''} 
        ${adornment?.end ? styles.inputWithEnd : ''}
        ${!label ? styles.textField__noLabel : ''}
        ${classes?.input || ''}
        `}
        onFocus={handleFocus}
        ref={inputRef}
      />
      {adornment?.end && (
        <div className={styles.endAdornment}>{adornment.end}</div>
      )}
    </div>
  );
};
