import React, { FC } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectProps
} from '@mui/material';
import { Role } from '../../../../../../types/supabase/collections';
import { translateRole } from '../../../../../../utils/translate/translateRole';

interface CustomProps {
  value: Role;
  onChange: (e: any, newValue: Role) => void;
  disabled?: boolean;
  options: Role[];
  labelClassName?: string;
}

type Props = CustomProps &
  Omit<
    SelectProps,
    | 'renderInput'
    | 'options'
    | 'getOptionLabel'
    | 'value'
    | 'onChange'
    | 'disabled'
  >;

export const RoleDropdown: FC<Props> = ({
  value,
  options,
  onChange,
  disabled = false,
  labelClassName = '',
  ...selectProps
}) => {
  return (
    <FormControl fullWidth disabled={disabled}>
      <InputLabel className={labelClassName}>Rolle</InputLabel>
      <Select
        renderValue={(role) => translateRole(role as Role)}
        value={value}
        onChange={(e) => {
          onChange(e, e.target.value as Role);
        }}
        label="Rolle"
        disabled={disabled}
        {...selectProps}
      >
        {options.map((role) => (
          <MenuItem key={role} value={role}>
            {translateRole(role)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
