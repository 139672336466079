import { Database, Tables } from '../../types/supabase/supabaseDB';
import { supabase } from './supabaseClient';
import logger from '../../sharedPacakge/logger';
import { assertNoSupabaseError } from './supabaseErrorHandling';
import type { PostgrestFilterBuilder } from '@supabase/postgrest-js';

type TableRecords<T> = T extends { Tables: { [key: string]: { Row: infer R } } }
  ? { [K in keyof T['Tables']]: T['Tables'][K]['Row'] }
  : never;

type PublicTableRecords = TableRecords<Database['public']>;

type TableNameToRecordMap = PublicTableRecords;

export async function getRecords<T extends keyof TableNameToRecordMap>(
  tableName: T,
  ids: number[]
): Promise<Tables<T>[] | null> {
  const { data, error } = await supabase
    .from(tableName)
    .select('*')
    .in('id', ids);

  assertNoSupabaseError(data, error, 'getRecords error', { tableName, ids });

  return data as any[];
}

type CustomQuery<T extends keyof TableNameToRecordMap> = PostgrestFilterBuilder<
  Database['public'],
  TableNameToRecordMap[T],
  any
>;

export async function getRecordsCustomQuery<
  T extends keyof TableNameToRecordMap
>(
  tableName: T,
  customQuery: (query: CustomQuery<T>) => CustomQuery<T>
): Promise<TableNameToRecordMap[T][] | null> {
  const query = supabase.from(tableName).select('*');

  const { data, error } = await customQuery(query as CustomQuery<T>);

  assertNoSupabaseError(data, error, 'getRecordsCustomQuery error', {
    tableName,
    customQuery
  });

  return data as TableNameToRecordMap[T][] | null;
}
export async function deleteRecords<T extends keyof TableNameToRecordMap>(
  tableName: T,
  ids: number[]
): Promise<void> {
  const { error } = await supabase.from(tableName).delete().in('id', ids);

  if (error) {
    logger.supabaseError('deleteRecords error', error);
    throw error;
  }
}

type InsertType<T extends keyof TableNameToRecordMap> =
  Database['public']['Tables'][T]['Insert'];

export async function createRecord<T extends keyof TableNameToRecordMap>(
  tableName: T,
  record: InsertType<T>
): Promise<TableNameToRecordMap[T] | null> {
  // Ensure the record is wrapped in an array for insertion
  const { data, error } = await supabase
    .from(tableName as keyof PublicTableRecords) // casting tableName as string
    .insert([record]) // insert expects an array of records
    .single(); // .single() expects exactly one record to be returned

  if (error) {
    logger.supabaseError('createRecord error', error);
    throw error;
  }

  return data as TableNameToRecordMap[T];
}

type UpdateType<T extends keyof TableNameToRecordMap> =
  Database['public']['Tables'][T]['Update'];

export async function updateRecord<T extends keyof TableNameToRecordMap>(
  tableName: T,
  id: number,
  updates: UpdateType<T>
): Promise<TableNameToRecordMap[T] | null> {
  // Perform the update operation
  const { data, error } = await supabase
    .from(tableName)
    .update(updates as any) // Add 'as any' to bypass TypeScript errors temporarily
    .eq('id', id)
    .single(); // Ensure a single record is returned

  assertNoSupabaseError(data, error, 'updateRecord error', {
    tableName,
    id,
    updates
  });

  return data as unknown as TableNameToRecordMap[T];
}

// type RecordType<T extends keyof TableNameToRecordMap> = TableNameToRecordMap[T];
//
// type InsertType<T extends keyof TableNameToRecordMap> =
//   Database['public']['Tables'][T]['Insert'];

// export async function getRecord<T extends keyof TableNameToRecordMap>(
//   tableName: T,
//   id: number
// ): Promise<RecordType<T> | null> {
//   const { data, error: getRecordError } = await supabase
//     .from(tableName)
//     .select('*')
//     .eq('id', id)
//     .single();
//
//   if (getRecordError) {
//     console.error('getRecord', getRecordError);
//     return null;
//   }
//
//   return data as unknown as RecordType<T>;
// }

// export async function deleteRecord<T extends keyof TableNameToRecordMap>(
//   tableName: T,
//   id: number
// ): Promise<void> {
//   const { error } = await supabase.from(tableName).delete().eq('id', id);
//
//   if (error) {
//     console.error('deleteRecord', error);
//   }
// }
