import { useState } from 'react';
import {
  createParser,
  ParsedEvent,
  ReconnectInterval
} from 'eventsource-parser';
import { ItemCategory } from '../../../../../types/supabase/collections';
import { isValidJSON } from '../../../../../utils/isValidJSON';
import FindyApi from '../../../../../sharedPacakge/findyApi/fetchFindyApi';
import { useLocationData } from '../../../../../contexts/LocationDataContext/LocationDataContext';

export const useGetFoundItemFromImage = () => {
  const { itemCategories } = useLocationData();
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<
    Array<{
      category?: ItemCategory;
      description?: string;
      isHighValueItem?: boolean;
    }>
  >([]);

  const reset = () => {
    setItems([]);
    setIsLoading(false);
  };

  const setItemsIfValid = (json: string) => {
    if (!isValidJSON(json)) return;
    // finishing an item field
    const parsedData = JSON.parse(json) as {
      items?: Array<{
        category?: string; // the category name
        description?: string;
        isHighValueItem?: boolean;
      }>;
    };

    if (!parsedData.items) return;

    const formatteItems = parsedData.items.map((imgDetectionItem) => ({
      category:
        itemCategories.find((c) => c.name === imgDetectionItem.category) ||
        itemCategories.find((c) => c.id === 100)!,
      description: imgDetectionItem.description || '',
      is_high_value_item: imgDetectionItem.isHighValueItem || false
    }));
    setItems(formatteItems);
  };

  const getFoundItemsFromImage = async (body: {
    isMultipleItems: boolean;
    uptimizedBase64Image: string;
  }) => {
    setItems([]);
    setIsLoading(true);
    let fullText = '';
    console.log('body', body);

    const response = await FindyApi.postAndGetRawResponse(
      'found-item-from-image',
      {
        ...body,
        categories: itemCategories
      }
    );

    const data = response.body;
    if (!data) {
      return;
    }

    const onParse = (event: ParsedEvent | ReconnectInterval) => {
      if (event.type === 'event') {
        const data = event.data;
        try {
          const text = JSON.parse(data).text ?? '';
          fullText += text;

          // todo potentially throttle the stuff below
          setItemsIfValid(fullText); // complete data
          setItemsIfValid(`${fullText}"}]}`); // finishing an item field
          setItemsIfValid(`${fullText}}]}`); // finishing an item that is potentially missing some fields
          setItemsIfValid(`${fullText}]}`); // a finishined an item or an empty array
          console.log('newText', text);
        } catch (e) {
          console.error(e);
        }
      }
    };

    const reader = data.getReader();
    const decoder = new TextDecoder();
    const parser = createParser(onParse);
    let done = false;
    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunkValue = decoder.decode(value);
      parser.feed(chunkValue);
    }

    // setItemsIfValid(fullText);
    setIsLoading(false);
    console.log('fullText:', fullText);
  };

  return {
    getFoundItemsFromImage,
    isLoadingImageDection: isLoading,
    imageDectionItems: items,
    reset
  };
};
