import { supabase } from '../../../supabase/supabaseClient';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export async function deleteUserRolesInOrg(
  userId: string,
  organisationId: number
): Promise<void> {
  const { error } = await supabase
    .from('user_roles')
    .delete()
    .eq('user_id', userId)
    .eq('organisation_id', organisationId);
  assertNoSupabaseError({}, error, 'deleteUserInvite error', {
    userId,
    organisationId
  });
}
