import React, { useEffect, useState } from 'react';
import useFilters from '../LocationPage/hooks/useFilters';
import { MultiChipInput } from '../../components/MultiChipInput/MultiChipInput';
import FindyApi from '../../sharedPacakge/findyApi/fetchFindyApi';
import styles from './internatInsightsPage.module.css';
import { MuiLocalizationAndThemeProvider } from '../../sharedPacakge/components/MuiLocalizationAndThenmeProvider/MuiLocalizationAndThemeProvider';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { getDateDaysBeforeNow } from '../../utils/dateInXDays';
import { DateGraph } from './components/DateGraph/DateGraph';
import {
  ArchivedFoundItemRecord,
  ArchivedInquiryRecord,
  ArchivedMatchRecord,
  FoundItemRecord,
  InquiryRecord,
  MatchRecord,
  Organisation,
  userProfileRecord,
  UserRoleRecord
} from '../../types/supabase/collections';
import GenericTable from '../../components/GenericTable/GenericTable';
import { daysBetweenDates } from '../../utils/daysBetweenDays';
import { UserUsageGraph } from './components/DateGraph/UserUsageGraph';
import { Button } from '../../sharedPacakge/components/Button/Button';
import { ArchiveReasonBarChart } from './components/ArchiveReasonBarChart';

type UserRoleWithProfile = userProfileRecord & UserRoleRecord;

export interface FindyInternalInsightsResponse {
  organisations: Array<
    Organisation & {
      inquiries: Array<InquiryRecord | ArchivedInquiryRecord>;
      foundItems: Array<FoundItemRecord | ArchivedFoundItemRecord>;
      matches: Array<MatchRecord | ArchivedMatchRecord>;
      users: Array<{
        usersRole: UserRoleRecord;
        userProfile: userProfileRecord;
      }>;
    }
  >;
}

export const InternalInsightsPage = () => {
  const [filters, updateFilters] = useFilters();
  const [data, setData] = useState<FindyInternalInsightsResponse | null>(null);
  const [startDate, setStartDate] = useState<Date>(getDateDaysBeforeNow(60));
  const [showActiveSessionsForUserIds, setShowActiveSessionsForUserIds] =
    useState<string[]>([]);

  useEffect(() => {
    const organisationIds = filters.ids;
    FindyApi.post('internal-insights-page-data', { organisationIds }).then(
      (response: FindyInternalInsightsResponse) => {
        setData(response);
      }
    );
  }, [filters.ids]);

  const onIdsChange = (newValue: string[]) => {
    updateFilters({
      ids: newValue.map((id) => parseInt(id))
    });
  };

  // @ts-ignore
  return (
    <div className={styles.page__container}>
      <div className={styles.filters__container}>
        <div className={styles.organisationFilter__container}>
          <MultiChipInput
            label="organisation ids"
            value={filters.ids ? filters.ids.map((id) => id.toString()) : []}
            onChange={onIdsChange}
          />
        </div>
        <div className={styles.startDateFilter__container}>
          <div>Start Date</div>
          <MuiLocalizationAndThemeProvider>
            <DatePicker
              onChange={(newValue) =>
                newValue && setStartDate(newValue.toDate())
              }
              value={dayjs(startDate)}
              maxDate={dayjs(new Date())}
            />
          </MuiLocalizationAndThemeProvider>
        </div>
      </div>

      {/* Render graphs for multiple types of records */}

      <div>
        <div style={{ display: 'flex' }}>
          <DateGraph
            data={data}
            startDate={startDate}
            recordType="foundItems"
          />
          <DateGraph data={data} startDate={startDate} recordType="inquiries" />
        </div>
        <div style={{ display: 'flex' }}>
          <DateGraph
            data={data}
            startDate={startDate}
            recordType="matches_created"
          />
          <DateGraph
            data={data}
            startDate={startDate}
            recordType="matches_confirmed"
          />
          <DateGraph
            data={data}
            startDate={startDate}
            recordType="matches_rejected"
          />
        </div>
        {data && (
          <div>
            <ArchiveReasonBarChart data={data} type="foundItems" />
            <ArchiveReasonBarChart data={data} type="inquiries" />
          </div>
        )}

        {data &&
          data.organisations.map((organisation) => (
            <div>
              <h3>
                {organisation.name} ({organisation.id})
              </h3>
              <GenericTable<UserRoleWithProfile>
                columns={[
                  {
                    id: 'full_name',
                    label: 'Name',
                    render: ({ full_name }) => full_name
                  },
                  {
                    id: 'email',
                    label: 'Email',
                    render: ({ email }) => email
                  },
                  {
                    id: 'role',
                    label: 'Role',
                    render: ({ role }) => role
                  },
                  {
                    id: 'last_active_at',
                    label: 'sidst aktiv',
                    render: ({ last_active_at }) => {
                      const daysAgo = daysBetweenDates(
                        new Date(last_active_at),
                        new Date()
                      );
                      return daysAgo === 0 ? 'idag' : `${daysAgo} days ago`;
                    }
                  },
                  {
                    id: 'all_sessions',
                    label: 'Sessioner',
                    render: (user) =>
                      showActiveSessionsForUserIds.some(
                        (userId) => userId === user.id
                      ) ? (
                        <UserUsageGraph
                          startDate={startDate}
                          dateStrings={user.all_sessions}
                        />
                      ) : (
                        <Button
                          style={{ height: 25, width: 40 }}
                          onClick={(e) => {
                            e.preventDefault();
                            setShowActiveSessionsForUserIds((prevState) => [
                              ...prevState,
                              user.id
                            ]);
                          }}
                        >
                          Se historik
                        </Button>
                      )
                  }
                ]}
                data={(
                  organisation.users.flatMap((testy) => ({
                    ...testy.userProfile,
                    ...testy.usersRole
                  })) as any
                ).sort(
                  (a: any, b: any) =>
                    new Date(b.last_active_at).getTime() -
                    new Date(a.last_active_at).getTime()
                )}
                rowKey="id"
              />
            </div>
          ))}
      </div>
    </div>
  );
};
