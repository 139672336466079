import { ItemStatus } from '../../../../../../sharedPacakge/types/ItemStatus';
import styles from './matchSections.module.css';
import { Text } from '../../../../../../components/Text/Text';
import { TextField } from '../../../../../../components/TextField/TextField';
import { Button } from '../../../../../../sharedPacakge/components/Button/Button';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { CancelPickupButton } from '../../Fields/CancelPickupButton/CancelPickupButton';
import {
  ArchivedFoundItem,
  ArchivedInquiry,
  FoundItem,
  Inquiry
} from '../../../../../../types/supabase/collections';
import { ViewItemModalCallbacks } from '../../ViewItemModalCallbacks';
import { unMatchItems } from '../../../../../../servieces/matchmaking/unMatchItems';
import { useLocationData } from '../../../../../../contexts/LocationDataContext/LocationDataContext';
import { SetState } from '../../../../../../sharedPacakge/types/utilityTypes';
import { useSnackbar } from '../../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { useIsMobile } from '../../../../../../utils/hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';
import Page from '../../../../../Page';

interface Props<
  T extends FoundItem | ArchivedFoundItem | Inquiry | ArchivedInquiry
> {
  item: FoundItem | ArchivedFoundItem | Inquiry | ArchivedInquiry;
  matchingItem:
    | FoundItem
    | ArchivedFoundItem
    | Inquiry
    | ArchivedInquiry
    | null;
  isArchivedItem: boolean;
  isLoading: boolean;
  setIsLoading: SetState<boolean>;
  callbacks: ViewItemModalCallbacks<T>;
  closeModal: () => void;
}
export function MatchSection<
  T extends FoundItem | ArchivedFoundItem | Inquiry | ArchivedInquiry
>({
  item,
  matchingItem,
  isLoading,
  setIsLoading,
  isArchivedItem,
  callbacks,
  closeModal
}: Props<T>) {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { location } = useLocationData();
  const isFoundItem = 'found_at_date' in item;

  const onUnMatchClick = (e: any) => {
    e.preventDefault();
    if (isArchivedItem || !matchingItem || !location) return;

    setIsLoading(true);
    unMatchItems({
      inquiryId: isFoundItem ? matchingItem.id : item.id,
      foundItemId: isFoundItem ? item.id : matchingItem.id,
      location
    })
      .then(() => {
        showSnackbar('Genstand er Unmatched!');
        callbacks.onUpdateItem(item.id, {
          status: ItemStatus.ReadyForPickup
        } as Partial<T>);
        closeModal();
      })
      .catch(() => showSnackbar('Der skete en fejl', 'error'))
      .finally(() => setIsLoading(false));
  };

  const onSeeMatchClick = (e: any) => {
    e.preventDefault();
    if (!matchingItem || !location) return;

    if (isFoundItem) {
      if (isArchivedItem)
        navigate(Page.l.viewArchivedInquiry(location.id, matchingItem.id));
      else navigate(Page.l.viewInquiry(location.id, matchingItem.id));
    } else {
      if (isArchivedItem)
        navigate(Page.l.viewArchivedFoundItem(location.id, matchingItem.id));
      else navigate(Page.l.viewFoundItem(location.id, matchingItem.id));
    }
  };

  return item.status === ItemStatus.ReadyForPickup ||
    item.status === ItemStatus.LeftBehind ? (
    <div className={styles.sectionContainer}>
      <Text type="body-strong">Match</Text>
      <div>
        {matchingItem ? (
          <>
            <TextField
              label="Match"
              value={matchingItem.description}
              disabled
            />
            <div className={styles.matchButtons__container}>
              <Button
                buttonType="white"
                fullWidth
                disabled={isLoading}
                onClick={onSeeMatchClick}
              >
                <RemoveRedEyeOutlinedIcon /> {!isMobile && 'Se match'}
              </Button>

              {!isArchivedItem && (
                <>
                  {/*<Button buttonType="white" fullWidth disabled={isLoading}>*/}
                  {/*  <CreateOutlinedIcon />*/}
                  {/*  {!isMobile && 'Rediger match'}*/}
                  {/*</Button>*/}
                  <Button
                    buttonType="white"
                    fullWidth
                    disabled={isLoading}
                    onClick={onUnMatchClick}
                  >
                    <CloseOutlinedIcon />
                    {!isMobile && 'Fjern match'}
                  </Button>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <Text type="body">
              Sat til afhentning manuel og har derfor ikke noget match
            </Text>
            {isFoundItem &&
              !isArchivedItem &&
              item.status === ItemStatus.ReadyForPickup && (
                <CancelPickupButton
                  foundItem={item}
                  callbacks={callbacks as ViewItemModalCallbacks<FoundItem>}
                />
              )}
          </>
        )}
      </div>
    </div>
  ) : null;
}
