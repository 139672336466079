import {
  FoundItemRecord,
  InquiryRecord
} from '../../types/supabase/collections';
import FindyApi from '../../sharedPacakge/findyApi/fetchFindyApi';

export const runMatchmaking = async (
  item: FoundItemRecord | InquiryRecord
): Promise<{ matchesCount: number }> =>
  await FindyApi.post('matchmaking', {
    item
  });
