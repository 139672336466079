import React, { FC, useState, useRef, useEffect } from 'react';
import {
  TextField,
  List,
  Paper,
  ClickAwayListener,
  Chip,
  Box,
  FormControl
} from '@mui/material';
import styles from './locationPicker.module.css';
import ReactDOM from 'react-dom';
import useViewportSize from '../../../../../../components/AutoCompleteInput/useViewportSize';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
interface Location {
  id: number;
  name: string;
}

interface Props {
  placeholder?: string;
  options: Location[];
  fixedOptions: Location[]; // Renamed from fixedSelections to fixedOptions
  value: Location[]; // Array of selected locations
  onChange: (newValue: Location[]) => void; // Update function for selected locations
  maxOptions?: number;
}

export const LocationPicker: FC<Props> = ({
  placeholder,
  value,
  options,
  onChange,
  fixedOptions, // Renamed from fixedSelections
  maxOptions = 20
}) => {
  const actualVisibleViewport = useViewportSize();
  const [filteredOptions, setFilteredOptions] = useState<Location[]>(options);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // Track if component is being edited
  const [dropdownPosition, setDropdownPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const [dropdownMaxHeight, setDropdownMaxHeight] = useState<number | string>(
    'auto'
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const [inputText, setInputText] = useState<string>(''); // Track input text for filtering

  useEffect(() => {
    if (containerRef.current && actualVisibleViewport) {
      const [, viewportHeight] = actualVisibleViewport;
      const rect = containerRef.current.getBoundingClientRect();
      const availableHeight = Math.min(
        viewportHeight - rect.bottom - 16, // 16px margin from bottom of viewport
        300 // maximum height for dropdown
      );
      setDropdownMaxHeight(availableHeight);
      setDropdownPosition({
        top: rect.bottom + window.scrollY, // Adjust for page scroll
        left: rect.left + window.scrollX // Adjust for page scroll
      });
    }
  }, [actualVisibleViewport, open]);

  useEffect(() => {
    if (!open) return;

    // Filter options to exclude those already selected
    setFilteredOptions(
      options
        .filter(
          (option) => !value.some((selected) => selected.id === option.id)
        ) // Exclude selected options
        .filter((option) =>
          option.name.toLowerCase().includes(inputText.toLowerCase())
        )
        .slice(0, maxOptions)
    );
  }, [inputText, options, open, value]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputText(value);
    setOpen(!!value); // Open dropdown if there's input
  };

  const handleOptionSelect = (option: Location) => {
    if (!value.some((loc) => loc.id === option.id)) {
      // Avoid duplicates
      onChange([...value, option]); // Add selected option
    }
    setInputText(''); // Clear input after selection
    setOpen(false); // Close dropdown
  };

  const handleRemoveOption = (option: Location) => {
    const newValue = value.filter((loc) => loc.id !== option.id); // Remove selected option
    onChange(newValue); // Update selected values
  };

  const handleClickAway = () => {
    setOpen(false); // Close dropdown on outside click
    setIsEditing(false); // Stop editing when clicked outside
  };

  const handleClick = () => {
    setIsEditing(true); // Start editing on any click
    setOpen(true); // Open dropdown when clicking
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputText) {
      const selectedOption = options.find(
        (opt) => opt.name.toLowerCase() === inputText.toLowerCase()
      );
      if (
        selectedOption &&
        !value.some((loc) => loc.id === selectedOption.id)
      ) {
        handleOptionSelect(selectedOption); // Select the option
      }
      e.preventDefault(); // Prevent form submission if in a form
    }
  };

  const portal = document.getElementById('autocomplete-root');

  if (!portal) {
    console.warn("Modal can't open, there is no modal root in the html");
    return null;
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        style={{ position: 'relative', cursor: 'pointer' }}
        ref={containerRef}
        onClick={handleClick}
      >
        <FormControl fullWidth>
          <Box
            style={{ minHeight: 40 }}
            display="flex"
            flexWrap="wrap"
            gap={1}
            alignItems="center"
            border={1}
            borderColor="grey.400"
            borderRadius={1}
            p={1}
          >
            {isEditing
              ? value.map((selectedOption) => (
                  <>
                    <Chip
                      key={selectedOption.id}
                      label={selectedOption.name}
                      color="primary"
                      className={styles.chip}
                      {...(!fixedOptions.some(
                        (option) => option.id === selectedOption.id
                      ) && {
                        onDelete: isEditing
                          ? () => handleRemoveOption(selectedOption) // Only allow deletion when editing
                          : undefined
                      })}
                    />
                  </>
                ))
              : value.map(({ name }) => name).join(', ')}

            {isEditing ? (
              <TextField
                type="text"
                value={inputText}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                variant="standard"
                InputProps={{ disableUnderline: true }}
                sx={{ flex: 1, minWidth: '150px' }}
              />
            ) : (
              <Box style={{ marginLeft: 'auto' }}>
                <EditOutlinedIcon />
              </Box>
            )}
          </Box>
        </FormControl>

        {open &&
          dropdownPosition &&
          filteredOptions.length > 0 &&
          ReactDOM.createPortal(
            <Paper
              style={{
                position: 'absolute',
                top: dropdownPosition.top,
                left: dropdownPosition.left,
                zIndex: 1300, // Higher than most MUI components
                maxHeight: dropdownMaxHeight,
                overflowY: 'auto',
                width: containerRef.current?.clientWidth ?? 'auto' // Match the width of the input field
              }}
            >
              <List className={styles.options__container}>
                {filteredOptions.map((option) => (
                  <button
                    key={option.id}
                    onClick={() => handleOptionSelect(option)}
                    className={styles.option}
                  >
                    {option.name}
                  </button>
                ))}
              </List>
            </Paper>,
            portal
          )}
      </div>
    </ClickAwayListener>
  );
};
