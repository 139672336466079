import React, { FC, ReactNode } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/da"; // Import Danish locale

// Set the locale globally
dayjs.locale("da");

const theme = createTheme({
  palette: {
    primary: {
      main: "#2C7DFE",
    },
  },
});

interface MuiLocalizationAndThemeProviderProps {
  children: ReactNode;
}

export const MuiLocalizationAndThemeProvider: FC<
  MuiLocalizationAndThemeProviderProps
> = ({ children }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="da">
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </LocalizationProvider>
  );
};
