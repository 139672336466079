import React, { FC } from 'react';
import styles from './possibleMatchCard.module.css';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import {
  Inquiry,
  MatchRecord
} from '../../../../../../types/supabase/collections';
import { SetState } from '../../../../../../sharedPacakge/types/utilityTypes';

interface Props {
  match: {
    matchData: MatchRecord;
    inquiry: Inquiry;
  };
  probability: number;
  isSelected: boolean;
  setSelectedMatch: SetState<{
    matchData: MatchRecord;
    inquiry: Inquiry;
  } | null>;
}

const PossibleMatchCard: FC<Props> = ({
  match,
  probability,
  isSelected,
  setSelectedMatch
}) => {
  const onClick = (e: any) => {
    e.preventDefault();
    setSelectedMatch(match);
  };

  const shouldShowProbability = probability ? probability >= 50 : false;

  return (
    <div
      className={`${styles.container} ${isSelected ? styles.container__selected : ''}`}
      onClick={onClick}
    >
      <div className={styles.container__left}>
        {match.inquiry?.image && (
          <img
            className={styles.image}
            src={match.inquiry.image}
            alt="match-image"
            width={80}
            height={80}
          />
        )}
      </div>
      <div className={styles.container__right}>
        <div className={styles.category}>
          {match.inquiry.category.name_danish}
        </div>
        <div className={styles.description}>{match.inquiry.description}</div>
        {shouldShowProbability && (
          <div className={styles.probability}>
            <AutoAwesomeIcon /> {probability}% sandsynlig match
          </div>
        )}
      </div>
    </div>
  );
};

export default PossibleMatchCard;
