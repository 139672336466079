import { incrementDateByDays } from './incrementDateByDays';
import { DayOfWeek, ExpiryOption } from '../sharedPacakge/types';

export const getNewExpiryDate = (
  _lostOrFoundDate: Date,
  _locationExpirySetting: string
): Date => {
  const daysOfWeek: DayOfWeek[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];

  // Parse _expirySetting into ExpiryOption
  var expirySetting: ExpiryOption = 30;
  var createdDate = _lostOrFoundDate;

  if (!isNaN(Number(_locationExpirySetting))) {
    expirySetting = Number(_locationExpirySetting);
  } else {
    if (daysOfWeek.includes(_locationExpirySetting as DayOfWeek)) {
      expirySetting = _locationExpirySetting as DayOfWeek;
    }
  }

  const getNextWeekdayDate = (date: Date, weekday: DayOfWeek): Date => {
    const targetDay = daysOfWeek.indexOf(weekday);
    let daysToAdd = targetDay - date.getDay();
    if (daysToAdd <= 0) {
      daysToAdd += 7;
    }
    return incrementDateByDays(date, daysToAdd);
  };

  if (typeof expirySetting === 'number') {
    return incrementDateByDays(createdDate, expirySetting);
  }

  // Assuming expirySetting is a DayOfWeek
  return getNextWeekdayDate(createdDate, expirySetting);
};
// todo: Inquiry | foundItem (inquriy has +3)
