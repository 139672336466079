import { InquiryEmailTemplate } from '../../../../types/supabase/collections';
import { sendEmail } from '../../../../servieces/sendEmail';
import { formatEmailContentWithVariables } from '../../../../sharedPacakge/emails/formatEmailContentWithVariables';

export const sendTestInquiryEmail = async (
  inquiryTemplate: Omit<InquiryEmailTemplate, 'id'>,
  email: string,
  locationName: string
) => {
  await sendEmail({
    emails: [email],
    subject: inquiryTemplate.subject,
    htmlContent: formatEmailContentWithVariables({
      content: inquiryTemplate.content,
      recipientName: 'Lars Larsen',
      locationName: locationName,
      inquiryCategoryName: 'Telefon',
      inquiryDescription:
        'Jeg har mistet min Iphone 15 Pro max, med gennemsigtig apple cover'
    })
  });
};
