import { supabase } from '../../../supabase/supabaseClient';
import { FeedbackRecord } from '../../../../types/supabase/collections';
import { sendEmail } from '../../../sendEmail';
import logger from '../../../../sharedPacakge/logger';

export async function sendFeedback(
  input: Omit<FeedbackRecord, 'id' | 'created_at'>
): Promise<void> {
  const { error } = await supabase.from('feedback').insert(input);

  if (error) {
    logger.supabaseError('sendFeedback error', error);
    throw error;
  }

  await sendEmail({
    subject: 'Der blev indsendt fejl feedback',
    emails: ['ahmed02omran@gmail.com', 'vitaliysteffensen@gmail.com'],
    htmlContent: `${input.message} - af bruger ${input.user_id} som er i organisationen med id: ${input.organisation_id}`
  });
}
