import { supabase } from '../../../supabase/supabaseClient';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export async function deleteLocation(args: {
  organisationId: number;
  locationId: number;
}): Promise<{ success: boolean; errorMessage: string }> {
  const { organisationId, locationId } = args;

  // Step 1: Check if the organisation has more than one location
  const { data: locations, error: locationsError } = await supabase
    .from('locations')
    .select('id')
    .eq('organisation_id', organisationId);

  assertNoSupabaseError(
    locations,
    locationsError,
    'deleteLocation locationsError:',
    { organisationId },
    true
  );

  if (!locations || locations.length <= 1) {
    return {
      success: false,
      errorMessage: 'Der skal mindst være én lokation, i en organisation'
    };
  }

  // Step 2: Delete the location & its foreign key relations are set to cascade
  const { error: deleteLocationError } = await supabase
    .from('locations')
    .delete()
    .eq('id', locationId);

  assertNoSupabaseError(
    null,
    deleteLocationError,
    'deleteLocation locationsError:',
    { organisationId }
  );

  return { success: true, errorMessage: '' };
}
