import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ArchivedFoundItem,
  ArchivedInquiry,
  CategoryRecord,
  FoundItem,
  Inquiry,
  ItemCategory
} from '../../../../../../types/supabase/collections';
import { useModalNavigation } from '../../../../../../sharedPacakge/components/Modal/Modal';
import styles from '../EditableFoundItemTextField/editableFoundItemTextField.module.css';
import EditIcon from '@mui/icons-material/Edit';
import { ViewItemModalCallbacks } from '../../ViewItemModalCallbacks';
import { TextField } from '../../../../../../components/TextField/TextField';
import CategoryDropdown from '../../../../../../sharedPacakge/components/CategoryDropdown.tsx/CategoryDropdown';
import { useLocationData } from '../../../../../../contexts/LocationDataContext/LocationDataContext';
import { ResponsiveGridContainer } from '../../../../../../components/ResponsiveGridContainer/ResponsiveGridContainer';
import { useSnackbar } from '../../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import Api from '../../../../../../servieces/Api/Api';
import { runMatchmaking } from '../../../../../../servieces/matchmaking/runMatchmaking';
import Page from '../../../../../Page';

interface Props<T extends FoundItem | ArchivedFoundItem> {
  foundItem: T;
  matchingItem: Inquiry | ArchivedInquiry | null;
  callbacks: ViewItemModalCallbacks<T>;
}

function EditContent<T extends FoundItem | ArchivedFoundItem>({
  foundItem,
  callbacks,
  matchingItem
}: Props<T>) {
  const isArchivedItem = 'archive_reason' in foundItem;
  const { itemCategories, location } = useLocationData();
  const { showSnackbar } = useSnackbar();
  const { navigation, setNavigation } = useModalNavigation();
  const parentCategories = itemCategories
    .filter((category) => category.parent_category_id === null)
    .sort((a, b) =>
      a.name_danish.toLowerCase().localeCompare(b.name_danish.toLowerCase())
    );
  const [parentCategory, setParentCategory] = useState<CategoryRecord | null>(
    parentCategories.find(
      (category) => category!.id === foundItem.category.parent_category_id
    )!
  );
  const childCategories = parentCategory
    ? itemCategories
        .filter((category) => category.parent_category_id === parentCategory.id)
        .sort((a, b) =>
          a.name_danish.toLowerCase().localeCompare(b.name_danish.toLowerCase())
        )
    : [];
  const [category, setCategory] = useState<CategoryRecord | null>(
    childCategories.find((category) => category!.id === foundItem.category.id)!
  );

  const handleSubmit = useCallback(async () => {
    if (!category) {
      showSnackbar('Under kategori mangler', 'warning');
      return;
    }

    // Your API call and state update logic
    await Api.put.foundItem(
      foundItem.id,
      {
        category_id: category.id
      },
      isArchivedItem
    );
    callbacks.onUpdateItem(foundItem.id, {
      category: category
    } as Partial<T>);
    showSnackbar('Kategori opdateret!', 'success');

    // run matchmaking if active & no current match
    if (!isArchivedItem && !matchingItem) {
      await Api.delete.matches((q) => q.eq('found_item_id', foundItem.id));
      const { category: categoryObject, ...rest } = foundItem;
      const foundItemRecord = {
        ...rest,
        category_id: categoryObject.id
      };
      const { matchesCount } = await runMatchmaking(foundItemRecord);
      matchesCount > 0 &&
        showSnackbar(
          <>
            {matchesCount} mulige matches.{' '}
            <a
              style={{ textDecoration: 'underline' }}
              href={Page.l.matchmaking(location!.id, foundItem.id)}
            >
              Se matches
            </a>
          </>
        );
    }

    setNavigation(null);
  }, [category, setNavigation]);

  useEffect(() => {
    if (navigation) {
      // Only update navigation if needed
      setNavigation((prev) => {
        if (prev && prev.onSubmit !== handleSubmit) {
          return {
            ...prev,
            onSubmit: handleSubmit
          };
        }
        return prev; // Ensure we return prev in both branches
      });
    }
  }, [navigation, handleSubmit]); // handleSubmit is still a dependency, but we mitigate the issue

  return (
    <ResponsiveGridContainer>
      <CategoryDropdown
        label="Kategori"
        value={parentCategory}
        itemCategories={parentCategories}
        onChange={(e, newValue: ItemCategory | null) => {
          if (newValue) {
            // mategerial UI will get warnings if we don't use exact object refference
            const exactObjRef = parentCategories.find(
              (p) => p.id === newValue.id
            ) as ItemCategory;
            setParentCategory(exactObjRef);
            setCategory(null);
          } else setParentCategory(null);
        }}
      />
      <CategoryDropdown
        label="Under kategori"
        disabled={!parentCategory}
        value={category}
        itemCategories={childCategories}
        onChange={(e, newValue: ItemCategory | null) => {
          if (newValue) {
            // mategerial UI will get warnings if we don't use exact object refference
            const exactObjRef = itemCategories.find(
              (p) => p.id === newValue.id
            ) as ItemCategory;
            setCategory(exactObjRef);
          } else setCategory(null);
        }}
      />
    </ResponsiveGridContainer>
  );
}

export function EditableCategory<T extends FoundItem | ArchivedFoundItem>(
  props: Props<T>
) {
  const { setNavigation } = useModalNavigation();

  const onEditClick = (e: any) => {
    e.preventDefault();
    setNavigation({
      title: 'Kategori',
      // this has to be another component, in order for it to keep its state once it slides out.
      content: <EditContent {...props} />,
      onBack: () => setNavigation(null),
      onSubmit: () => {}
    });
  };

  return (
    <div onClick={onEditClick}>
      <TextField
        classes={{
          input: styles.pointerCursor
        }}
        label="Kategori"
        value={props.foundItem.category.name_danish}
        onChange={() => {}} // nothing should happen here
        adornment={{
          end: <EditIcon />
        }}
      />
    </div>
  );
}
