import React, { FC } from "react";
import styles from "./compareMatch.module.css";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Divider } from "@mui/material";
import {
  FoundItem,
  Inquiry,
} from "../../../../../../types/supabase/collections";
import { formatDate } from "../../../../../../utils/formatting/formatDate";

interface Props {
  foundItem: FoundItem;
  selectedMatch: Inquiry;
  probability: number;
  // setSelectedMatch: SetState<Inquiry | null>;
}
const CompareMatch: FC<Props> = ({ foundItem, selectedMatch, probability }) => {
  const onClick = (e: any) => {
    e.preventDefault();
  };

  return (
    <div className={styles.container}>
      {probability >= 50 && (
        <div className={styles.probability}>
          <AutoAwesomeIcon /> {probability}% sandsynlig match
        </div>
      )}
      <div className={styles.flexContainer}>
        <div>
          <div className={styles.title}>Efterlysning</div>
          <div className={styles.id}>ID: {selectedMatch.id}</div>
          <div className={styles.image__container}>
            {selectedMatch?.image ? (
              <img
                className={styles.image}
                src={selectedMatch.image}
                alt="match-image"
                width={350}
                height={350}
              />
            ) : (
              <div className={styles.image}>
                <div className={styles.image__none}>
                  ( intet billede vedhæftet )
                </div>
              </div>
            )}
          </div>
          <div>
            <div className={styles.category}>
              {selectedMatch.category.name_danish}
            </div>
            <div className={styles.time}>
              Mistet {formatDate(selectedMatch.lost_at_date)}
            </div>
          </div>
        </div>
        <div>
          <div className={styles.title}>Fund</div>
          <div className={styles.id}>ID: {foundItem.id}</div>
          <div className={styles.image__container}>
            <img
              className={styles.image}
              src={foundItem.image}
              alt="match-image"
              width={350}
              height={350}
            />
          </div>
          <div className={styles.category}>
            {foundItem.category.name_danish}
          </div>
          <div className={styles.time}>
            Fundet {formatDate(foundItem.found_at_date)}
          </div>
        </div>
      </div>
      <div className={styles.sectionSplit}>
        <Divider />
        <p>Beskrivelse</p>
      </div>
      <div className={styles.flexContainer}>
        <div className={styles.description}>{selectedMatch.description}</div>
        <div className={styles.description}>{foundItem.description}</div>
      </div>
      <div className={styles.sectionSplit}>
        <Divider />
        <p>Kontakt detaljer</p>
      </div>
      <div className={styles.flexContainer}>
        <div className={styles.description}>
          <p>{selectedMatch.reporter_name}</p>
          <p>{selectedMatch.reporter_email}</p>
          <p>{selectedMatch.reporter_phone}</p>
        </div>
        <div className={styles.description}></div>
      </div>
    </div>
  );
};

export default CompareMatch;
