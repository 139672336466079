import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import FoundItemsTable from '../../../../components/FoundItemsTable/FoundItemTable';
import React, { FC, useEffect, useState } from 'react';
import {
  FoundItem,
  MatchRecord
} from '../../../../../../types/supabase/collections';
import Page from '../../../../../Page';
import { useLocationData } from '../../../../../../contexts/LocationDataContext/LocationDataContext';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../../../sharedPacakge/components/Button/Button';
import { Text } from '../../../../../../components/Text/Text';
import { ViewItemModalCallbacks } from '../../../../components/ViewItemModal/ViewItemModalCallbacks';
import styles from '../foundItemsTable.module.css';

export const filterItemsWithPossibleMatches = (
  foundItems: FoundItem[],
  matchesData: MatchRecord[]
) => {
  const uniqueItemsIdsFromMatches = Array.from(
    new Set(matchesData.map((match) => match.found_item_id))
  );
  return foundItems.filter((item) =>
    uniqueItemsIdsFromMatches.includes(item.id)
  );
};

export const PossibleMatchesTable: FC<{
  itemsWithPossibleMatch: FoundItem[];
  matchesData: MatchRecord[];
  callbacks: ViewItemModalCallbacks<FoundItem>;
}> = ({ itemsWithPossibleMatch, matchesData, callbacks }) => {
  const { location } = useLocationData();
  const navigate = useNavigate();

  if (!location) return null;

  const onSeeMatchesClick = (e: any, foundItem: FoundItem) => {
    e.preventDefault();
    navigate(Page.l.matchmaking(location.id, foundItem.id));
  };

  const getMatchCount = (foundItemId: number) =>
    matchesData.filter((match) => match.found_item_id === foundItemId).length;

  return (
    <div>
      <FoundItemsTable
        foundItems={itemsWithPossibleMatch}
        callbacks={callbacks}
        options={{
          columns: ['image', 'category', 'description', 'found_at_date'],
          renderNoFoundItems: () => (
            <span className={styles.noFoundItems__text}>
              <InventoryRoundedIcon />
              <Text type="body-strong">Du er helt ajour.</Text>
            </span>
          ),
          renderActionsColumn: (row) => (
            <div className={styles.tableActions__container}>
              <Button
                onClick={(e) => onSeeMatchesClick(e, row)}
                buttonType="white"
                size="small"
              >
                <Text type="body-strong">
                  Se {getMatchCount(row.id)} matches
                </Text>
              </Button>
            </div>
          )
        }}
      />
    </div>
  );
};
