import React, { createContext, useContext, useEffect, useState } from 'react';
import { User, Session } from '@supabase/auth-js/src/lib/types';
import { supabase } from '../../servieces/supabase/supabaseClient';
import { objectsAreEqual } from '../../utils/arraysAreEqual';
import { useDeepMemo } from '../../utils/hooks/useDeepMemo';
import { useSnackbar } from '../../sharedPacakge/components/Snackbar/SnackbarContext';
import Api from '../../servieces/Api/Api';

interface AuthContextProps {
  user: User | null;
  username: string;
  loading: boolean;
  signInWithEmail: (email: string, password: string) => Promise<User | null>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { showSnackbar } = useSnackbar();
  const [user, setUser] = useState<User | null>(null);
  const [username, setUsername] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const updateUser = (newValue: User | null) => {
    if (objectsAreEqual(newValue, user)) return;

    setUser(newValue);

    if (newValue) {
      Api.get.userProfile(newValue.id).then((profile) => {
        setUsername(profile.full_name);

        const isOngoingSession = sessionStorage.getItem('isOngoingSession');
        if (!isOngoingSession) {
          sessionStorage.setItem('isOngoingSession', 'true');
          Api.put.userProfile(profile.id, {
            all_sessions: [...profile.all_sessions, new Date().toISOString()],
            last_active_at: new Date().toISOString()
          });
        }
      });
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const {
          data: { session },
          error
        } = await supabase.auth.getSession();

        if (error) {
          throw new Error(`Failed to fetch session: ${error.message}`);
        }

        const fetchedUser = session?.user || null;
        updateUser(fetchedUser);
      } catch (error) {
        console.error('Error fetching user:', error);
        updateUser(null);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();

    const {
      data: { subscription }
    } = supabase.auth.onAuthStateChange((_, session: Session | null) => {
      const newUser = session?.user || null;
      updateUser(newUser);
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, [user]);

  const signInWithEmail = async (
    email: string,
    password: string
  ): Promise<User | null> => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: email.toLowerCase(),
        password
      });

      if (error) {
        if (error.message === 'Invalid login credentials')
          showSnackbar('adgangskode eller email er forkert', 'error');
        else if (error.message === 'Email not confirmed')
          showSnackbar('Du mangler at bekræfte din email', 'error');
        else showSnackbar(error?.message, 'error');
      }

      if (error || !data.session) {
        throw new Error(error?.message || 'Invalid login credentials');
      }

      const newUser = data.user;
      updateUser(newUser);

      return newUser;
    } catch (error) {
      console.error('Error signing in:', error);
      return null;
    }
  };

  const signOut = async () => {
    try {
      await supabase.auth.signOut();
      updateUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const memoizedValue = useDeepMemo(() => {
    return {
      user,
      username,
      loading,
      signInWithEmail,
      signOut
    };
  }, [user, loading, username]) as any;

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
