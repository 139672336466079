import React from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import styles from './signOutButton.module.css';
import { useNavigate } from 'react-router-dom';
import Page from '../../../../Page';
import { useAuth } from '../../../../../contexts/AuthContext/AuthContext';
import { Button } from '../../../../../sharedPacakge/components/Button/Button';
const SignOutButton = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const onClick = async () => {
    await signOut();
    navigate(Page.login);
  };

  return (
    <Button onClick={onClick} fullWidth className={styles.button}>
      <ExitToAppIcon className={styles.icon} /> Log ud
    </Button>
  );
};

export default SignOutButton;
