import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect
} from 'react';

type TriggerEvent = (
  eventName: EventName,
  triggerEventAfter: () => Promise<any>
) => void;

type EventsContextProps = {
  triggerEvent: TriggerEvent;
};

const defaultValue: EventsContextProps = {
  triggerEvent: () => {}
};

const EventsContext = createContext<EventsContextProps>(defaultValue);

type EventName = 'foundItemUploaded';

export const EventsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  useEffect(() => {}, []);

  const triggerEvent = (
    eventName: EventName,
    triggerEventAfter: () => Promise<any>
  ) => {
    triggerEventAfter().then(() => window.dispatchEvent(new Event(eventName)));
  };

  const value = { triggerEvent };

  return (
    <EventsContext.Provider value={value}>{children}</EventsContext.Provider>
  );
};

export const useEventDispatcher = (): TriggerEvent => {
  const context = useContext(EventsContext);
  if (!context) {
    throw new Error(
      'useLocationData must be used within an LocationDataProvider'
    );
  }
  return context.triggerEvent;
};

export const useEventListener = (
  eventName: EventName,
  callback: () => void
) => {
  useEffect(() => {
    window.addEventListener(eventName, callback);
    return () => {
      window.removeEventListener(eventName, callback);
    };
  }, [eventName, callback]);
};
