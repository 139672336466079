import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { LoadingSpinner } from '../../sharedPacakge/components/LoadingSpinner/LoadingSpinner';
import { useHotjarIdentity } from '../../utils/hooks/useHotjarIdentity';

const AuthRoute: React.FC<{ element: ReactElement }> = ({ element }) => {
  const { user, loading } = useAuth();
  useHotjarIdentity();

  if (loading) {
    return <LoadingSpinner centered />;
  }

  return user ? element : <Navigate to="/login" />;
};

export default AuthRoute;
