import React, {useEffect, useState} from 'react';
import {useLocationData} from '../../../../contexts/LocationDataContext/LocationDataContext';
import {TabLayout} from '../../components/TabLayout/TabLayout';
import {
    TextField, 
} from '@mui/material';
import Api from '../../../../servieces/Api/Api';
import {useAuth} from '../../../../contexts/AuthContext/AuthContext';
import CheckIcon from '@mui/icons-material/Check';
import {Button} from '../../../../sharedPacakge/components/Button/Button';
import {useSnackbar} from '../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import logger from "../../../../sharedPacakge/logger";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

export const FeedbackTab = () => {
  const {user} = useAuth();
  const {organisation, location} = useLocationData();
  const {showSnackbar} = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tab, setTab] = useState<"Feedback" | "Help">('Help');
  const [message, setMessage] = useState<string>('');
  const [isSubmittet, setIsSubmittet] = useState<boolean>(false);

  if (!organisation || !location || !user) return null;

  const onSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    if (message === '') return showSnackbar('ingen besked');

    Api.post
    .feedback({
      feedback_type: "feedback",
      organisation_id: organisation.id,
      user_id: user.id,
      message
    })
    .then(() => {
      setIsLoading(false);
      showSnackbar('Feedback er modtaget');
      setIsSubmittet(true);
    })
    .catch(() => showSnackbar('Der skete en fejl', 'error'));
  };

    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
        setTab(newValue);
    };
  
  return (
      <TabLayout>
          <TabContext value={tab}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Hjælp, problemer & andet" value="Help" />
                  <Tab label="Feedback & forslag" value="Feedback" />
              </TabList>
    
          <TabPanel value="Help" style={{padding: 0, margin: 0}}>
           <TawkToChat/>
          </TabPanel>
          <TabPanel value="Feedback" style={{padding: 0, margin: 0}}>
              {isSubmittet ? (
                  <div
                      style={{
                          display: 'flex',
                          gap: 10,
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: 40
                      }}
                  >
                      <h2 style={{color: '#2D7CFE'}}>Feedback modtaget</h2> <CheckIcon/>
                  </div>
              ) : (
                  <form
                      onSubmit={onSubmit}
                      style={{
                          maxWidth: 600,
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 20,
                          marginTop: 40
                      }}
                  >
                      <TextField
                          label="Besked"
                          value={message}
                          onChange={(e: any) => setMessage(e.target.value)}
                          fullWidth
                          variant="outlined"
                          disabled={isLoading}
                          multiline
                          rows={4}
                      />
                      <Button type="submit" fullWidth>
                          Send
                      </Button>
                  </form>
              )}
          </TabPanel>
          </TabContext>
      </TabLayout>
  );
};

declare global {
  interface Window {
    Tawk_API?: any;
    Tawk_LoadStart?: Date;
    tawkto_loadedOnce?: boolean; // this is to check if script has loaded once. on the page.
  }
}
const TawkToChat = () => {
  const {user, username} = useAuth()
  const {organisation, location, userRole} = useLocationData()
    
  useEffect(() => {
      if (!user || !organisation || !location) return;
      if (window.tawkto_loadedOnce) {
          window.location.reload()
          // quickfix fullpage relead to mount and unmount the tawkto chat
          // this is quickfix, because the tawkto script doesnt work so well, when trying to remount it.
          // it only works propperly on a full page reload, not only in an spa laod, for some reason.
      }
      
    // Initialize Tawk_API and Tawk_LoadStart globally
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();
    window.Tawk_API.embedded = 'tawk_66f6683de5982d6c7bb56fde';

    // Create and append the script dynamically
    const script = document.createElement("script");
    script.async = true;
    script.src = 'https://embed.tawk.to/66f6683de5982d6c7bb56fde/1i8q2m9g9';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    document.body.appendChild(script);

    // Add event listener for when the script has loaded
      script.onload = () => {
          setTimeout(() => {
              window.tawkto_loadedOnce = true;
              

          // Check if Tawk_API is available
          if (window.Tawk_API.setAttributes) {
              window.Tawk_API.setAttributes(
                  {
                      name: username,
                      organisation: `${organisation.name} | ID: ${organisation.id}`,
                      location: `${location.name} | ID: ${location.id}`,
                      userId: user.id,
                      email: user.email,
                      role: userRole
                  },
                  (error: any) => 
                         error &&  logger.error(`Error setting Tawk.to attributes: ${JSON.stringify(error)}`)
              );
          } else {
              console.error("Tawk_API.addEvent is not available yet.");
          }}, 1000)
      };



    // Cleanup to remove the script when the component is unmounted
      return () => {
          // const scripts = document.querySelectorAll('script[src*="embed.tawk.to"]');
          // scripts.forEach(script => {
          //     if (script && script.parentNode) {
          //         script.parentNode.removeChild(script);
          //     }
          // });
          //
          // // Reset global Tawk.to API state
          // if (window.Tawk_API) {
          //     window.Tawk_API = undefined;
          //     window.Tawk_LoadStart = undefined;
          // }
      };
  }, [organisation, location, user]); // Add dependencies to effect

  return (
      <div id="tawk_66f6683de5982d6c7bb56fde"
           style={{
             width: '100%',
             height: '70vh',
             position: 'relative',
               zIndex: 0,
           }}
      ></div>
  );
};


