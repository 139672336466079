import { PostgrestError } from '@supabase/supabase-js';
import { StorageError } from '@supabase/storage-js';

const betterStackUrl = 'https://in.logs.betterstack.com';
const apiKey =
  process.env.REACT_APP_BETTER_STACK_LOGGING_API_KEY ??
  process.env.NEXT_PUBLIC_BETTER_STACK_LOGGING_API_KEY;

const sendLog = async (
  level: 'error' | 'warn' | 'info' | 'debug',
  message: string,
  fullText?: string
) => {
  if (process.env.NODE_ENV !== 'production') {
    console[level](message);
    return;
  }

  const data: Record<string, any> = {
    dt: new Date().toISOString().replace('T', ' ').replace('Z', ' UTC'),
    level,
    message
  };

  if (fullText) {
    data.full_text = fullText;
  }

  try {
    const response = await fetch(betterStackUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${apiKey}`
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      console.error(`Failed to send log: ${response.statusText}`);
    }
  } catch (error) {
    console.error('Error sending log:', error);
  }
};

const logger = {
  supabaseError: (
    context: string,
    error: PostgrestError,
    contextData: object = {}
  ) => {
    sendLog(
      'error',
      `${context}
     hint: ${error.hint}
     code: ${error.code}
     message: ${error.message}
     details: ${error.details}
     | context Data: ${JSON.stringify(contextData)}
     `
    ).then();
  },

  supabaseStorageError: (prefix: string, error: StorageError) => {
    sendLog(
      'error',
      `${prefix}
     name: ${error.name}
     cause: ${error.cause}
     message: ${error.message}
     stack: ${error.stack}
     `
    ).then();
  },
  error: (message: string, fullText?: string) => {
    sendLog('error', message, fullText).then();
  },
  warn: (message: string, fullText?: string) => {
    sendLog('warn', message, fullText).then();
  },
  info: (message: string, fullText?: string) => {
    sendLog('info', message, fullText).then();
  },
  debug: (message: string, fullText?: string) => {
    sendLog('debug', message, fullText).then();
  }
};

export default logger;
