import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox
} from '@mui/material';
import { objectsAreEqual } from '../../utils/arraysAreEqual';
import styles from './genricTable.module.css';
import { GenericTableProps } from './GenericTable';

const isDate = (value: any): value is Date => value instanceof Date;

function GenericTableMobile<T extends Record<string, any>>({
  columns,
  data,
  onRowClick,
  rowKey,
  actionsColumn,
  multiSelect,
  isRowLoading
}: GenericTableProps<T>) {
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof T | ''>('');
  const [selectedRows, setSelectedRows] = React.useState<T[]>([]);

  const handleSort = (columnId: keyof T) => {
    const isAsc = orderBy === columnId && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnId);
  };

  // Determine which column IDs are present in the data
  const validColumnIds = new Set<keyof T>(
    data.length > 0 ? (Object.keys(data[0]) as Array<keyof T>) : []
  );

  const sortedData = React.useMemo(() => {
    if (!orderBy || !validColumnIds.has(orderBy)) return data;

    return [...data].sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const aDate = new Date(aValue);
        const bDate = new Date(bValue);
        if (!isNaN(aDate.getTime()) && !isNaN(bDate.getTime())) {
          return (
            (aDate.getTime() - bDate.getTime()) * (order === 'asc' ? 1 : -1)
          );
        }
        return aValue.localeCompare(bValue) * (order === 'asc' ? 1 : -1);
      } else if (typeof aValue === 'number' && typeof bValue === 'number') {
        return (aValue - bValue) * (order === 'asc' ? 1 : -1);
      } else if (isDate(aValue) && isDate(bValue)) {
        return (
          (aValue.getTime() - bValue.getTime()) * (order === 'asc' ? 1 : -1)
        );
      }
      return 0;
    });
  }, [data, order, orderBy, validColumnIds]);

  const onRowSelect = (e: any, row: T) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = e.target.checked;

      if (isSelected) {
        const newValue = [...prevSelectedRows, row];
        multiSelect?.onSelectedRowChange(newValue);
        return newValue;
      } else {
        const newValue = prevSelectedRows.filter(
          (prevSelectedRow) => !objectsAreEqual(prevSelectedRow, row)
        );
        multiSelect?.onSelectedRowChange(newValue);
        return newValue;
      }
    });
  };

  const onRowSelectAll = (e: any) => {
    setSelectedRows((prevSelectedRows) => {
      const newValue = prevSelectedRows.length === data.length ? [] : data;
      multiSelect?.onSelectedRowChange(newValue);
      return newValue;
    });
  };

  // todo - no image header if no image column
  // make the different stuff more pretty
  // ensure all tables looks good & on mobile & actions coloumn also looks goood

  return (
    <TableContainer>
      {multiSelect?.enabled && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end'
          }}
        >
          <div>Vælg alle</div>
          <TableCell padding="checkbox" style={{ border: 'none' }}>
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Checkbox
                classes={{
                  checked: styles.multiSelect__checkbox
                }}
                checked={selectedRows.length === data.length}
                onChange={onRowSelectAll}
                inputProps={{
                  'aria-label': 'select row'
                }}
              />
            </div>
          </TableCell>
        </div>
      )}
      <Table>
        <TableHead>
          <TableRow>
            {/*<TableCell key="image" style={{ border: 'none' }} />*/}
            {/*<TableCell key="content" style={{ border: 'none' }} />*/}
            {/*{actionsColumn && <TableCell style={{ border: 'none' }} />}*/}
            {/* Empty header for actions column */}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((row) => {
            // Extract the image column separately
            const imageColumn = columns.find((column) => column.id === 'image');
            const isLoading = isRowLoading?.(row) || false;

            // Determine if the row should be clickable
            const handleRowClick = (e: any) => {
              if (onRowClick) {
                onRowClick(e, row);
              }
            };

            return (
              <TableRow
                key={row[rowKey]} // Use the rowKey prop here
                style={{
                  cursor: onRowClick ? 'pointer' : 'default',
                  background: isLoading ? '#f4f4f4' : 'inherit'
                }}
                className={isLoading ? styles.skeleton : ''}
                onClick={(e: any) =>
                  !isLoading && onRowClick && onRowClick(e, row)
                }
              >
                {imageColumn && imageColumn.renderMobile && (
                  <TableCell
                    key="image"
                    style={{ padding: '2px 0', height: 90, border: 'none' }}
                  >
                    {typeof imageColumn.renderMobile === 'function'
                      ? imageColumn.renderMobile(row)
                      : imageColumn.render(row)}
                  </TableCell>
                )}

                <TableCell
                  key="content"
                  style={{ border: 'none', padding: '2px 10px' }}
                >
                  {columns
                    .filter(
                      (column) => column.renderMobile && column.id !== 'image'
                    )
                    .map((column) =>
                      typeof column.renderMobile === 'function' ? (
                        <div>{column.renderMobile(row)}</div>
                      ) : (
                        <div>{column.render(row)}</div>
                      )
                    )}
                </TableCell>

                {actionsColumn && (
                  <TableCell style={{ border: 'none', padding: '2px 10px' }}>
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      {actionsColumn(row)}
                    </div>
                  </TableCell>
                )}

                {multiSelect?.enabled && (
                  <TableCell padding="checkbox" style={{ border: 'none' }}>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Checkbox
                        classes={{
                          checked: styles.multiSelect__checkbox
                        }}
                        checked={
                          !!selectedRows.find((selectedRow) =>
                            objectsAreEqual(row, selectedRow)
                          )
                        }
                        onChange={(e) => onRowSelect(e, row)}
                        inputProps={{
                          'aria-label': 'select row'
                        }}
                      />
                    </div>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default GenericTableMobile;
