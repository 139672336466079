import { FC, ReactNode } from 'react';
import styles from './chip.module.css';

interface Props {
  children: ReactNode;
  background?: string;
  borderColor?: string;
}
export const Chip: FC<Props> = ({ children, background, borderColor }) => {
  return (
    <div
      className={styles.chip}
      style={{
        background: background ?? '#FFFFFF',
        borderColor: borderColor ?? '#FFFFFFF'
      }}
    >
      {children}
    </div>
  );
};
