const secretKeyStr = '12345'; // not too important if this is hidden or not, this is only to not directly show amount of locations & organisations
const secretKey = parseInt(secretKeyStr, 10); // Convert secretKey to a number
const base = 36;

export function encodeIntId(id: number): string {
  const saltedId = id + secretKey;
  return saltedId.toString(base);
}

export function decodeIntId(encodedId: string): number {
  const saltedId = parseInt(encodedId, base);
  return saltedId - secretKey;
}
