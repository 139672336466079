import { ItemStatus } from '../../sharedPacakge/types/ItemStatus';
import {
  ArchivedFoundItem,
  ArchivedInquiry,
  FoundItem,
  Inquiry
} from '../../types/supabase/collections';

export const translateItemStatus = (itemStatus: ItemStatus | string) => {
  switch (itemStatus) {
    case ItemStatus.AwaitingMatch:
      return 'Afventer match';
    case ItemStatus.ReadyForPickup:
      return 'Afventer afhentning';
    case ItemStatus.LeftBehind:
      return 'Ejer afhentede aldrig genstanden';
    case ItemStatus.Expired:
      return 'Udløbet';
  }
};

export const TranslateViewItemModalStatus = (
  item: FoundItem | ArchivedFoundItem | ArchivedInquiry | Inquiry
) => {
  const isArchivedItem = 'archive_reason' in item;

  if (isArchivedItem) return item.archive_reason;

  switch (item.status) {
    case ItemStatus.AwaitingMatch:
      return 'Afventer match';
    case ItemStatus.ReadyForPickup:
      return 'Afventer afhentning';
    case ItemStatus.LeftBehind:
      return 'Ejer afhentede aldrig genstanden';
    case ItemStatus.Expired:
      return 'Udløbet';
  }
};
