import React, { FC } from 'react';
import { Menu } from '@mui/material';
import { useAuth } from '../../../../../contexts/AuthContext/AuthContext';
import CircleImage from '../../OrganisationLogo/OrganisationLogo';
import { OrganisationSelectModal } from './OrganisationSelectModal';

export const OrganisationSelectButton: FC = () => {
  const { username } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <CircleImage altText={username} onClick={handleClick} />
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <OrganisationSelectModal handleClose={handleClose} />
      </Menu>
    </div>
  );
};
