import { supabase } from '../../../supabase/supabaseClient';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export async function getAnyLocationIdFromOrganisation(args: {
  userId: string;
  organisationId: number;
}): Promise<{ locationId: number }> {
  const { userId, organisationId } = args;

  const { data: userRoles, error: userRoleError } = await supabase
    .from('user_roles')
    .select()
    .eq('user_id', userId)
    .eq('organisation_id', organisationId);

  assertNoSupabaseError(
    userRoles,
    userRoleError,
    'getAnyLocationIdFromOrganisation userRoleError:',
    { userId, userRoleError },
    true
  );

  // if the user is an locationAdmin, we can just return the locationId straight away
  const locationId = userRoles
    .map((userRole) => userRole.location_id)
    .find((location_id) => location_id !== null);
  if (locationId) return { locationId };

  // if user is org admin, then find the locationId
  const { data: location, error: locationError } = await supabase
    .from('locations')
    .select('id')
    .eq('organisation_id', organisationId)
    .limit(1)
    .single();

  assertNoSupabaseError(
    location,
    locationError,
    'getAnyLocationIdFromOrganisation locationError:',
    { userId, organisationId },
    true
  );

  return { locationId: location.id };
}
