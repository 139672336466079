import { tabsConfiguration } from '../tabsConfiguration';
import { useLocation } from 'react-router-dom';
import { useLocationData } from '../../../contexts/LocationDataContext/LocationDataContext';

export const useActiveTab = () => {
  const { location, userRole } = useLocationData();
  const { pathname } = useLocation();

  if (!location) return { activeTab: null };

  const tabs = tabsConfiguration(location.id).filter(
    (tab) => !tab.visibleForRoles || tab.visibleForRoles.includes(userRole)
  );

  // Find the tab that matches the current pathname
  const activeTab = tabs.find((tab) => pathname === tab.urlPattern);

  return { activeTab };
};
