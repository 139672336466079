import React, { FC } from 'react';
import styles from './organisationLogo.module.css';
import { Skeleton } from '@mui/material';
interface Props {
  image?: string | undefined;
  altText: string | undefined;
  className?: string;
  onClick?: (e: any) => void;
  size?: 'small' | 'normal';
}

const CircleImage: FC<Props> = ({
  altText,
  className,
  onClick,
  size = 'normal'
}) => {
  const sizes = {
    width: size === 'normal' ? 40 : 20,
    height: size === 'normal' ? 40 : 20
  };

  const props: any = {
    className: `${styles.circleImage} ${className ?? ''}`,
    style: { ...sizes, fontSize: size === 'normal' ? 16 : 12 }
  };

  if (onClick) {
    props.onClick = onClick;
    props.className += ` ${styles.hoverEffect}`;
  }

  return altText ? (
    <div {...props}>{altText.charAt(0).toUpperCase()}</div>
  ) : (
    <Skeleton variant="circular" {...sizes} />
  );
};

export default CircleImage;
