import React, { FC, RefObject, useEffect, useRef } from 'react';
import styles from './textArea.module.css'; // Ensure this path is correct

type CustomProps = {
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  id?: string;
  label?: string;
  adornment?: {
    beforeValue?: React.ReactNode;
    end?: React.ReactNode;
  };
  classes?: {
    input?: string;
  };
  inputRef?: RefObject<HTMLTextAreaElement>;
};
export const TextArea: FC<CustomProps> = ({
  value = '',
  onChange,
  onKeyDown,
  placeholder,
  disabled = false,
  id,
  label,
  adornment,
  classes,
  inputRef // Use inputRef directly here
}) => {
  const internalRef = useRef<HTMLTextAreaElement>(null);
  const textareaRef = inputRef || internalRef; // Use inputRef if provided, otherwise fall back to internalRef

  const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (disabled) {
      e.target.blur(); // Remove focus if the field is disabled
    }
  };

  // Auto-resize function
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset height to auto
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set height to scroll height
    }
  }, [value]);

  return (
    <div className={`${styles.textFieldContainer}`}>
      {adornment?.beforeValue && (
        <div className={styles.beforeValueAdornment}>
          {adornment.beforeValue}
        </div>
      )}
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
      )}
      <textarea
        ref={textareaRef} // Use textareaRef which is now correctly either inputRef or internalRef
        value={value}
        rows={1}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        id={id}
        onKeyDown={onKeyDown}
        className={`
          ${styles.textField} 
          ${disabled ? styles.textField__disabled : ''} 
          ${adornment?.beforeValue ? styles.inputWithStart : ''} 
          ${adornment?.end ? styles.inputWithEnd : ''}
          ${!label ? styles.textField__noLabel : ''}
          ${classes?.input || ''}
        `}
        onFocus={handleFocus}
        style={{ overflow: 'hidden', resize: 'none' }} // Prevent resizing
      />
      {adornment?.end && (
        <div className={styles.endAdornment}>{adornment.end}</div>
      )}
    </div>
  );
};
