import { supabase } from '../../../supabase/supabaseClient';
import { PostFoundItemJobsRecord } from '../../../../types/supabase/collections';

export function subscribeToFoundItemJobs(
  jobIds: number[],
  callback: (job: PostFoundItemJobsRecord) => void
) {
  const channel = supabase
    .channel('post_found_item_jobs_updates')
    .on(
      'postgres_changes',
      {
        event: 'INSERT', // Listen to INSERT events
        schema: 'public',
        table: 'post_found_item_jobs',
        filter: `id=in.(${jobIds.join(',')})` // Filter by job IDs
      },
      (payload) => {
        const job = payload.new as PostFoundItemJobsRecord; // The inserted job data
        callback(job); // Call the callback with the new job
      }
    )
    .on(
      'postgres_changes',
      {
        event: 'UPDATE', // Listen to UPDATE events
        schema: 'public',
        table: 'post_found_item_jobs',
        filter: `id=in.(${jobIds.join(',')})` // Filter by job IDs
      },
      (payload) => {
        const job = payload.new as PostFoundItemJobsRecord; // The updated job data
        callback(job); // Call the callback with the updated job
      }
    )
    .subscribe();

  // Return a function to unsubscribe when no longer needed
  return () => {
    supabase.removeChannel(channel);
  };
}
