import {
  InquiryEmailTemplate,
  InquiryEmailType
} from '../../../../types/supabase/collections';
import { supabase } from '../../../supabase/supabaseClient';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';
import { getDefaultEmailTemplate } from '../../../../sharedPacakge/emails/defaultEmailTemplate';

export const getEmailTemplate = async (
  inquiryEmailType: InquiryEmailType,
  organisationId: number
): Promise<Omit<InquiryEmailTemplate, 'id'>> => {
  const { data, error } = await supabase
    .from('inquiry_email_templates')
    .select(
      `content, content_json, enabled, id, inquiry_email_type, organisation_id, scheduled_in_x_days, subject`
    )
    .eq('organisation_id', organisationId)
    .eq('inquiry_email_type', inquiryEmailType)
    .single();

  assertNoSupabaseError(data, error, 'getEmailTemplate error', {
    inquiryEmailType,
    organisationId
  });

  return data ?? getDefaultEmailTemplate(inquiryEmailType, organisationId);
};
