import React, { createContext, useContext, useState, useCallback } from 'react';

interface ProgressBar {
  id: number;
  progress: number;
}

interface HeaderProgressBarContextType {
  progressBars: ProgressBar[];
  addOrUpdateProgressbar: (id: number, progress: number) => void;
}

const HeaderProgressBarContext = createContext<
  HeaderProgressBarContextType | undefined
>(undefined);

export const HeaderProgressBarProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [progressBars, setProgressBars] = useState<ProgressBar[]>([]);

  const addOrUpdateProgressbar = useCallback((id: number, progress: number) => {
    setProgressBars((prevBars) => {
      const existingBarIndex = prevBars.findIndex((bar) => bar.id === id);

      if (existingBarIndex !== -1) {
        // Update the existing progress bar
        const updatedBars = [...prevBars];
        updatedBars[existingBarIndex] = {
          ...updatedBars[existingBarIndex],
          progress
        };
        return updatedBars;
      } else {
        // Add a new progress bar
        return [...prevBars, { id, progress }];
      }
    });

    // If progress reaches 100, wait for the transition to complete before removing
    if (progress === 100) {
      setTimeout(() => {
        setProgressBars((prevBars) => prevBars.filter((bar) => bar.id !== id));
      }, 500); // Wait for 300ms to allow for the transition animation
    }
  }, []);

  return (
    <HeaderProgressBarContext.Provider
      value={{ progressBars, addOrUpdateProgressbar }}
    >
      {children}
    </HeaderProgressBarContext.Provider>
  );
};

export const useHeaderProgressBar = (): HeaderProgressBarContextType => {
  const context = useContext(HeaderProgressBarContext);
  if (!context) {
    throw new Error(
      'useHeaderProgressBar must be used within a HeaderProgressBarProvider'
    );
  }
  return context;
};
