import { supabase } from '../../../supabase/supabaseClient';
import logger from '../../../../sharedPacakge/logger';

export async function getAllOrganisationWhereUserIsAdmin(
  userId: string
): Promise<{ id: number; name: string }[]> {
  // Fetch organisation IDs where the user is an OrganisationAdmin
  const { data: userRoles, error: userRolesError } = await supabase
    .from('user_roles')
    .select('organisation_id')
    .eq('user_id', userId);

  if (userRolesError) {
    logger.supabaseError(
      'getAllOrganisationWhereUserIsAdmin admin roles error',
      userRolesError
    );
    throw userRolesError;
  }

  const organisationIds = userRoles?.map((role) => role.organisation_id) || [];

  // Fetch the organisation names using the retrieved IDs
  const { data: organisations, error: orgNamesError } = await supabase
    .from('organisations')
    .select('id, name')
    .in('id', organisationIds);

  if (orgNamesError) {
    logger.supabaseError(
      'getAllOrganisationWhereUserIsAdmin organisation names error',
      orgNamesError
    );
    throw orgNamesError;
  }

  return organisations || [];
}
