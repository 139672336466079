import React, { FC, ReactNode } from 'react';
import styles from './guidingCard.module.css';

interface Props {
  tag: string;
  icon: ReactNode;
  title: string;
  description: () => ReactNode;
}

const GuidingCard: FC<Props> = ({ tag, icon, title, description }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.overLine__container}>
          <p className={styles.tag}>{tag}</p>
          <div className={styles.titleIcon__container}>
            <h3 className={styles.title}>{title}</h3>
            <div className={styles.icon__container}>{icon}</div>
          </div>
        </div>
        <div className={styles.white__line} />
        <div className={styles.description__container}>{description()}</div>
      </div>
    </div>
  );
};

export default GuidingCard;
