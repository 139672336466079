import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import FoundItemsTable from '../../../../components/FoundItemsTable/FoundItemTable';
import React, { FC, useEffect, useState } from 'react';
import {
  FoundItem,
  MatchRecord
} from '../../../../../../types/supabase/collections';
import { useLocationData } from '../../../../../../contexts/LocationDataContext/LocationDataContext';
import { ItemStatus } from '../../../../../../sharedPacakge/types/ItemStatus';
import { FoundItemArchiveReason } from '../../../../../../sharedPacakge/types';
import { Button } from '../../../../../../sharedPacakge/components/Button/Button';
import { archiveItems } from '../../../../../../servieces/Api/methods/put/archiveItems';
import { Gap } from '../../../../../../components/Gap/Gap';
import { Text } from '../../../../../../components/Text/Text';
import { ViewItemModalCallbacks } from '../../../../components/ViewItemModal/ViewItemModalCallbacks';
import styles from '../foundItemsTable.module.css';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import { Tooltip } from '@mui/material';

export const filterExpiredOrLeftBehindItems = (
  foundItems: FoundItem[],
  matchesData: MatchRecord[]
) =>
  foundItems.filter(
    (foundItem) =>
      foundItem.status === ItemStatus.Expired ||
      foundItem.status === ItemStatus.LeftBehind
  );

export const ExpiredOrLeftBehindTable: FC<{
  expiredOrLeftBehindItems: FoundItem[];
  callbacks: ViewItemModalCallbacks<FoundItem>;
}> = ({ expiredOrLeftBehindItems, callbacks }) => {
  const { location } = useLocationData();
  const [selectedRows, setSelectedRows] = useState<FoundItem[]>([]);

  if (!location) return null;

  const onArchiveItemsClick = async (
    e: any,
    reason: FoundItemArchiveReason
  ) => {
    e.preventDefault();

    await archiveItems({
      ids: selectedRows.map((row) => row.id),
      type: 'foundItems',
      archiveReason: reason
    });

    selectedRows.forEach((row) => {
      callbacks.onArchive && callbacks.onArchive(row.id);
    });

    setSelectedRows([]);
  };

  const onAutomaticClick = async (e: any) => {
    e.preventDefault();

    const highValueItems = selectedRows.filter(
      (item) => item.is_high_value_item
    );
    const lowValueItems = selectedRows.filter(
      (item) => !item.is_high_value_item
    );

    if (highValueItems.length > 0) {
      await archiveItems({
        ids: highValueItems.map((row) => row.id),
        type: 'foundItems',
        archiveReason: 'Handed to police'
      });
    }
    if (lowValueItems.length > 0) {
      await archiveItems({
        ids: lowValueItems.map((row) => row.id),
        type: 'foundItems',
        archiveReason: 'Donated'
      });
    }

    selectedRows.forEach((row) => {
      callbacks.onArchive && callbacks.onArchive(row.id);
    });

    setSelectedRows([]);
  };

  return (
    <>
      <Gap size={16} />
      <div style={{ display: 'flex', gap: 15 }}>
        <Button
          disabled={selectedRows.length === 0}
          onClick={(e) => onArchiveItemsClick(e, 'Handed to police')}
          buttonType="white"
          size="small"
        >
          <Text type="body-strong">Til Politi</Text>
        </Button>
        <Button
          disabled={selectedRows.length === 0}
          onClick={(e) => onArchiveItemsClick(e, 'Donated')}
          buttonType="white"
          size="small"
        >
          <Text type="body-strong">Til Donation</Text>
        </Button>
        <Tooltip title="Automatisk håndtering af alle genstande til Politiet eller Donation  baseret på værdi">
          <div>
            <Button
              disabled={selectedRows.length === 0}
              onClick={(e) => onAutomaticClick(e)}
              buttonType="white"
              size="small"
            >
              <Text type="body-strong">Automatisk</Text>
              <AutoFixHighOutlinedIcon />
            </Button>
          </div>
        </Tooltip>
      </div>
      <Gap size={16} />
      <FoundItemsTable
        foundItems={expiredOrLeftBehindItems}
        callbacks={callbacks}
        options={{
          columns: ['image', 'category', 'description'],
          customColumns: [
            {
              id: 'expiryReason',
              label: 'Årsag',
              render: (row) =>
                row?.status === ItemStatus.LeftBehind
                  ? 'blev aldrig afhentet'
                  : 'blev aldrig matchet'
            }
          ],
          renderNoFoundItems: () => (
            <span className={styles.noFoundItems__text}>
              <InventoryRoundedIcon />
              <p>Du er helt ajour.</p>
            </span>
          ),
          multiSelect: {
            enabled: true,
            onSelectedRowChange: (rows) => setSelectedRows(rows)
          }
        }}
      />
    </>
  );
};
