import { supabase } from '../../../supabase/supabaseClient';
import { Location } from '../../../../types/supabase/collections';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export async function getLocation(id: number): Promise<Location> {
  const { data, error } = await supabase
    .from('locations')
    .select('*')
    .eq('id', id)
    .single();

  assertNoSupabaseError(data, error, 'getLocation error:', { id }, true);

  return data;
}
