import {
  useLocationData,
  useSetLocationData
} from '../../../../../../contexts/LocationDataContext/LocationDataContext';
import { useSnackbar } from '../../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import React, { useEffect, useState } from 'react';
import Api from '../../../../../../servieces/Api/Api';
import styles from './storageAndFoundLocationOptions.module.css';
import { Button } from '../../../../../../sharedPacakge/components/Button/Button';
import { MultiChipInput } from '../../../../../../components/MultiChipInput/MultiChipInput';

export const StorageAndFoundLocationOptions = () => {
  const { location } = useLocationData();
  const setLocationData = useSetLocationData();
  const { showSnackbar } = useSnackbar();
  const [storageLocationOptions, setStorageLocationOptions] = useState<
    string[]
  >([]);
  const [foundAtLocationOptions, setFoundAtLocationOptions] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (location) {
      setStorageLocationOptions(location.storage_location_options);
      setFoundAtLocationOptions(location.found_at_location_options);
    }
  }, [location]);

  if (!location) return null;

  const onUpdateExpirySettingClick = (e: any) => {
    e.preventDefault();
    Api.put
      .location(location.id, {
        storage_location_options: storageLocationOptions,
        found_at_location_options: foundAtLocationOptions
      })
      .then(() => {
        setLocationData((prevState) => {
          if (!prevState.isLoading && prevState.location) {
            return {
              ...prevState,
              location: {
                ...prevState.location,
                storage_location_options: storageLocationOptions,
                found_at_location_options: foundAtLocationOptions
              }
            };
          }

          return prevState;
        });
        showSnackbar('udløbsdatoer blev opdateret');
      })
      .catch(() => showSnackbar('Der skete en fejl', 'error'));
  };
  return (
    <form onSubmit={onUpdateExpirySettingClick} className={styles.form}>
      <MultiChipInput
        label="Valgmuligheder for: Opbevaringssted"
        value={storageLocationOptions}
        onChange={(newValue) => setStorageLocationOptions(newValue)}
      />
      <MultiChipInput
        label="Valgmuligheder for: Fundet ved"
        value={foundAtLocationOptions}
        onChange={(newValue) => setFoundAtLocationOptions(newValue)}
      />
      <Button type="submit">Opdater</Button>
    </form>
  );
};
