import React, { FC } from 'react';
import styles from './FoundItemsTable.module.css';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { FoundItem } from '../../../../../../types/supabase/collections';
import FoundItemsTable from '../../../../components/FoundItemsTable/FoundItemTable';
import { ViewItemModalCallbacks } from '../../../../components/ViewItemModal/ViewItemModalCallbacks';
import { PostFoundItemItem } from '../../../../../../contexts/FoundItemPosterContext/PostFoundItemJobsContext';

type FoundItemAndPostFoundItem = FoundItem | PostFoundItemItem;

export const AllFoundItemsTable: FC<{
  foundItems: Array<FoundItemAndPostFoundItem>;
  callbacks: ViewItemModalCallbacks<FoundItem>;
}> = ({ foundItems, callbacks }) => {
  const renderNoFoundItems = () => (
    <>
      <h2 className={styles.noItems__text}>Ingen fundne genstande endnu</h2>
      <p
        style={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '5px'
        }}
      >
        Opret fundne genstande
        <PostAddIcon />
      </p>
    </>
  );

  return (
    <FoundItemsTable<FoundItemAndPostFoundItem>
      foundItems={foundItems}
      callbacks={callbacks as any}
      options={{
        renderNoFoundItems: renderNoFoundItems,
        columns: [
          'image',
          'category',
          'description',
          'found_at_date',
          'id',
          'status'
        ]
      }}
    />
  );
};
