import { supabase } from '../../../supabase/supabaseClient';
import {
  FoundItem,
  Inquiry,
  MatchRecord
} from '../../../../types/supabase/collections';
import { getInquiries } from './getInquiries';
import { getFoundItem } from './getFoundItem';
import logger from '../../../../sharedPacakge/logger';

interface MatchmakingData {
  foundItem: FoundItem;
  potentialMatches: Array<{
    matchData: MatchRecord;
    inquiry: Inquiry;
  }>;
}

export async function getMatchmakingData(args: {
  foundItemId: number;
}): Promise<MatchmakingData> {
  const { foundItemId } = args;

  const foundItem = await getFoundItem(foundItemId);

  const { data: possibleMatches, error: possibleMatchesError } = await supabase
    .from('matches')
    .select('*')
    .eq('match_rejected', false)
    .eq('match_confirmed', false)
    .eq('found_item_id', foundItemId);

  if (possibleMatchesError) {
    logger.supabaseError(
      'getMatchmakingData possibleMatchesError',
      possibleMatchesError
    );
    throw possibleMatchesError;
  }

  const possibleMatchesIds = possibleMatches?.map((match) => match.inquiry_id);

  const inquiries = await getInquiries({
    location_id: foundItem.location_id,
    customQuery: (query) =>
      query
        .in('id', possibleMatchesIds)
        .order('lost_at_date', { ascending: false })
  });

  if (!inquiries) {
    logger.error(`Issue getActionsPageData: ${inquiries}`);
    throw new Error(`Issue getActionsPageData: ${inquiries}`);
  }

  const potentialMatches = inquiries
    .map((inquiry) => {
      const matchData = possibleMatches?.find(
        (match) => match.inquiry_id === inquiry.id
      ) as MatchRecord;

      return {
        matchData: matchData, // Provide a default if matchData is undefined
        inquiry: inquiry
      };
    })
    .sort(
      (a, b) =>
        (b.matchData?.probability ?? 0) - (a.matchData?.probability ?? 0)
    );

  return {
    foundItem,
    potentialMatches
  };
}
