import React, { ReactNode } from 'react';
import styles from './tabs.module.css';

interface Tab<T> {
  label: T;
  suffix?: ReactNode;
}

interface TabComponentProps<T extends string> {
  tabs: Tab<T>[];
  selectedTabIndex: number; // Should match the type of label
  onTabClick: (index: number) => void;
}

const Tabs = <T extends string>({
  tabs,
  selectedTabIndex,
  onTabClick
}: TabComponentProps<T>) => {
  return (
    <div className={styles.tabContainer}>
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={`${styles.tab} ${index === selectedTabIndex ? styles.selected : ''}`}
          onClick={(e: any) => {
            e.preventDefault();
            onTabClick(index);
          }}
        >
          {tab.label}
          {tab.suffix && <span className={styles.suffix}>{tab.suffix}</span>}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
