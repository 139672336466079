import React, { FC, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './galleryImage.module.css';

const GalleryImage: FC<{ image?: string }> = ({ image }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = (e: any) => {
    e.preventDefault();
    setIsFullScreen((prev) => !prev);
  };

  // Render full screen image in a portal if `isFullScreen` is true
  const fullScreenImage = isFullScreen ? (
    <div className={styles.fullScreenContainer}>
      <img className={styles.fullImage} src={image} alt="billede" />
      <div className={styles.closeButton} onClick={toggleFullScreen}>
        X
      </div>
    </div>
  ) : null;

  const portal = document.getElementById('gallery-root');
  if (!portal) {
    console.warn("Snackbar can't open, there is no snackbar root in the HTML");
    return null;
  }

  return (
    <div className={styles.container}>
      {image ? (
        <>
          <img
            className={styles.thumbnail}
            src={image}
            alt="billede"
            onClick={toggleFullScreen}
          />
          {isFullScreen &&
            ReactDOM.createPortal(fullScreenImage, document.body)}
        </>
      ) : (
        <p>(Intet billede vedhæftet)</p>
      )}
    </div>
  );
};

export default GalleryImage;
