import React, { FC, useState } from 'react';
import { TextField } from '@mui/material';
import styles from './newLocationButton.module.css';
import { useLocationData } from '../../../../../../../../contexts/LocationDataContext/LocationDataContext';
import Api from '../../../../../../../../servieces/Api/Api';
import { useAuth } from '../../../../../../../../contexts/AuthContext/AuthContext';
import { SetState } from '../../../../../../../../sharedPacakge/types/utilityTypes';
import { useSnackbar } from '../../../../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { FormModal } from '../../../../../../../../sharedPacakge/components/Modal/FormModal/FormModal';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';

interface Props {
  onLocationCreatedCallback: () => void;
  setIsOpen: SetState<boolean>;
}

const NewLocationModal: FC<Props> = ({
  onLocationCreatedCallback,
  setIsOpen
}) => {
  const { showSnackbar } = useSnackbar();
  const { user } = useAuth();
  const { organisation } = useLocationData();
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (!organisation) return null;

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (name === '') return showSnackbar('navn er tomt', 'error');
    if (!user || !organisation) return;

    setIsLoading(true);
    Api.post
      .location({
        organisationId: organisation.id,
        locationName: name
      })
      .then(() => {
        showSnackbar(`Lokation (${name}) oprettet`);
        setIsLoading(false);
        setIsOpen(false);
        onLocationCreatedCallback();
      })
      .catch(() => setIsLoading(false));
  };

  const onNameChange = (e: any) => {
    setName(e.target.value);
  };

  return (
    <FormModal
      onFormSubmit={onSubmit}
      title="Opret ny lokation"
      setIsOpen={setIsOpen}
      submitButtonText="Udfør"
      disabled={isLoading}
    >
      <div className={styles.textfieldContainer}>
        <TextField
          fullWidth
          id="outlined-basic"
          label="Lokation navn"
          variant="outlined"
          onChange={onNameChange}
          InputProps={{
            endAdornment: (
              <FmdGoodOutlinedIcon style={{ width: 22, height: 22 }} />
            )
          }}
        />
        <p className={styles.subtitle}>
          Lokation oprettes under: {organisation.name}
        </p>
      </div>
    </FormModal>
  );
};

export default NewLocationModal;
