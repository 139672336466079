export const daysUntilDate = (targetDate: Date): number => {
  const today = new Date();

  const differenceInMs = targetDate.getTime() - today.getTime();

  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const days = Math.ceil(differenceInMs / millisecondsPerDay);

  return Math.max(days, 0);
};

export const daysUntilDateText = (
  isoDate: string,
  format: 'short' | 'long' = 'short'
) => {
  const daysUntil = daysUntilDate(new Date(isoDate));
  return daysUntil === 0
    ? 'idag'
    : `${daysUntilDate(new Date(isoDate))} ${format === 'short' ? 'd' : 'dage'}`;
};
