import React, { FC } from 'react';
import styles from './formModal.module.css';
import { Modal, ModalProps } from '../Modal';
import { Button } from '../../Button/Button';

interface FormModalProps extends ModalProps {
  onFormSubmit: (e: any) => void;
  submitButtonText: string;
  buttonType?: 'primary' | 'secondary' | 'warn';
  disabled?: boolean;
}

export const FormModal: FC<FormModalProps> = ({
  onFormSubmit,
  submitButtonText,
  disabled,
  buttonType = 'primary',
  ...props
}) => {
  return (
    <Modal {...props}>
      <form onSubmit={onFormSubmit} className={styles.form}>
        {props.children}
        <Button
          className={styles.submitButton}
          type="submit"
          disabled={disabled}
          buttonType={buttonType}
        >
          {submitButtonText}
        </Button>
      </form>
    </Modal>
  );
};
