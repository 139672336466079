import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import ReactDOM from 'react-dom';
import styles from './Modal.module.css';
import { Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Text } from '../../../components/Text/Text';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import { SetState } from '../../types/utilityTypes';

interface NavigationState {
  title: string;
  content: ReactNode;
  onBack: () => void;
  onSubmit: () => void;
}

export interface ModalProps {
  title: string;
  children: ReactNode;
  setIsOpen?: SetState<boolean>;
  onClose?: () => void;
  className?: string;
}

interface ModalNavigationContextType {
  navigation: NavigationState | null;
  setNavigation: SetState<NavigationState | null>;
}

const ModalNavigationContext = createContext<
  ModalNavigationContextType | undefined
>(undefined);

export const Modal: FC<ModalProps> = ({
  title,
  children,
  setIsOpen,
  onClose,
  className
}) => {
  const [navigation, setNavigation] = useState<NavigationState | null>(null);
  const [stagedNavigation, setStagedNavigation] =
    useState<NavigationState | null>(null);
  const [isSlidingIn, setIsSlidingIn] = useState(false);
  const [isSlidingOut, setIsSlidingOut] = useState(false);
  const [previousContent, setPreviousContent] = useState<ReactNode>(children);
  const [initialLoad, setInitialLoad] = useState(true); // Flag to skip animation on modal open
  const [fixedHeight, setFixedHeight] = useState<number | undefined>(undefined); // State to hold fixed height
  const contentContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (navigation && contentContainerRef.current) {
      const newHeight = contentContainerRef.current.clientHeight;
      if (newHeight !== fixedHeight) {
        console.log('newHeight', newHeight);
        setFixedHeight(newHeight);
      }
    } else {
      setFixedHeight(undefined);
    }
  }, [navigation]);

  useEffect(() => {
    const closeModalOnEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsOpen && setIsOpen(false);
        onClose && onClose();
      }
    };

    document.body.style.overflow = 'hidden';
    window.addEventListener('keydown', closeModalOnEscape);

    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, [setIsOpen, onClose]);

  // Detect change in navigation to trigger slide-in and slide-out animations
  useEffect(() => {
    // Skip animation on initial load and when clearing navigation
    if (initialLoad) {
      setStagedNavigation(null);
      setPreviousContent(children);
      setInitialLoad(false); // Set initial load to false after first render
      return;
    }

    if (navigation) {
      // Only animate when setting a new navigation content state, not when clearing it
      setIsSlidingOut(true);

      setTimeout(() => {
        setPreviousContent(navigation.content);
        setStagedNavigation(navigation);
        setIsSlidingOut(false);

        // Trigger slide-in after slide-out completes
        setTimeout(() => {
          setIsSlidingIn(true);
        }, 10);
      }, 300);
    } else {
      // Clear navigation without animation
      setStagedNavigation(null);
      setPreviousContent(children);
    }
  }, [navigation?.content, children, initialLoad]);

  const portal = document.getElementById('modal-root');

  if (!portal) {
    console.warn("Modal can't open, there is no modal root in the HTML");
    return null;
  }

  const clickOutside = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setIsOpen && setIsOpen(false);
      onClose && onClose();
    }
  };

  const onBackClick = (e: any) => {
    e.preventDefault();
    navigation!.onBack();
    // todo animate slide out right
  };
  const onSubmitClick = (e: any) => {
    e.preventDefault();
    navigation!.onSubmit();
    // todo animate slide out left again
  };
  const onCloseClick = (e: any) => {
    e.preventDefault();
    setIsOpen && setIsOpen(false);
    onClose && onClose();
  };

  return ReactDOM.createPortal(
    <div className={styles.background} onClick={clickOutside}>
      <div
        className={`${styles.modal} ${className ?? ''}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.modalHeader}>
          <div className={styles.modalHeader__side}>
            <div>
              {navigation ? (
                <ArrowBackIcon
                  className={styles.iconButton}
                  onClick={onBackClick}
                />
              ) : (
                <CloseIcon
                  className={styles.iconButton}
                  onClick={onCloseClick}
                />
              )}
            </div>
            <Text type="body-strong">
              {navigation ? navigation.title : title}
            </Text>
          </div>
          <div className={styles.modalHeader__side}>
            {navigation && (
              <CheckIcon
                className={styles.iconButton}
                onClick={onSubmitClick}
              />
            )}
          </div>
        </div>
        <Divider />
        <div
          className={
            isSlidingOut ? styles.slideOut : isSlidingIn ? styles.slideIn : ''
          }
          onAnimationEnd={() => {
            if (isSlidingIn) setIsSlidingIn(false);
          }}
          style={{ height: fixedHeight ? `${fixedHeight}px` : 'auto' }} // Apply fixed height if defined
          ref={contentContainerRef}
        >
          <ModalNavigationContext.Provider
            value={{ navigation, setNavigation }}
          >
            <div className={styles.contentContainer}>
              {isSlidingOut
                ? previousContent
                : stagedNavigation
                  ? stagedNavigation.content
                  : children}
            </div>
          </ModalNavigationContext.Provider>
        </div>
      </div>
    </div>,
    portal
  );
};

export const useModalNavigation = () => {
  const context = useContext(ModalNavigationContext);
  if (!context) {
    throw new Error(
      'useModalNavigation must be used within a ModalNavigationProvider'
    );
  }
  return context;
};
