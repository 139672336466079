import {InputAdornment, TextField} from "@mui/material";
import React, {FC} from "react";

interface Props {
    label: string;
    value: string | null | undefined;
    onChange:  (newValue: string) => void
    disabled?: boolean
}
export const PxInputField: FC<Props> = ({label, value, onChange, disabled}) => <div>
    <small>{label}</small>
    <TextField
        disabled={disabled}
        value={Number(value?.replace("px", ""))}
        type="number"
        onChange={(e: any) => onChange(`${e.target.value}px`)}
        fullWidth
        variant="outlined"
        InputProps={{
            endAdornment: <InputAdornment position="end">px</InputAdornment>,
        }}
    />
</div>