import React, { useState } from "react";
import styles from "./infoButton.module.css";
import { IconButton } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import InfoModal from "./InfoModal";

const InviteAdminsButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onModalClick = () => {
    setIsOpen(true);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls={isOpen ? "long-menu" : undefined}
        aria-expanded={isOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={onModalClick}
        style={{ height: 40, width: 40 }}
      >
        <HelpOutlineOutlinedIcon
          onClick={onModalClick}
          className={styles.iconButton}
        />
      </IconButton>
      {isOpen && <InfoModal setIsOpen={setIsOpen} />}
    </>
  );
};

export default InviteAdminsButton;
