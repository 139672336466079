import React, { ReactNode, useEffect, useState } from 'react';
import { ListItemIcon } from '@mui/material';
import { Role } from '../../../../../../types/supabase/collections';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Api from '../../../../../../servieces/Api/Api';
import { useLocationData } from '../../../../../../contexts/LocationDataContext/LocationDataContext';
import { useAuth } from '../../../../../../contexts/AuthContext/AuthContext';
import styles from './locationsOverview.module.css';
import { Button } from '../../../../../../sharedPacakge/components/Button/Button';
import DeleteLocationButton from './components/DeleteLocationButton/DeleteLocationButton';
import NewLocationModal from './components/NewLocationButton/NewLocationModal';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';

export const LocationsOverview = () => {
  const {
    organisation,
    location: currentLocation,
    userRole
  } = useLocationData();
  const { user } = useAuth();
  const [allLocation, setAllLocations] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);
  const [newLocationIsOpen, setNewLocationIsOpen] = useState(false);

  const fetchLocations = () => {
    if (!organisation || !user) return;
    Api.get
      .allLocationsWhereUserIsAdminWithingOrganisation({
        userId: user.id,
        organisationId: organisation?.id
      })
      .then((response) => {
        setAllLocations(response);
      });
  };

  useEffect(() => {
    fetchLocations();
  }, [user, organisation]);

  const options: { id: number; name: string; icon: ReactNode }[] =
    allLocation.map((location) => ({
      ...location,
      icon: <FmdGoodOutlinedIcon style={{ width: 18, height: 18 }} />
    }));

  const onNewLocationClick = (e: any) => {
    e.preventDefault();
    setNewLocationIsOpen(true);
  };
  return (
    <>
      {options.map((option, index) => (
        <div key={index} className={styles.menuItem}>
          <div>
            {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
            {option.name}
            {currentLocation?.id === option.id && (
              <span className={styles.selectedLocation__text}>(Nuværende)</span>
            )}
          </div>
          {userRole === Role.OrganisationAdmin && (
            <DeleteLocationButton
              location={{ id: option.id, name: option.name }}
              onLocationDeletedCallback={fetchLocations}
            />
          )}
        </div>
      ))}

      {userRole === Role.OrganisationAdmin && (
        <Button
          onClick={onNewLocationClick}
          className={styles.addLocation__button}
          fullWidth
        >
          <AddOutlinedIcon /> Ny lokation
        </Button>
      )}
      {newLocationIsOpen && (
        <NewLocationModal
          setIsOpen={setNewLocationIsOpen}
          onLocationCreatedCallback={fetchLocations}
        />
      )}
    </>
  );
};
