import { supabase } from '../../../supabase/supabaseClient';
import { Role } from '../../../../types/supabase/collections';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export async function getAnyLocationWhereUserIsAdmin(
  userId: string
): Promise<number | null> {
  // If no organisation admin role, check if the user is an admin of any location
  const { data: userRoleData, error: userRoleError } = await supabase
    .from('user_roles')
    .select('location_id')
    .eq('user_id', userId)
    .limit(1)
    .single();

  assertNoSupabaseError(
    userRoleData,
    userRoleError,
    'getAnyLocationWhereUserIsAdmin userRoleError:',
    { userId, role: Role.LocationAdmin }
  );

  if (userRoleData && userRoleData.location_id) {
    return userRoleData.location_id;
  }

  // Check if the user is an admin of any organisation
  const { data: organisation, error: orgError } = await supabase
    .from('user_roles')
    .select('organisation_id')
    .eq('user_id', userId)
    .eq('role', Role.OrganisationAdmin)
    .limit(1)
    .single();

  assertNoSupabaseError(
    organisation,
    orgError,
    'getAnyLocationWhereUserIsAdmin orgError:',
    { userId, role: Role.OrganisationAdmin }
  );

  if (organisation?.organisation_id) {
    // Fetch a random location_id within the organisation
    const { data: location, error: locError } = await supabase
      .from('locations')
      .select('id')
      .eq('organisation_id', organisation.organisation_id)
      .limit(1) // Limit to one result to select randomly
      .single();

    assertNoSupabaseError(
      location,
      locError,
      'getAnyLocationWhereUserIsAdmin locError:',
      { organisation },
      true
    );

    return location.id ?? null;
  }
  return null;
}
