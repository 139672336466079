import { FC } from 'react';
import { AgCharts } from 'ag-charts-react';
import { AgCartesianChartOptions } from 'ag-charts-community';
import dayjs from 'dayjs';

interface Props {
  dateStrings: string[]; // Accept an array of date strings
  startDate: Date; // The start date for filtering data
}

export const UserUsageGraph: FC<Props> = ({ dateStrings, startDate }) => {
  const getAggregatedData = () => {
    const orgDateCountMap: Record<string, number> = {}; // Map for counting occurrences by date

    const start = dayjs(startDate).startOf('day');
    const end = dayjs(); // Current day

    // Count occurrences of each date
    dateStrings.forEach((dateString) => {
      const createdDate = dayjs(dateString);

      // Filter dates after start date
      if (createdDate.isAfter(start) && createdDate.isBefore(end)) {
        const date = createdDate.format('YYYY-MM-DD');
        orgDateCountMap[date] = (orgDateCountMap[date] || 0) + 1; // Count occurrences
      }
    });

    // Create an array of dates from startDate to today
    const allDates: Array<Record<string, any>> = [];
    let currentDate = start.clone();

    while (currentDate.isBefore(end) || currentDate.isSame(end, 'day')) {
      const dateKey = currentDate.format('YYYY-MM-DD');
      const orgData: Record<string, any> = { date: currentDate.toDate() }; // Initialize with the current date

      // Add counts for the current date
      orgData['count'] = orgDateCountMap[dateKey] || 0; // Set count for the current date

      allDates.push(orgData);
      currentDate = currentDate.add(1, 'day');
    }

    return allDates; // Return all dates with counts
  };

  const chartData = getAggregatedData();

  if (!chartData.length) return null;

  const chartOptions: AgCartesianChartOptions = {
    data: chartData,
    series: [
      {
        type: 'line',
        xKey: 'date',
        yKey: 'count', // Use count for the y-axis
        yName: `date`,
        marker: { enabled: false }
      }
    ],
    axes: [
      {
        type: 'time',
        position: 'bottom',
        title: { text: 'Date' }
      },
      {
        type: 'number',
        position: 'left',
        title: { text: `Count of sessions` }
      }
    ],
    theme: {
      overrides: {
        line: {
          series: {
            interpolation: {
              type: 'smooth'
            },
            tooltip: {
              renderer: ({ title, datum, xKey, yKey }) => ({
                title,
                content: `${datum[yKey]} | ${formatDate(datum[xKey])}`
              })
            }
          }
        }
      }
    },
    legend: { enabled: true }
  };

  return (
    <div style={{ width: '100%' }}>
      <AgCharts options={chartOptions} />
    </div>
  );
};

export function formatDate(isoDate: string): string {
  const date = new Date(isoDate);
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  };
  const formatter = new Intl.DateTimeFormat('da-DK', options);
  const parts = formatter.formatToParts(date);

  const weekday = parts.find((part) => part.type === 'weekday')?.value;
  const day = parts.find((part) => part.type === 'day')?.value;
  const month = parts.find((part) => part.type === 'month')?.value;
  const year = parts.find((part) => part.type === 'year')?.value;

  return `${weekday} d. ${day} ${month} ${year}`;
}
