import { supabase } from '../../../supabase/supabaseClient';
import { Role } from '../../../../types/supabase/collections';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';
import { superUserEmail } from '../../../../utils/constants';

export type AdminOrInvite =
  | {
      id: number;
      userId: string;
      email: string;
      fullName: string;
      isUserInvite: false;
    }
  | {
      id: number;
      email: string;
      fullName: string;
      isUserInvite: true;
      expiryDate: string;
    };

export async function getAdminsAndInvites(args: {
  getUsersWithRole: Role;
  locationId: number;
  organisationId: number;
}): Promise<Array<AdminOrInvite>> {
  const { getUsersWithRole, locationId, organisationId } = args;

  const { data: userRoles, error: userRolesError } = await supabase
    .from('user_roles')
    .select('id, user_id')
    .match({
      ...(getUsersWithRole === Role.LocationAdmin
        ? { location_id: locationId }
        : { organisation_id: organisationId }),
      role: getUsersWithRole
    });

  assertNoSupabaseError(
    userRoles,
    userRolesError,
    'getAllAdmins userRolesError:',
    {
      getUsersWithRole,
      organisationId
    }
  );

  let allAdmins: AdminOrInvite[] = [];
  if (userRoles) {
    const userIdList = userRoles.map((user) => user.user_id);

    const { data: users, error: usersError } = await supabase
      .from('user_profiles')
      .select('id, email, full_name')
      .in('id', userIdList);

    assertNoSupabaseError(users, usersError, 'getAllAdmins userRolesError:');

    if (users) {
      allAdmins = users.map((user) => ({
        id: userRoles.find((role) => role.user_id === user.id)!.id,
        userId: user.id,
        email: user.email,
        fullName: user.full_name,
        isUserInvite: false
      }));
    }
  }

  const { data: userInvites, error: userInvitesError } = await supabase
    .from('user_invites')
    .select('id, email, expiry_date')
    .match({
      ...(getUsersWithRole === Role.LocationAdmin
        ? { location_id: locationId }
        : { organisation_id: organisationId }),
      role: getUsersWithRole
    })
    .gte('expiry_date', new Date().toISOString());

  assertNoSupabaseError(
    userInvites,
    userInvitesError,
    'getAllAdmins userInvitesError:'
  );

  let allUserInvites: AdminOrInvite[] = [];
  if (userInvites) {
    allUserInvites = userInvites.map((invite) => ({
      id: invite.id,
      email: invite.email,
      fullName: invite.email.split('@')[0],
      isUserInvite: true,
      expiryDate: invite.expiry_date
    }));
  }

  const adminListExceptSuperUser = [...allAdmins, ...allUserInvites].filter(
    (admin) => admin.email !== superUserEmail
  );

  return adminListExceptSuperUser;
}
