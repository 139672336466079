import React, { CSSProperties, FC, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField } from '@mui/material';
import styles from './deleteLocationButton.module.css';
import { useLocationData } from '../../../../../../../../contexts/LocationDataContext/LocationDataContext';
import { EllipsisMenu } from '../../../../../../../../components/EllipsisMenu/EllipsisMenu';
import Api from '../../../../../../../../servieces/Api/Api';
import { useAuth } from '../../../../../../../../contexts/AuthContext/AuthContext';
import Page from '../../../../../../../Page';
import { useNavigate } from 'react-router-dom';
import { FormModal } from '../../../../../../../../sharedPacakge/components/Modal/FormModal/FormModal';
import { useSnackbar } from '../../../../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';

interface Props {
  location: { id: number; name: string };
  style?: CSSProperties;
  onLocationDeletedCallback: () => void;
}
const DeleteLocationButton: FC<Props> = ({
  location,
  onLocationDeletedCallback,
  style
}) => {
  const { showSnackbar } = useSnackbar();
  const { user } = useAuth();
  const { organisation } = useLocationData();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [name, setName] = useState('');
  const navigate = useNavigate();

  if (!location) return null;

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (name !== location.name) {
      alert('forkert navn på lokation');
      return;
    }

    if (!organisation || !user)
      return console.error('missing boxId or orgId to delete');

    Api.delete
      .location({
        locationId: location.id,
        organisationId: organisation.id
      })
      .then((response) => {
        if (!response.success && response.errorMessage) {
          return showSnackbar(response.errorMessage, 'error');
        }

        setIsOpen(false);
        onLocationDeletedCallback();
        Api.get
          .anyLocationIdFromOrganisation({
            userId: user.id,
            organisationId: organisation.id
          })
          .then((response) => {
            navigate(Page.l.foundItems(response.locationId));
          });
      });
  };

  const onNameChange = (e: any) => {
    setName(e.target.value);
  };

  return (
    <>
      <EllipsisMenu
        options={[
          {
            label: 'Slet',
            icon: <DeleteIcon />,
            onClick: () => setIsOpen(true)
          }
        ]}
      />
      {isOpen && (
        <FormModal
          onFormSubmit={onSubmit}
          title="Slet lokation"
          setIsOpen={setIsOpen}
          buttonType="warn"
          submitButtonText="Slet"
        >
          <div className={styles.textfieldContainer}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Bekræft navn"
              placeholder={location.name}
              variant="outlined"
              onChange={onNameChange}
              InputProps={{
                endAdornment: (
                  <FmdGoodOutlinedIcon style={{ width: 22, height: 22 }} />
                )
              }}
            />
            <p className={styles.subtitle}>
              Skriv &quot;{location.name}&quot; for at bekræfte at du vil slette
              lokationen
            </p>
          </div>
        </FormModal>
      )}
    </>
  );
};

export default DeleteLocationButton;
