import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import styles from './matchesBatch.module.css';
import { FC } from 'react';
export const MatchesBatch: FC<{ amount: number }> = ({ amount }) => {
  if (amount === 0) return null;

  return (
    <div className={styles.batch__container}>
      {amount} <AccessTimeOutlinedIcon />
    </div>
  );
};
