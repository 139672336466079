import React, { FC } from 'react';
import { AgCharts } from 'ag-charts-react';
import { FindyInternalInsightsResponse } from '../InternalInsightsPage';
import {
  ArchivedFoundItemRecord,
  ArchivedInquiryRecord
} from '../../../types/supabase/collections';
import { InquiryArchiveReason } from '../../../sharedPacakge/types';

interface Props {
  data: FindyInternalInsightsResponse;
  type: 'foundItems' | 'inquiries';
}

// Archive reasons
const archiveReasons: InquiryArchiveReason[] = [
  'Picked up by owner',
  'Donated',
  'Kept',
  'Handed to police',
  '(manually archived) Physical inquiry & immediate pickup',
  '(manually archived) Donated',
  '(manually archived) Kept',
  '(manually archived) Handed to police',
  'never matched'
];

export const ArchiveReasonBarChart: FC<Props> = ({ data, type }) => {
  return (
    <AgCharts
      options={{
        title: {
          text: `Archived ${type} with reasons`
        },
        data: data.organisations.map((organisation) => {
          const id = organisation.id;
          const name = organisation.name; // Assuming 'name' exists in the organisation object
          const label = `${name} (ID: ${id})`; // Combine name and ID for display

          const record = organisation[type] as (
            | ArchivedFoundItemRecord
            | ArchivedInquiryRecord
          )[]; // Type assertion

          const archivedCounts = archiveReasons.reduce(
            (acc, reason) => {
              acc[reason] = record.filter(
                (item) =>
                  'archive_reason' in item && item.archive_reason === reason
              ).length;
              return acc;
            },
            {} as Record<string, number>
          );

          return {
            organisation_label: label, // Use combined label as x-axis value
            organisation_id: id,
            ...archivedCounts // Spread the archived counts into the object
          };
        }),
        series: archiveReasons.map((reason) => ({
          type: 'bar',
          xKey: 'organisation_label', // Use the combined label as the xKey
          yKey: reason,
          yName: reason
        }))
      }}
    />
  );
};
