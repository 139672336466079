import { supabase } from '../../../supabase/supabaseClient';
import { InquiryEmailTemplate } from '../../../../types/supabase/collections';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';

export const upsertInquiryEmailTemplate = async (
  emailTemplate: Omit<InquiryEmailTemplate, 'id'>
): Promise<void> => {
  // Step 1: Fetch the existing row
  const { data: existingTemplates, error: fetchError } = await supabase
    .from('inquiry_email_templates')
    .select('*')
    .eq('organisation_id', emailTemplate.organisation_id)
    .eq('inquiry_email_type', emailTemplate.inquiry_email_type)
    .single(); // Fetch a single record

  assertNoSupabaseError({}, fetchError, 'Fetch existing email template error');

  // Step 2: Determine whether to update or insert
  let upsertError;
  if (existingTemplates) {
    const { error: updateError } = await supabase
      .from('inquiry_email_templates')
      .update(emailTemplate)
      .eq('id', existingTemplates.id);
    upsertError = updateError;
  } else {
    const { error: insertError } = await supabase
      .from('inquiry_email_templates')
      .insert(emailTemplate);
    upsertError = insertError;
  }

  // Check for errors during upsert
  assertNoSupabaseError({}, upsertError, 'Upsert failed');
};
