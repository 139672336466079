export enum CookieName {
  EnabledFoundItemCreationImageDetection = 'enabledFoundItemCreationImageDetection'
}

export function setCookie(
  name: CookieName,
  value: any,
  hours: number = 0,
  type: 'secure' | 'regular' = 'regular'
) {
  let expires = '';
  if (hours) {
    const date = new Date();
    if (hours === Infinity) {
      // Set the expiration date to 100 years in the future
      date.setFullYear(date.getFullYear() + 100);
    } else {
      date.setTime(date.getTime() + hours * 60 * 60 * 1000);
    }
    expires = `; expires=${date.toUTCString()}`;
  }

  const secureAttributes = type === 'secure' ? '; Secure; SameSite=Strict' : ''; // Todo: add "HttpOnly", so that the cookie only can be changed from the server side (but i can do this once i make a fully server side sign in system)
  document.cookie = `${name}=${encodeURIComponent(value || '')}${expires}; path=/${secureAttributes}`;
}

export function getBooleanCookie(name: CookieName): boolean | undefined {
  const cookies = document.cookie.split('; ');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split('=');
    if (cookie[0] === name) {
      return decodeURIComponent(cookie[1]) === 'true';
    }
  }

  return undefined; // Cookie not found
}
