import { supabase } from '../../../supabase/supabaseClient';
import { assertNoSupabaseError } from '../../../supabase/supabaseErrorHandling';
import { Database } from '../../../../types/supabase/supabaseDB';

export async function postMatch(
  input: Database['public']['Tables']['matches']['Insert']
): Promise<void> {
  const { error } = await supabase.from('matches').insert(input);

  assertNoSupabaseError(input, error, 'postMatch');
}
