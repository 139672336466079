import React, { FC } from 'react';
import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styles from './ellipsisMenu.module.css';

interface Option {
  label: string;
  icon?: React.ReactNode; // Allow any React node, including MUI icons
  onClick: () => void;
  color?: 'default' | 'negative';
}

interface Props {
  options: Option[];
}

export const EllipsisMenu: FC<Props> = ({ options }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (e: any, onClick: () => void) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem
            className={`${option.color === 'negative' ? styles.menuItem__negative : ''}`}
            key={index}
            onClick={(e) => handleMenuItemClick(e, option.onClick)} // Use custom click handler
          >
            {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
