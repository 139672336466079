import React, { useState } from 'react';
import { Outlet as Content } from 'react-router-dom';
import styles from './locationPage.module.css';
import { Header } from './components/Header/Header';
import { useIsMobile } from '../../utils/hooks/useIsMobile';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Drawer } from '../../components/Drawer/Drawer';
import { Sidebar } from './components/Sidebar/Sidebar';
import { LocationDataProvider } from '../../contexts/LocationDataContext/LocationDataContext';
import { EventsProvider } from '../../contexts/EventsProvider.ts/EventsProvider';
import { HeaderProgressBarProvider } from '../../contexts/HeaderProgressBarContext/ProgressBarContext';
import { FoundItemPosterProvider } from '../../contexts/FoundItemPosterContext/PostFoundItemJobsContext';

const LocationPage = () => {
  const isMobile = useIsMobile();
  const [navDrawerIsOpen, setNavDrawerIsOpen] = useState<boolean>(false);

  const onOpenNavDrawerClick = (e: any) => {
    e.preventDefault();
    setNavDrawerIsOpen(true);
  };

  return (
    <EventsProvider>
      <LocationDataProvider>
        <HeaderProgressBarProvider>
          <FoundItemPosterProvider>
            <div className={styles.pageContainer}>
              <div className={styles.header__container}>
                <Header
                  navDrawerButton={
                    isMobile
                      ? () => <MenuRoundedIcon onClick={onOpenNavDrawerClick} />
                      : undefined
                  }
                />
              </div>
              <div className={styles.contentCotnaienr}>
                {isMobile ? (
                  navDrawerIsOpen && (
                    <Drawer setIsOpen={setNavDrawerIsOpen}>
                      <Sidebar
                        closeNavbarMobile={() => setNavDrawerIsOpen(false)}
                      />
                    </Drawer>
                  )
                ) : (
                  <div className={styles.sidebarContainer}>
                    <Sidebar />
                  </div>
                )}
                <div className={styles.tab}>
                  <Content />
                </div>
              </div>
            </div>
          </FoundItemPosterProvider>
        </HeaderProgressBarProvider>
      </LocationDataProvider>
    </EventsProvider>
  );
};

export default LocationPage;
