import React, { ReactElement, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Api from '../../servieces/Api/Api';
import Page from '../../pages/Page';
import { LoadingSpinner } from '../../sharedPacakge/components/LoadingSpinner/LoadingSpinner';

const NoAuthOnlyhRoute: React.FC<{ element: ReactElement }> = ({ element }) => {
  const { user, loading: loadingAuth } = useAuth();
  const [redirectUrl, setRedirectUrl] = useState<string>('');

  useEffect(() => {
    if (loadingAuth || !user) return;
    Api.get
      .anyLocationWhereUserIsAdmin(user.id)
      .then((locationId) => {
        if (locationId) setRedirectUrl(Page.l.foundItems(locationId));
        else setRedirectUrl(Page.createOrganisation);
      })
      .catch((error) => {
        console.error('Error:', error);
        setRedirectUrl(Page.createOrganisation);
      });
  }, [loadingAuth, user]);

  const loadingRedirectUrl = !redirectUrl && user;

  if (loadingAuth || loadingRedirectUrl) {
    return <LoadingSpinner centered />;
  }

  return !user ? element : <Navigate to={redirectUrl} />;
};

export default NoAuthOnlyhRoute;
